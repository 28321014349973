/* eslint-disable no-underscore-dangle */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import { API_URL, API_V2_URL } from '../../config/api';
import { CRM_URL } from '../../config/crm';
import { getSelectedSeller } from '../../utils/getSelectedSeller';

enum HttpMethod {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    PATCH = 'patch',
    DEL = 'delete',
}

interface RequestParams {
    method: HttpMethod;
    path: string;
    data?: Record<string, any>;
    config?: AxiosRequestConfig;
}

const fiveMinutesInMilliseconds = 300000;

const api: AxiosInstance = axios.create({
    baseURL: API_V2_URL,
    timeout: fiveMinutesInMilliseconds,
    withCredentials: true,
    timeoutErrorMessage:
        'Não foi possível estabelecer uma conexão com o servidor. Tente novamente mais tarde.',
});

api.interceptors.request.use(
    (config) => {
        const selectedSeller = getSelectedSeller();

        const updatedConfig = {
            ...config,
            headers: {
                sellerid: selectedSeller?.id,
                'Content-Type': 'application/json',
            },
        };

        return updatedConfig;
    },
    (error) => Promise.reject(error),
);

const refreshAuth = async () => {
    const tokenRefreshPath = '/auth/refresh';
    const logoutPath = 'auth/logout';
    const loginUrl = `${CRM_URL}/login`;

    return api
        .post(tokenRefreshPath, { skipAuthRefresh: true })
        .then(() => Promise.resolve())
        .catch(async (error) => {
            const logoutApi: AxiosInstance = axios.create({
                baseURL: API_URL,
                timeout: fiveMinutesInMilliseconds,
                withCredentials: true,
                timeoutErrorMessage:
                    'Não foi possível estabelecer uma conexão com o servidor. Tente novamente mais tarde.',
            });

            await logoutApi
                .post(logoutPath)
                .then(() => Promise.resolve())
                .catch(() => Promise.reject());

            window.location.href = loginUrl;

            return Promise.reject(error);
        });
};

createAuthRefreshInterceptor(api, refreshAuth, {
    shouldRefresh: (error) =>
        error?.response?.data?.statusCode === 401 &&
        error?.response?.data?.isTokenExpired,
});

class RequestV2 {
    public static get<T = any, R = AxiosResponse<T>>(
        pathParam: string,
        data?: Record<string, any>,
        config?: AxiosRequestConfig,
    ): Promise<R> {
        const params = data ? this.getSearchParams(data) : '';

        const path = `${pathParam}?${params}`;

        return RequestV2.request<R>({ method: HttpMethod.GET, path, config });
    }

    public static post<T = any, R = AxiosResponse<T>>(
        path: string,
        data?: Record<string, any>,
        config?: AxiosRequestConfig,
    ): Promise<R> {
        return RequestV2.request<R>({
            method: HttpMethod.POST,
            path,
            data,
            config,
        });
    }

    public static put<T = any, R = AxiosResponse<T>>(
        path: string,
        data?: Record<string, any>,
        config?: AxiosRequestConfig,
    ): Promise<R> {
        return RequestV2.request<R>({
            method: HttpMethod.PUT,
            path,
            data,
            config,
        });
    }

    public static patch<T = any, R = AxiosResponse<T>>(
        path: string,
        data?: Record<string, any>,
        config?: AxiosRequestConfig,
    ): Promise<R> {
        return RequestV2.request<R>({
            method: HttpMethod.PATCH,
            path,
            data,
            config,
        });
    }

    public static del<T = void, R = AxiosResponse<T>>(
        path: string,
        config?: AxiosRequestConfig,
    ): Promise<R> {
        return RequestV2.request<R>({ method: HttpMethod.DEL, path, config });
    }

    public static async downloadFile<T = any>(
        url: string,
        params?: T,
        customName?: string,
    ) {
        const response = await api.get(url, { responseType: 'blob', params });

        const href = URL.createObjectURL(response.data);
        const contentDisposition = response.headers['content-disposition'];
        const extension = response.headers['content-type'];
        let fileName = customName ?? 'downloaded_file';

        if (contentDisposition && !customName) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            if (fileNameMatch && fileNameMatch[1]) {
                const index = 1;
                fileName = fileNameMatch[index];
            }
        }

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${fileName}.${extension}`);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    public static async getFile<T>(
        url: string,
        params?: T,
        customName?: string,
    ) {
        const response = await api.get(url, { responseType: 'blob', params });

        const contentDisposition = response.headers['content-disposition'];
        const extension = response.headers['content-type'];
        let fileName = customName ?? 'downloaded_file';

        if (contentDisposition && !customName) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            if (fileNameMatch && fileNameMatch[1]) {
                const index = 1;
                fileName = fileNameMatch[index];
            }
        }
        return new File([response.data], `${fileName}.${extension}`, {
            type: response.data.type,
        });
    }

    private static async request<R>({
        method,
        path,
        data,
        config,
    }: RequestParams): Promise<R> {
        try {
            const response = await RequestV2.httpRequest<R>({
                method,
                path,
                data,
                config,
            });

            return response;
        } catch (error) {
            const err = error as any;

            const requestIsCanceled = !err.response;

            if (requestIsCanceled) {
                return Promise.reject(err);
            }

            const { data: errorData } = err.response;

            return Promise.reject(errorData);
        }
    }

    private static async httpRequest<R>({
        method,
        path,
        data,
        config,
    }: RequestParams): Promise<R> {
        const axiosMethod = api[method];
        const reqConfig = RequestV2.getConfig(config || {});

        if ([HttpMethod.GET, HttpMethod.DEL].includes(method)) {
            return axiosMethod(path, reqConfig);
        }

        return axiosMethod(path, data, reqConfig);
    }

    private static getConfig(config: AxiosRequestConfig): AxiosRequestConfig {
        return {
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
            },
            ...config,
        };
    }

    private static getSearchParams(data: Record<string, any>): string {
        const params = Object.keys(data).map((key) => {
            if (Array.isArray(data[key]) && data[key].length) {
                return data[key]
                    .map((param: any, index: number) =>
                        Object.keys(param)
                            .map(
                                (paramKey) =>
                                    `${new URLSearchParams({
                                        [`${key}[${index}][${paramKey}]`]:
                                            param[paramKey],
                                    })}`,
                            )
                            .join('&'),
                    )
                    .join('&');
            }

            if (!Array.isArray(data[key]) && data[key] !== undefined) {
                return `${new URLSearchParams({ [key]: data[key] })}`;
            }

            return '';
        });

        return params.filter((param) => !!param).join('&');
    }
}

export default RequestV2;
