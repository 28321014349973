import RequestV2 from './request-v2.http';

export default class LibraryHttpService {
    private static uri = 'library';

    public static downloadItem(folder: string, file: string) {
        return RequestV2.downloadFile(
            `${this.uri}/files/download/${folder}/${file}`,
        );
    }
}
