import RequestV2 from './request-v2.http';

export default class IntegratorHttpService {
    private static uri = 'users/integrator';

    public static readMany(params: {}) {
        return RequestV2.get(this.uri, params);
    }

    public static readOne(id: number) {
        return RequestV2.get(`${this.uri}/${id}`);
    }

    public static updateAuth(data: {}) {
        return RequestV2.put(this.uri, data);
    }
}
