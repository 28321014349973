import { Col, Row } from 'react-bootstrap';
import { getPaymentMethodText } from '../../../../utils/getPaymentMethodText';
import { formatCurrency } from '../../../../utils/strings';
import { StyledData, StyledDataHeader, StyledSectionTitle } from '../styles';

interface PaymentData {
    method: string;
    total: string;
    subtotal: string;
    discountValue: number;
    interestValue: number;
    commission: number;
    freightValue: number;
}

interface Props {
    paymentData: PaymentData;
}

export default function InvoicePaymentData({ paymentData }: Props) {
    return (
        <>
            <StyledSectionTitle>Pagamento</StyledSectionTitle>

            <Row>
                <Col>
                    <StyledDataHeader>Método de pagamento</StyledDataHeader>
                    <StyledData>
                        {getPaymentMethodText(paymentData.method) || '-'}
                    </StyledData>
                </Col>
                <Col>
                    <StyledDataHeader>Total</StyledDataHeader>
                    <StyledData>{formatCurrency(paymentData.total)}</StyledData>
                </Col>
            </Row>

            <Row>
                <Col>
                    <StyledDataHeader>Subtotal</StyledDataHeader>
                    <StyledData>
                        {formatCurrency(paymentData.subtotal)}
                    </StyledData>
                </Col>
                <Col>
                    <StyledDataHeader>Comissão</StyledDataHeader>
                    <StyledData>
                        {formatCurrency(paymentData.commission)}
                    </StyledData>
                </Col>
            </Row>

            <Row>
                <Col>
                    <StyledDataHeader>Acréscimos</StyledDataHeader>
                    <StyledData>
                        {formatCurrency(paymentData.interestValue)}
                    </StyledData>
                </Col>
                <Col>
                    <StyledDataHeader>Desconto</StyledDataHeader>
                    <StyledData>
                        {formatCurrency(paymentData.discountValue)}
                    </StyledData>
                </Col>
            </Row>
            <Row>
                <Col>
                    <StyledDataHeader>Frete</StyledDataHeader>
                    <StyledData>
                        {formatCurrency(paymentData.freightValue)}
                    </StyledData>
                </Col>
            </Row>
        </>
    );
}
