/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-return-assign */
/* eslint-disable no-useless-return */
/* eslint-disable no-return-await */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-const */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import {
    Col,
    Form,
    Modal,
    Nav,
    OverlayTrigger,
    Row,
    Spinner,
    Tooltip,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from 'react-query';
import { StyledLinkAddLine } from './styles';
import { StyledNav } from '../../../styles/nav';
import { useStructureCalculation } from '../../../contexts/structureCalculationContext';
import {
    StyledOutlineButton,
    StyledConfirmButton,
} from '../../../styles/button';
import { useOnlineStore } from '../../../contexts/onlineStoreContext';
import { ReactComponent as ReactRemoveIcon } from '../../../assets/icons/remove.svg';
import { ReactComponent as ReactPlusIcon } from '../../../assets/icons/plus.svg';
import isFriendlyHttpError from '../../../utils/isFriendlyHttpError';
import ProductHttpService from '../../../services/http/product.http';
import InvoiceHttpService from '../../../services/http/invoice-http';

const StructureCalculationModal = (props: any) => {
    const [loadingButton, setLoadingButton] = useState(false);
    const { option, setOption, setCart } = useOnlineStore();
    const { tab, setTab, lines, setLines, setStructureCalculationValues } =
        useStructureCalculation();
    const { register, handleSubmit, watch, errors, reset, setValue } = useForm({
        shouldUnregister: false,
        defaultValues: option,
    });
    const formData = watch();

    async function loadModules() {
        const modules = await ProductHttpService.getModules({ active: true });

        modules?.data?.data?.map((obj: any) => {
            obj.label = obj.name;
            obj.values = obj.id;
        });

        return modules?.data;
    }

    async function loadRoofTypes() {
        const roofTypes = await ProductHttpService.getRoofTypes({
            moduleId: formData?.moduleId[tab],
            manufacturerReference: ['SSM', 'FIBERMETAL'].toString(),
        });

        roofTypes.data = roofTypes?.data.data?.filter(
            (item: any) => item?.manufacturerReference?.trim() !== null,
        );

        return roofTypes?.data;
    }

    const modules: any = useQuery('modules', loadModules);
    const { data: roofTypes, refetch: refetchRoofTypes } = useQuery(
        'roofTypes',
        formData?.moduleId && loadRoofTypes,
    );

    const addLine = async () => {
        let cont = lines.length;
        let newLines: any = [...lines];

        newLines.push(`Linha ${(cont += 1)}`);

        setLines(newLines);
        setTab(lines.length);
    };

    const removeLine = async (index: number) => {
        lines.splice(index - 1, 1);

        setLines(lines);

        setTimeout(() => {
            formData?.moduleId?.splice(index - 1, 1);
            formData?.roofTypeId?.splice(index - 1, 1);
            formData?.totalPanels?.splice(index - 1, 1);
            formData?.totalLines?.splice(index - 1, 1);
            setValue('moduleId', formData?.moduleId);
            setValue('roofTypeId', formData?.roofTypeId);
            setValue('totalPanels', formData?.totalPanels);
            setValue('totalLines', formData?.totalLines);
        }, 0);

        setOption(formData);
        setTab(index - 2);
    };

    const cancel = () => {
        reset();
        setLines([]);
        setStructureCalculationValues([]);

        props.onHide();
    };

    const validateForm = () => {
        for (let i = 0; i < lines.length; i += 1) {
            if (
                !formData?.moduleId[i] ||
                !formData?.roofTypeId[i] ||
                !formData?.totalPanels[i] ||
                !formData?.totalLines[i]
            ) {
                toast.info(
                    'Para salvar as alterações, é necessário completar o preenchimento de todas as linhas',
                );

                return false;
            }
        }

        return true;
    };

    const formatData = () => {
        const data: any = { lines: [] };

        for (let i = 0; i < lines.length; i += 1) {
            let item: any = {
                moduleId: formData?.moduleId[i],
                roofTypeId: formData?.roofTypeId[i],
                totalPanels: formData?.totalPanels[i],
                totalLines: formData?.totalLines[i],
            };

            data.lines.push(item);
        }

        return data;
    };

    const findStructureCalculation = useMutation(
        async () =>
            await InvoiceHttpService.addByStructureCalculationKit({
                ...formData,
            }),
        {
            onMutate: () => {
                setLoadingButton(true);

                return;
            },
            onError: (error: any) => {
                let err = error as any;

                if (isFriendlyHttpError(err)) {
                    toast.error(err.message);

                    return;
                }

                console.log('error -> ', err);

                toast.error('Erro ao realizar o cálculo de estruturas');
            },
            onSuccess: async (data) => {
                if (!data) {
                    toast.info('Nenhum produto encontrado para a estrutura');

                    return;
                }

                if (props?.budget) {
                    props.addToInvoiceItems &&
                        props.addToInvoiceItems(data.data);
                } else {
                    setCart(data.data ?? {});
                }

                props.onHide();
            },
            onSettled: () => {
                setLoadingButton(false);
            },
        },
    );

    const onSubmit = async () => {
        const isValid = validateForm();

        if (!isValid) {
            return;
        }

        let formattedData = formatData();

        setStructureCalculationValues(formattedData);

        await findStructureCalculation.mutateAsync();
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onExit={() => false}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Cálculo das estruturas de fixação
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row className="">
                    <Col sm={12}>
                        <Form.Control
                            ref={register}
                            value={lines?.length}
                            name="total"
                            type="hidden"
                        />
                        <StyledLinkAddLine onClick={addLine}>
                            <ReactPlusIcon
                                fill={'#2F80ED'}
                                width={20}
                                height={20}
                                className="mr-1"
                            />
                            Nova linha
                        </StyledLinkAddLine>
                    </Col>
                    <Col sm={12} className="mt-4">
                        <StyledNav
                            variant="tabs"
                            activeKey={tab}
                            onSelect={(selectedKey: any) => setTab(selectedKey)}
                        >
                            {lines &&
                                lines?.map((item: any, index: number) => (
                                    <Nav.Item key={index} className="text-left">
                                        <Nav.Link
                                            eventKey={index}
                                            className="float-left"
                                        >
                                            Linha {(index += 1)}
                                        </Nav.Link>
                                        <OverlayTrigger
                                            key="left"
                                            placement="top"
                                            overlay={
                                                <Tooltip
                                                    id={`tooltip-${index}`}
                                                >
                                                    Remover linha {index}
                                                </Tooltip>
                                            }
                                        >
                                            <ReactRemoveIcon
                                                onClick={() =>
                                                    removeLine(index)
                                                }
                                                className="float-left ml-n3 mr-2 cursor-pointer"
                                                fill={'#707070'}
                                                width={20}
                                                height={20}
                                            />
                                        </OverlayTrigger>
                                    </Nav.Item>
                                ))}
                        </StyledNav>
                    </Col>

                    <Col className="mt-4">
                        <Form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                            {lines &&
                                lines?.map((i: any, index: number) => {
                                    if (index === parseInt(tab, 10)) {
                                        return (
                                            <>
                                                <div key={index}>
                                                    <Form.Group controlId="formBasicModule">
                                                        <Form.Label>
                                                            Selecione o módulo
                                                        </Form.Label>
                                                        <Form.Control
                                                            ref={register({
                                                                required:
                                                                    'Campo obrigatório',
                                                            })}
                                                            name={`moduleId[${tab}]`}
                                                            type="text"
                                                            as="select"
                                                            onChange={(
                                                                event,
                                                            ) => {
                                                                Promise.all([
                                                                    refetchRoofTypes(),
                                                                ]).then(() => {
                                                                    if (
                                                                        event
                                                                            ?.target
                                                                            ?.value
                                                                    ) {
                                                                        setOption(
                                                                            formData,
                                                                        );
                                                                    }
                                                                });
                                                            }}
                                                        >
                                                            <option value="">
                                                                Selecione
                                                            </option>
                                                            {modules &&
                                                                modules?.data.data?.map(
                                                                    (
                                                                        module: any,
                                                                        index: any,
                                                                    ) => (
                                                                        <option
                                                                            key={
                                                                                index
                                                                            }
                                                                            value={
                                                                                module.id
                                                                            }
                                                                        >
                                                                            {
                                                                                module.name
                                                                            }
                                                                        </option>
                                                                    ),
                                                                )}
                                                        </Form.Control>
                                                        {errors.moduleId?.[tab]
                                                            ?.message && (
                                                            <Form.Control.Feedback
                                                                type="invalid"
                                                                className="d-block"
                                                            >
                                                                {
                                                                    errors
                                                                        .moduleId?.[
                                                                        tab
                                                                    ]?.message
                                                                }
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </Form.Group>

                                                    <Form.Group controlId="formBasicRoofType">
                                                        <Form.Label>
                                                            Selecione o tipo de
                                                            telhado
                                                        </Form.Label>
                                                        <Form.Control
                                                            ref={register({
                                                                required:
                                                                    'Campo obrigatório',
                                                            })}
                                                            name={`roofTypeId[${tab}]`}
                                                            type="text"
                                                            as="select"
                                                            onChange={(
                                                                event,
                                                            ) => {
                                                                if (
                                                                    event.target
                                                                        .value
                                                                ) {
                                                                    setOption(
                                                                        formData,
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <option value="">
                                                                Selecione
                                                            </option>
                                                            {roofTypes?.length &&
                                                                roofTypes?.map(
                                                                    (
                                                                        roofType: any,
                                                                        index: any,
                                                                    ) => (
                                                                        <option
                                                                            key={
                                                                                index
                                                                            }
                                                                            value={
                                                                                roofType.id
                                                                            }
                                                                        >
                                                                            {
                                                                                roofType?.name
                                                                            }
                                                                        </option>
                                                                    ),
                                                                )}
                                                        </Form.Control>

                                                        {errors.roofTypeId?.[
                                                            tab
                                                        ]?.message && (
                                                            <Form.Control.Feedback
                                                                type="invalid"
                                                                className="d-block"
                                                            >
                                                                {
                                                                    errors
                                                                        .roofTypeId?.[
                                                                        tab
                                                                    ]?.message
                                                                }
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </Form.Group>

                                                    <Form.Group controlId="formBasicTotalPanels">
                                                        <Form.Label>
                                                            Quantidade de
                                                            painéis por linha
                                                        </Form.Label>
                                                        <Form.Control
                                                            ref={register({
                                                                required:
                                                                    'Campo obrigatório',
                                                            })}
                                                            name={`totalPanels[${tab}]`}
                                                            type="number"
                                                            max={200}
                                                            min={0}
                                                            onChange={() =>
                                                                setOption(
                                                                    formData,
                                                                )
                                                            }
                                                        />
                                                        {errors.totalPanels?.[
                                                            tab
                                                        ]?.message && (
                                                            <Form.Control.Feedback
                                                                type="invalid"
                                                                className="d-block"
                                                            >
                                                                {
                                                                    errors
                                                                        .totalPanels?.[
                                                                        tab
                                                                    ]?.message
                                                                }
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </Form.Group>

                                                    <Form.Group controlId="formBasicTotalLines">
                                                        <Form.Label>
                                                            Quantidade de linhas
                                                            iguais a esta
                                                        </Form.Label>
                                                        <Form.Control
                                                            ref={register({
                                                                required:
                                                                    'Campo obrigatório',
                                                            })}
                                                            onChange={() =>
                                                                setOption(
                                                                    formData,
                                                                )
                                                            }
                                                            name={`totalLines[${tab}]`}
                                                            type="number"
                                                            min={0}
                                                        />

                                                        {errors.totalLines?.[
                                                            tab
                                                        ]?.message && (
                                                            <Form.Control.Feedback
                                                                type="invalid"
                                                                className="d-block"
                                                            >
                                                                {
                                                                    errors
                                                                        .totalLines?.[
                                                                        tab
                                                                    ]?.message
                                                                }
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </Form.Group>
                                                </div>
                                            </>
                                        );
                                    }
                                })}
                        </Form>
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer className="mt-4">
                <StyledOutlineButton
                    onClick={cancel}
                    style={{ width: '200px' }}
                >
                    Cancelar e limpar
                </StyledOutlineButton>

                <StyledConfirmButton
                    onClick={handleSubmit(onSubmit)}
                    style={{ width: '200px' }}
                    disabled={!formData}
                >
                    {loadingButton ? (
                        <Spinner animation={'border'} />
                    ) : (
                        'Calcular'
                    )}
                </StyledConfirmButton>
            </Modal.Footer>
        </Modal>
    );
};

export default StructureCalculationModal;
