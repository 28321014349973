/* eslint-disable prefer-const */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Col, Form, Nav, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import BaseLayout from '../../components/BaseLayout';
import { GeneralData } from '../../components/Profile/GeneralData';
import SecurityData from '../../components/Profile/SecurityData';
import { CompanyData } from '../../components/Profile/CompanyData';
import isFriendlyHttpError from '../../utils/isFriendlyHttpError';
import { AuthValues, useAuth } from '../../contexts/authContext';
import { AddressData } from '../../components/Profile/AddressData';
import userHasRoles from '../../utils/userHasRoles';
import { Role } from '../../constants/role';
import { StyledPageSubTitle, StyledPageTitle } from '../../styles/pageTitle';
import { StyledNav } from '../../styles/nav';
import UserHttpService from '../../services/http/user-http';

const Profile: React.FC = () => {
    const pathname = window.location.pathname.split('/')[2];

    const [tab, setTab] = useState(pathname);
    const [loading, setLoading] = useState(false);

    const { user, setUser }: AuthValues = useAuth();

    const isIntegrator = userHasRoles(user, [Role.Integrator]);

    user.password = '';

    const history = useHistory();
    const methods = useForm({
        defaultValues: user,
    });

    const handleTab = (eventKey: any) => {
        setTab(eventKey);

        history.push(eventKey);
    };

    const onSubmit = async (data: any) => {
        const params = {
            ...data,
            id: parseInt(data.id, 10),
            notificationMethods: data.notificationMethods?.map(
                (item: any) => item.value,
            ),
            password: data.password || undefined,
            password_confirmation: data.password_confirmation || undefined,
        };

        try {
            setLoading(true);

            const response = await UserHttpService.updateByProfile(params);

            if (response) {
                toast.success('Usuário salvo com sucesso!');

                const newUser = {
                    name: response.data.name,
                    email: response.data.email,
                    password: '',
                    passqword_confirmation: '',
                    notificationMethods: data.notificationMethods,
                };

                setUser({ ...user, ...newUser });
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);

            let error = err as any;

            if (isFriendlyHttpError(error)) {
                return toast.error(error.message);
            }

            return toast.error('Erro ao salvar dados do usuário!');
        }
    };

    useEffect(() => {
        setTab(pathname);
    }, [pathname]);

    return (
        <BaseLayout>
            <Row className="header align-items-center pr-2 pl-2">
                <Col>
                    <StyledPageTitle className="mt-2">
                        Configurações
                    </StyledPageTitle>
                    <StyledPageSubTitle>
                        Matenha seus dados sempre atualizados.
                    </StyledPageSubTitle>
                </Col>
                {tab === 'usuario' && (
                    <Col className="text-right">
                        <Button
                            style={{ width: '150px' }}
                            onClick={methods.handleSubmit(onSubmit)}
                        >
                            {loading ? (
                                <Spinner animation={'border'} />
                            ) : (
                                'Salvar alterações'
                            )}
                        </Button>
                    </Col>
                )}
            </Row>
            <Row className="mt-4 pl-2 pr-2">
                <Col>
                    <StyledNav
                        variant="tabs"
                        activeKey={tab}
                        onSelect={(selectedKey: any) => handleTab(selectedKey)}
                    >
                        <Nav.Item>
                            <Nav.Link eventKey="usuario">Meu perfil</Nav.Link>
                        </Nav.Item>
                        {isIntegrator && (
                            <Nav.Item>
                                <Nav.Link eventKey="empresa">
                                    Minha empresa
                                </Nav.Link>
                            </Nav.Item>
                        )}
                    </StyledNav>
                </Col>
            </Row>

            <FormProvider {...methods}>
                <Form
                    onSubmit={methods.handleSubmit(onSubmit)}
                    className="mx-2"
                >
                    {tab === 'usuario' && (
                        <>
                            <Row className="mt-4">
                                <Col>
                                    <GeneralData />
                                </Col>
                            </Row>
                            <Row className="mt-4 ">
                                <Col>
                                    <SecurityData />
                                </Col>
                            </Row>
                        </>
                    )}

                    {isIntegrator && tab === 'empresa' && (
                        <>
                            <Row className="mt-4">
                                <Col>
                                    <CompanyData user={user ? user : {}} />
                                </Col>
                            </Row>

                            <Row className="mt-4">
                                <Col>
                                    <AddressData user={user ? user : {}} />
                                </Col>
                            </Row>
                        </>
                    )}
                </Form>
            </FormProvider>
        </BaseLayout>
    );
};

export { Profile };
