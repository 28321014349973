import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { PaymentMethod } from '../constants/onlineStore';
import { Role } from '../constants/role';
import { useAuth } from '../contexts/authContext';
import { dashboardListRoute } from '../routes/config';
import InvoiceHttpService from '../services/http/invoice-http';
import userHasRoles from '../utils/userHasRoles';
import { InvoiceStatus } from '../constants/invoiceStatus';

export default function useInvoiceAttachmentPageAccess(invoiceId: string) {
    const history = useHistory();

    const { user } = useAuth();

    const isAdministratorOrFinancial = userHasRoles(user, [
        Role.Administrator,
        Role.Financial,
    ]);

    if (!isAdministratorOrFinancial) {
        history.push(dashboardListRoute.path);
    }

    return useQuery({
        queryKey: ['invoice', invoiceId],
        queryFn: async () => {
            const response = await InvoiceHttpService.show(invoiceId);

            return response.data;
        },
        onSuccess: ({ paymentMethod, status }) => {
            const isValidPaymentMethod = [
                PaymentMethod.Financing,
                PaymentMethod.Others,
            ].includes(paymentMethod);

            const isInvalidInvoiceStatus = [
                InvoiceStatus.Cart,
                InvoiceStatus.Budget,
                InvoiceStatus.BudgetExpired,
                InvoiceStatus.Invoice,
                InvoiceStatus.InvoiceValidated,
                InvoiceStatus.InvoiceReproved,
                InvoiceStatus.Processing,
            ].includes(status);

            if (isInvalidInvoiceStatus || !isValidPaymentMethod) {
                history.push(dashboardListRoute.path);
            }
        },
        onError: () => {
            history.push(dashboardListRoute.path);
        },
        enabled: Boolean(invoiceId && isAdministratorOrFinancial),
    });
}
