/* eslint-disable react/destructuring-assignment */
import { useEffect, useState } from 'react';
import { Col, Row, Spinner, Table, Button, Nav } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { StyledTh, StyledTr } from '../../../pages/OnlineStore/styles';
import { useOnlineStore } from '../../../contexts/onlineStoreContext';
import ISaveInvoiceItemDto from '../../../dtos/ISaveInvoiceItemDto';
import NumericInput from '../../NumericInput';
import { ReactComponent as EraserIcon } from '../../../assets/icons/eraser-solid.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as ReactArrowRight } from '../../../assets/icons/timeline/arrow_right.svg';
import InvoiceHttpService from '../../../services/http/invoice-http';
import { InvoiceStatus } from '../../../constants/invoiceStatus';
import { formatCurrency } from '../../../utils/strings';
import { ProductAvailability } from '../../../constants/productAvailability';
import { useStructureCalculation } from '../../../contexts/structureCalculationContext';
import isFriendlyHttpError from '../../../utils/isFriendlyHttpError';
import StructureCalculationModal from '../StructureCalculation';
import useWindowSize from '../../../hooks/useWindowSize';
import {
    steps as generatorSteps,
    readyKitsSteps,
} from '../../../constants/onlineStore';
import { StyledSquare, StyledTitleNav } from '../Base/styles';
import StyledKitPowerInfo from './styles';
import ConfirmationDialog from '../../ConfirmationDialog';
import { NotificationIcon } from '../../../constants/notificationIcon';
import BaseAvailabilityBadge from '../../BaseAvailabilityBadge';
import CartProductNumericInput from '../InvoiceProductNumericInput/components/cart-product-numeric-input';
import { replaceItemAtIndex } from '../../../utils/replace-array';
import CartOrInvoiceProductNumericInput from '../InvoiceProductNumericInput/components/cart-or-invoice-product-numeric-input';

const kitPowerDecimals = 2;
interface CartProps {
    onBudgetEditing?: boolean;
    budget?: boolean;
    disabled?: boolean;
    invoice?: any;
    step?: any;
    paymentDeliveryEditing?: boolean;
    setBudget?: (value: any) => void;
    setStep?: (value: any) => void;
    addToInvoiceItems?: (value: any) => void;
}

const Cart = (props: CartProps) => {
    const {
        cart,
        setCart,
        step,
        setStep,
        setGroupId,
        productType,
        setProducts,
        setReload,
        setLoadingCart,
        loadingCart,
    } = useOnlineStore();

    const { setStructureCalculationValues, setLines } =
        useStructureCalculation();
    const [calculationOfStructuresModal, setCalculationOfStructuresModal] =
        useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [invoice, setInvoice] = useState(props.invoice);
    const [items, setItems] = useState(props.invoice?.invoiceItems ?? []);
    const size = useWindowSize();

    const [steps, setSteps] = useState([]);

    const nextStep = (item: any) => {
        if (
            props.invoice.invoiceItems &&
            props.invoice.invoiceItems.length > 0
        ) {
            props.setStep(item.number);
        } else if (item.number > 2) {
            props.setStep(props.step);
        } else {
            props.setStep(item.number);
        }
    };

    const mutationClearCart = useMutation(
        async () => InvoiceHttpService.emptyCart(),
        {
            onError: (error: any) => {
                if (isFriendlyHttpError(error)) {
                    toast.error(error.message);

                    return;
                }

                toast.error('Ocorreu um erro ao limpar o carrinho');
            },
            onSuccess: (response) => {
                if (props.onBudgetEditing) {
                    props.setBudget(response.data);
                } else {
                    setCart(response.data);
                }

                if (productType === 'PA' || productType === 'BF') {
                    setStep(2);
                    setGroupId('');
                }

                if (productType === 'MP') {
                    setGroupId('1001');
                    setStep(1);
                }

                setProducts([]);
                setStructureCalculationValues([]);
                setLines([]);
            },
        },
    );

    useEffect(() => {
        if (props.invoice && props.invoice.id) {
            setInvoice(props.invoice);
        }
    }, [props.invoice]);

    useEffect(() => {
        if (props.onBudgetEditing) {
            props.setBudget(props.invoice);
        } else {
            setCart(props.invoice);
        }
        setItems(props.invoice?.invoiceItems ?? []);

        const generators = generatorSteps.filter(
            (item: any) => item.number !== 1 && item.number !== 4,
        );
        const kits = readyKitsSteps.filter((item: any) => item.number !== 4);

        setSteps(props.invoice.type === 'MP' ? generators : kits);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setItems(invoice?.invoiceItems ?? []);
    }, [invoice?.invoiceItems]);

    const willPickup = props.invoice?.addressType === 'pickup';

    return (
        <>
            <>
                {invoice?.status &&
                    (invoice?.status === InvoiceStatus.Cart ||
                        invoice?.status === InvoiceStatus.Budget) && (
                        <Col>
                            <Row className="align-items-center">
                                {props.onBudgetEditing && (
                                    <Row>
                                        {steps.map((item: any, index: any) => (
                                            <div key={index}>
                                                <Nav.Item>
                                                    <Nav.Link
                                                        style={
                                                            props.step ===
                                                            item.number
                                                                ? {
                                                                      color: '#F2994A',
                                                                      fontWeight: 600,
                                                                  }
                                                                : {
                                                                      color: 'rgba(112, 112, 112, 0.3)',
                                                                  }
                                                        }
                                                        eventKey={item.number}
                                                        onClick={async () => {
                                                            nextStep(item);
                                                        }}
                                                    >
                                                        <Row>
                                                            <Col xl={1}>
                                                                <StyledSquare
                                                                    background={
                                                                        props.step ===
                                                                        item.number
                                                                            ? '#F2994A'
                                                                            : '#fbf3e6'
                                                                    }
                                                                >
                                                                    <h5
                                                                        style={{
                                                                            fontSize: 38,
                                                                            fontWeight: 600,
                                                                            color:
                                                                                props.step ===
                                                                                item.number
                                                                                    ? '#F2994A'
                                                                                    : 'rgba(112, 112, 112, 0.3)',
                                                                        }}
                                                                    >
                                                                        {index +
                                                                            1}
                                                                    </h5>
                                                                </StyledSquare>
                                                            </Col>

                                                            <Col className="my-auto">
                                                                <StyledTitleNav
                                                                    className={
                                                                        size.width <
                                                                            1500 &&
                                                                        invoice.type ===
                                                                            'MP'
                                                                            ? 'mr-n4'
                                                                            : 'ml-3'
                                                                    }
                                                                    color={
                                                                        props.step ===
                                                                        item.number
                                                                            ? '#F2994A'
                                                                            : 'rgba(112, 112, 112, 0.3)'
                                                                    }
                                                                >
                                                                    {item.title}
                                                                </StyledTitleNav>
                                                            </Col>
                                                            {item.number !==
                                                                3 && (
                                                                <ReactArrowRight
                                                                    style={{
                                                                        position:
                                                                            'relative',
                                                                        top: '-3px',
                                                                    }}
                                                                    className={`my-auto ${
                                                                        size.width <
                                                                            1500 &&
                                                                        invoice.type ===
                                                                            'MP'
                                                                            ? ''
                                                                            : 'mr-4'
                                                                    }  `}
                                                                    fill={
                                                                        props.step ===
                                                                        item.number
                                                                            ? '#F2994A'
                                                                            : 'rgba(112, 112, 112, 0.3)'
                                                                    }
                                                                />
                                                            )}
                                                        </Row>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </div>
                                        ))}
                                    </Row>
                                )}
                                <Col className="text-right">
                                    {((cart?.type === 'MP' &&
                                        props.onBudgetEditing) ||
                                        (productType === 'MP' &&
                                            step === 3)) && (
                                        <>
                                            <Button
                                                size="lg"
                                                variant="outline"
                                                style={{
                                                    color: '#2F80ED',
                                                }}
                                                className="px-0"
                                                onClick={() =>
                                                    setCalculationOfStructuresModal(
                                                        true,
                                                    )
                                                }
                                            >
                                                <EditIcon
                                                    fill="#2F80ED"
                                                    className="mr-2"
                                                    width={20}
                                                />
                                                Editar estrutura de fixação
                                            </Button>
                                        </>
                                    )}
                                    {!props.paymentDeliveryEditing &&
                                        !props.onBudgetEditing &&
                                        invoice?.status ===
                                            InvoiceStatus.Cart && (
                                            <Button
                                                variant="outline"
                                                size="lg"
                                                style={{
                                                    color: '#2F80ED',
                                                }}
                                                className="px-0 ml-4"
                                                onClick={() => {
                                                    setShowConfirmation(true);
                                                    setReload(true);
                                                }}
                                            >
                                                <EraserIcon
                                                    fill="#2F80ED"
                                                    className="mr-2"
                                                    width={20}
                                                />
                                                Limpar Carrinho
                                            </Button>
                                        )}
                                </Col>
                            </Row>
                        </Col>
                    )}
                {invoice?.status !== InvoiceStatus.Cart &&
                    invoice?.status !== InvoiceStatus.Budget && <br />}

                {invoice?.kitPower && (
                    <StyledKitPowerInfo>
                        {`Potência do kit: ${invoice?.kitPower?.toFixed(
                            kitPowerDecimals,
                        )}kW`}
                    </StyledKitPowerInfo>
                )}
                <Table
                    hover
                    bordered
                    className="text-center mt-2"
                    size="sm"
                    style={{ backgroundColor: '#ffffff' }}
                >
                    <thead>
                        <tr>
                            <StyledTh>EQUIPAMENTOS</StyledTh>
                            <StyledTh>CÓDIGO</StyledTh>
                            <StyledTh>DESCRIÇÃO</StyledTh>
                            <StyledTh>DISPONIBILIDADE</StyledTh>
                            <StyledTh>QUANTIDADE</StyledTh>
                            <StyledTh>VALOR UNITÁRIO</StyledTh>
                            <StyledTh>VALOR TOTAL</StyledTh>
                        </tr>
                    </thead>
                    <tbody>
                        {items
                            ?.filter(
                                (invocieItem: any) => invocieItem.quantity > 0,
                            )
                            ?.map((item: any) => (
                                <tr key={item.id}>
                                    <td>{item.productCategory}</td>
                                    <td>{item.productErpCode}</td>
                                    <td>{item.productName}</td>
                                    <td>
                                        <BaseAvailabilityBadge
                                            isImmediate={
                                                item.availability ===
                                                ProductAvailability.Immediate
                                            }
                                            boardingDate={item.availability}
                                        />
                                    </td>
                                    <td>
                                        {props.onBudgetEditing ? (
                                            <NumericInput
                                                disabled={
                                                    props.onBudgetEditing ===
                                                    undefined
                                                        ? props.disabled
                                                        : !props.onBudgetEditing
                                                }
                                                tableItem={item ?? {}}
                                                onBudgetEditing={
                                                    props.onBudgetEditing
                                                }
                                                invoice={invoice ?? {}}
                                                setInvoice={
                                                    props.onBudgetEditing
                                                        ? props.setBudget
                                                        : setCart
                                                }
                                                item={
                                                    {
                                                        status: invoice.status,
                                                        invoiceId:
                                                            item.invoiceId,
                                                        id: item.id,
                                                        productErpCode:
                                                            item.productErpCode,
                                                        productName:
                                                            item.productName,
                                                        unitValue:
                                                            item.unitValue,
                                                        isCart: false,
                                                        availability:
                                                            item?.boardingDate,
                                                    } as ISaveInvoiceItemDto
                                                }
                                            />
                                        ) : (
                                            <CartOrInvoiceProductNumericInput
                                                disabled={
                                                    props.onBudgetEditing ===
                                                    undefined
                                                        ? props.disabled
                                                        : !props.onBudgetEditing
                                                }
                                                allItens={
                                                    invoice?.invoiceItems ?? []
                                                }
                                                invoiceId={invoice?.id ?? 0}
                                                type={
                                                    // eslint-disable-next-line no-nested-ternary
                                                    invoice?.status ===
                                                    InvoiceStatus.Cart
                                                        ? 'CART'
                                                        : [
                                                              InvoiceStatus.Budget,
                                                              InvoiceStatus.BudgetExpired,
                                                          ].includes(
                                                              invoice?.status,
                                                          )
                                                        ? 'BUDGET'
                                                        : 'INVOICE'
                                                }
                                                localItem={{
                                                    B1_COD: item.productErpCode,
                                                    B1_DESC: item.productName,
                                                    BM_DESC: '',
                                                    boardingDate:
                                                        item.availability,
                                                    VLR_TOT: item.unitValue,
                                                    CAT_DESC:
                                                        item.productCategory,
                                                }}
                                                onChange={(dataItem) => {
                                                    setItems(
                                                        (valueData: any[]) => {
                                                            const itemToUpdateIndex =
                                                                valueData.findIndex(
                                                                    (value) =>
                                                                        value.productErpCode ===
                                                                        dataItem.B1_COD,
                                                                );
                                                            if (
                                                                itemToUpdateIndex !==
                                                                -1
                                                            ) {
                                                                return replaceItemAtIndex(
                                                                    valueData,
                                                                    itemToUpdateIndex,
                                                                    {
                                                                        ...valueData[
                                                                            itemToUpdateIndex
                                                                        ],
                                                                        quantity:
                                                                            dataItem.quantity,
                                                                        totalValue:
                                                                            dataItem.totalValue,
                                                                    },
                                                                );
                                                            }
                                                            return valueData;
                                                        },
                                                    );
                                                }}
                                                productType={
                                                    invoice?.type ?? productType
                                                }
                                                updateCartOrInvoiceData={(
                                                    data,
                                                ) => {
                                                    setInvoice(data);
                                                    if (props.onBudgetEditing) {
                                                        props.setBudget(data);
                                                    } else {
                                                        setCart(data);
                                                    }
                                                }}
                                                quantityValue={item.quantity}
                                                setLoading={(loading) => {
                                                    setLoadingCart(loading);
                                                }}
                                            />
                                        )}
                                    </td>
                                    <td>{formatCurrency(item.unitValue)}</td>
                                    <td>{formatCurrency(item.totalValue)}</td>
                                </tr>
                            ))}
                    </tbody>
                    <tfoot>
                        <StyledTr>
                            <td className="text-right" colSpan={6}>
                                Subtotal
                            </td>
                            <td>
                                {loadingCart
                                    ? 'Calculando...'
                                    : formatCurrency(
                                          props.invoice?.rawInvoice
                                              ? Number(
                                                    props.invoice?.rawInvoice,
                                                )
                                              : 0,
                                      )}
                            </td>
                        </StyledTr>
                        {(props.paymentDeliveryEditing ||
                            (invoice?.status &&
                                invoice?.status !== InvoiceStatus.Cart)) && (
                            <>
                                <StyledTr>
                                    <td className="text-right" colSpan={6}>
                                        Comissão
                                    </td>
                                    <td>
                                        {loadingCart
                                            ? 'Calculando...'
                                            : formatCurrency(
                                                  props.invoice?.grossCommission
                                                      ? Number(
                                                            props.invoice
                                                                ?.grossCommission,
                                                        )
                                                      : 0,
                                              )}
                                    </td>
                                </StyledTr>
                                <StyledTr>
                                    <td className="text-right" colSpan={6}>
                                        Acréscimos
                                    </td>
                                    <td>
                                        {loadingCart
                                            ? 'Calculando...'
                                            : formatCurrency(
                                                  props.invoice
                                                      ?.interestValue ?? 0,
                                              )}
                                    </td>
                                </StyledTr>
                            </>
                        )}
                        {invoice?.status &&
                            invoice?.status !== InvoiceStatus.Cart && (
                                <>
                                    <StyledTr>
                                        <td className="text-right" colSpan={6}>
                                            Desconto
                                        </td>
                                        <td>
                                            {loadingCart
                                                ? 'Calculando...'
                                                : formatCurrency(
                                                      props.invoice
                                                          ?.discountValue ?? 0,
                                                  )}
                                        </td>
                                    </StyledTr>
                                </>
                            )}
                        {(props.paymentDeliveryEditing ||
                            (invoice?.status &&
                                invoice?.status !== InvoiceStatus.Cart)) && (
                            <StyledTr>
                                <td className="text-right" colSpan={6}>
                                    Frete
                                </td>
                                <td>
                                    {loadingCart
                                        ? 'Calculando...'
                                        : formatCurrency(
                                              willPickup ||
                                                  !props.invoice?.freightValue
                                                  ? 0
                                                  : Number(
                                                        props.invoice
                                                            ?.freightValue,
                                                    ),
                                          )}
                                </td>
                            </StyledTr>
                        )}
                        {(props.paymentDeliveryEditing ||
                            (invoice?.status &&
                                invoice?.status !== InvoiceStatus.Cart)) && (
                            <>
                                <StyledTr>
                                    <td className="text-right" colSpan={6}>
                                        Total
                                    </td>
                                    <td>
                                        {loadingCart
                                            ? 'Calculando...'
                                            : formatCurrency(
                                                  props.invoice?.totalInvoice
                                                      ? Number(
                                                            props.invoice
                                                                ?.totalInvoice,
                                                        )
                                                      : 0,
                                              )}
                                    </td>
                                </StyledTr>
                            </>
                        )}
                    </tfoot>
                </Table>
            </>

            <StructureCalculationModal
                show={calculationOfStructuresModal}
                onHide={() => setCalculationOfStructuresModal(false)}
                budget={props?.budget}
                invoiceId={invoice?.id}
                addToInvoiceItems={props?.addToInvoiceItems}
            />

            {showConfirmation && (
                <ConfirmationDialog
                    show={showConfirmation}
                    onHide={() => setShowConfirmation(false)}
                    onConfirm={mutationClearCart.mutateAsync}
                    title="Limpar carrinho"
                    text="Deseja limpar o carrinho? As informações serão perdidas."
                    icon={NotificationIcon.Warning}
                />
            )}
        </>
    );
};

export default Cart;
