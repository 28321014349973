import Request from './request';
import RequestV2 from './request-v2.http';

export default class PointHttpService {
    private static uri = 'points';

    private static uriV2 = 'point';

    public static index(params: {}) {
        return Request.get(this.uri, params);
    }

    public static showTrade(pointId: number) {
        return Request.get(`${this.uri}/${pointId}/trade`);
    }

    public static showSellerCategory() {
        return Request.get(`${this.uri}/category`);
    }

    public static showSellerInfo(sellerId: number) {
        return Request.get(`${this.uri}/integrator/${sellerId}`);
    }

    public static showTradeableBalance() {
        return Request.get(`${this.uri}/tradeable`);
    }

    public static showQualifiableBalance() {
        return Request.get(`${this.uri}/qualifiable`);
    }

    public static showGainedInSeason() {
        return Request.get(`${this.uri}/gainedInSeason`);
    }

    public static store(data: {}) {
        return Request.post(this.uri, data);
    }

    public static trade(productUrl: string, data: any) {
        return Request.post(`${this.uri}/trade/${productUrl}`, data);
    }

    public static showActualSeason() {
        return RequestV2.get(`${this.uriV2}/season`);
    }

    public static getSeason(seasonId: number) {
        return RequestV2.get(`${this.uriV2}/season/${seasonId}`);
    }
}
