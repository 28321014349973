/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useMutation } from 'react-query';
import imageDefault from '../../../../assets/default-img.png';
import ProductHttpService from '../../../../services/http/product.http';
import { StyledImage } from '../../Invoice/ReleasePaymentMethodModal/styles';
import handleResponseError from '../../../../utils/handleResponseError';

interface Props {
    data: any;
    onClick?: (id: number) => void;
}

const GeneratorsImageCard: React.FC<Props> = ({ data, onClick }) => {
    const [image, setImage] = useState<string | null>(null);

    const handleClick = () => onClick(data);

    const imageMutation = useMutation({
        mutationKey: ['product-image', data.id],
        mutationFn: () => ProductHttpService.getImage(data?.id),
        onSuccess: (result: any) => {
            setImage(result.data?.data);
        },
        onError: (error: any) => {
            handleResponseError(
                error,
                'Ocorreu um erro ao buscar imagem do produto',
            );
        },
    });

    useEffect(() => {
        if (!image) imageMutation.mutate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {imageMutation.isLoading ? (
                <div
                    style={{
                        width: '100%',
                        height: '200px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Spinner animation="border" variant="primary" />
                </div>
            ) : (
                <StyledImage
                    className="ml-n1"
                    // onClick={handleClick}
                    src={
                        image
                            ? `data:image/jpeg;base64,${image} `
                            : imageDefault
                    }
                    style={{ height: '200px', objectFit: 'contain' }}
                />
            )}
        </>
    );
};

export default GeneratorsImageCard;
