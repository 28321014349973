/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable import/no-useless-path-segments */
import React, { useRef, useState, useEffect } from 'react';
import {
    Button,
    ButtonGroup,
    Col,
    Dropdown,
    Row,
    Spinner,
    Table,
} from 'react-bootstrap';
import { useInfiniteQuery } from 'react-query';
import Highlighter from 'react-highlight-words';
import { toast } from 'react-toastify';
import BaseLayout from '../../components/BaseLayout';
import InvoiceHttpService from '../../services/http/invoice-http';
import { StyledTh } from './styles';
import { ReactComponent as ReactEraserIcon } from '../../assets/icons/eraser-solid.svg';
import { ReactComponent as ReactFilterIcon } from '../../assets/icons/filter.svg';
import { StyledPageSubTitle, StyledPageTitle } from '../../styles/pageTitle';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import { rowsPerPage } from '../../constants/pagination';
import { CreditCardAnalysisFilter } from '../../components/CreditCardAnalysis/Filters';
import { useInvoicePaycredit } from '../../contexts/invoicePaycreditContext';
import { InvoicePaycreditStatus } from '../../constants/invoiceStatus';
import { ReactComponent as ReactMoreIcon } from '../../assets/icons/more.svg';
import { ReactComponent as ReactListIcon } from '../../assets/icons/list.svg';
import { ReactComponent as ReactMagnifierIcon } from '../../assets/icons/magnifier.svg';
import getInvoiceStatusText from '../../utils/getInvoiceStatusText';
import { getPaymentMethodText } from '../../utils/getPaymentMethodText';
import SendAnalysisModal from '../../components/CreditCardAnalysis/SendAnalysisModal';
import { Logs } from '../../pages/CreditCardAnalysisLogs';

const List = () => {
    const { formData, reset, formState } = useInvoicePaycredit();

    const [statusData] = useState(Object.values(InvoicePaycreditStatus));

    const [toggleFilter, setToggleFilter] = useState<boolean>(false);
    const [selectedAnalysis, setSelectedAnalysis] = useState(null);
    const [isAnalysisModalOpen, setAnalysisModalOpen] =
        useState<boolean>(false);
    const [showPaycreditLogs, setShowPaycreditLogs] = useState(null);

    const loadInvoicesPaycredit = async ({ pageParam = 0 }: any) => {
        try {
            const newFilterStatus =
                formData.filterStatus &&
                formData.filterStatus.map((item: any) => item.value);

            const params = {
                term: formData.filterTerm ? formData.filterTerm : '',
                skip: pageParam,
                take: rowsPerPage,
                status: formData.filterStatus
                    ? newFilterStatus.join(',')
                    : statusData.join(','),
            };

            const response = await InvoiceHttpService.getInvoicesPaycredit(
                params,
            );

            return {
                data: response.data.data,
                nextPage: pageParam,
            };
        } catch (error) {
            toast.error(
                'Houve um erro ao carregar as informações, por favor tente novamente mais tarde.',
            );
        }
    };

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isLoading,
        isFetched,
        refetch,
    } = useInfiniteQuery('invoicesPaycredit', loadInvoicesPaycredit, {
        getNextPageParam: (lastPage) => lastPage?.nextPage + 1,
    });

    const hasFilterSelected = () => formState.isDirty;

    const clearFilters = () => {
        reset();
        refetch();
    };

    const getMatchDescription = (match: string): string => {
        switch (match) {
            case '0':
                return 'CPF e cartão de crédito incompatíveis';
            case '1':
                return 'CPF e cartão de crédito compatíveis';
            case '2':
                return 'Não foi possível identificar a compatibilidade de CPF com cartão de crédito';
            case '3':
                return 'CPF óbito';
            case '4':
                return 'CPF menor de idade';
            case '5':
                return 'CPF inativo no cadastro positivo';
            case '99':
                return 'Sem Consulta - Pessoa jurídica';
            default:
                return '-';
        }
    };

    const checkCreditCard = async (invoicePaycredit: any) => {
        try {
            const response = await InvoiceHttpService.checkCreditCard(
                invoicePaycredit,
            );

            if (response.status === 201) {
                toast.success('Compatibilidade verificada com sucesso!');

                refetch();
            }
        } catch (error: any) {
            toast.error(
                error?.message ||
                    'Houve um erro ao verificar compatibilidade, por favor tente novamente mais tarde',
            );
        }
    };

    const openAnalysisModal = async (invoicePaycredit: any) => {
        setSelectedAnalysis(invoicePaycredit);
        setAnalysisModalOpen(true);
    };

    const loadMoreRef = useRef();

    useIntersectionObserver({
        target: loadMoreRef,
        onIntersect: fetchNextPage,
        enabled: hasNextPage,
    });

    useEffect(() => {
        const timer = setTimeout(() => {
            refetch();
        }, 500);

        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    return (
        <>
            <BaseLayout>
                <Row className="header align-items-center pr-2 pl-2">
                    <Col>
                        <StyledPageTitle className="mt-2">
                            Análise de Cartões de Crédito
                        </StyledPageTitle>
                        <StyledPageSubTitle>
                            Todas as informações de análises de cartões de
                            crédito em um só lugar.
                        </StyledPageSubTitle>
                    </Col>
                    <Col className="text-right">
                        <ButtonGroup>
                            {hasFilterSelected() && (
                                <Button
                                    style={{
                                        color: '#2F80ED',
                                    }}
                                    className="mr-2"
                                    variant={'text'}
                                    onClick={clearFilters}
                                >
                                    <ReactEraserIcon
                                        fill={'#2F80ED'}
                                        className={'mr-2'}
                                        width={18}
                                    />{' '}
                                    Limpar filtros
                                </Button>
                            )}
                            <Button
                                className="mr-1"
                                variant={'light'}
                                style={{ backgroundColor: '#EEEEEE' }}
                                onClick={() => setToggleFilter(!toggleFilter)}
                            >
                                <ReactFilterIcon
                                    fill="#bdbdbd"
                                    width="18"
                                    height="18"
                                />
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>

                <CreditCardAnalysisFilter toggleFilter={toggleFilter} />

                <Row>
                    <Col className="ml-2 mr-2">
                        <Table bordered hover size="sm" className="text-center">
                            <thead>
                                <tr>
                                    <StyledTh>Nº DO PEDIDO</StyledTh>
                                    <StyledTh>DOCUMENTO DO CLIENTE</StyledTh>
                                    <StyledTh>NOME DO CLIENTE</StyledTh>
                                    <StyledTh>FORMA DE PAGAMENTO</StyledTh>
                                    <StyledTh>STATUS</StyledTh>
                                    <StyledTh>MATCH</StyledTh>
                                    <StyledTh>NÚMERO DO CARTÃO</StyledTh>
                                    <StyledTh>2° MATCH</StyledTh>
                                    <StyledTh>2° NÚMERO DO CARTÃO</StyledTh>
                                    <StyledTh>STATUS DA CONSULTA</StyledTh>
                                    <StyledTh>PAGAMENTOS</StyledTh>
                                    <StyledTh></StyledTh>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.pages
                                    ? data?.pages.map((group: any) =>
                                          group?.data.map(
                                              (item: any, index2: any) => (
                                                  <tr key={index2}>
                                                      <td>
                                                          <Highlighter
                                                              autoEscape
                                                              highlightClassName="highlight-term"
                                                              searchWords={[
                                                                  formData.filterTerm,
                                                              ]}
                                                              textToHighlight={item?.invoice.id?.toString()}
                                                          />
                                                      </td>
                                                      <td>
                                                          {
                                                              item?.costumerDocument
                                                          }
                                                      </td>
                                                      <td>
                                                          <Highlighter
                                                              autoEscape
                                                              highlightClassName="highlight-term"
                                                              searchWords={[
                                                                  formData.filterTerm,
                                                              ]}
                                                              textToHighlight={
                                                                  item?.costumerName
                                                              }
                                                          />
                                                      </td>
                                                      <td>
                                                          {getPaymentMethodText(
                                                              item?.paymentMethod,
                                                          )}
                                                      </td>
                                                      <td>
                                                          {getInvoiceStatusText(
                                                              item?.status,
                                                              item?.paymentMethod,
                                                          )}
                                                      </td>
                                                      <td>
                                                          {getMatchDescription(
                                                              item?.match,
                                                          )}
                                                      </td>
                                                      <td>
                                                          {item?.cardNumber}
                                                      </td>
                                                      <td>
                                                          {getMatchDescription(
                                                              item?.match2,
                                                          )}
                                                      </td>
                                                      <td>
                                                          {item?.cardNumber2}
                                                      </td>
                                                      <td>
                                                          {item?.consult ===
                                                              false ||
                                                          item?.consult === null
                                                              ? 'Consulta não realizada'
                                                              : 'Consulta realizada'}
                                                      </td>
                                                      <td>
                                                          {item?.status ===
                                                          InvoicePaycreditStatus.InvoiceAnalysisDone ? (
                                                              <Button
                                                                  onClick={() =>
                                                                      openAnalysisModal(
                                                                          item,
                                                                      )
                                                                  }
                                                              >
                                                                  Confirmar
                                                                  pagamento
                                                              </Button>
                                                          ) : (
                                                              <span>-</span>
                                                          )}
                                                      </td>
                                                      <td
                                                          className={'d-flex'}
                                                          style={{
                                                              justifyContent:
                                                                  'center',
                                                          }}
                                                      >
                                                          <Dropdown key="left">
                                                              <Dropdown.Toggle
                                                                  bsPrefix="nexen"
                                                                  as={Button}
                                                                  variant="text"
                                                              >
                                                                  <ReactMoreIcon
                                                                      fill="#bdbdbd"
                                                                      width="10"
                                                                      height="20"
                                                                  />
                                                              </Dropdown.Toggle>

                                                              <Dropdown.Menu>
                                                                  {!item?.consult && (
                                                                      <>
                                                                          <Dropdown.Item>
                                                                              <div
                                                                                  onClick={() =>
                                                                                      checkCreditCard(
                                                                                          item,
                                                                                      )
                                                                                  }
                                                                              >
                                                                                  <ReactListIcon
                                                                                      fill="#707070"
                                                                                      width="18"
                                                                                      height="18"
                                                                                      className="ml-n3 mr-2"
                                                                                  />
                                                                                  Verificar
                                                                                  compatibilidade
                                                                              </div>
                                                                          </Dropdown.Item>
                                                                          <Dropdown.Divider />
                                                                      </>
                                                                  )}
                                                                  <Dropdown.Item
                                                                      onClick={() => {
                                                                          setShowPaycreditLogs(
                                                                              item.id,
                                                                          );
                                                                      }}
                                                                  >
                                                                      <ReactMagnifierIcon
                                                                          fill="#707070"
                                                                          width="18"
                                                                          height="18"
                                                                          className="ml-n3 mr-2"
                                                                      />
                                                                      Logs da
                                                                      análise
                                                                  </Dropdown.Item>
                                                              </Dropdown.Menu>
                                                          </Dropdown>
                                                      </td>
                                                  </tr>
                                              ),
                                          ),
                                      )
                                    : ''}
                            </tbody>
                        </Table>
                    </Col>

                    <Col md={12} className="text-center" ref={loadMoreRef}>
                        {isFetchingNextPage || isLoading || !isFetched ? (
                            <Col md={12} className="text-center">
                                <Spinner animation={'border'} />
                            </Col>
                        ) : (
                            <Col md={12} className="text-center">
                                <strong style={{ color: '#adadad' }}>
                                    Sem itens para carregar
                                </strong>
                            </Col>
                        )}
                    </Col>
                </Row>
            </BaseLayout>

            <SendAnalysisModal
                selectedAnalysis={selectedAnalysis}
                show={isAnalysisModalOpen}
                onHide={() => {
                    setAnalysisModalOpen(false);
                }}
                refetch={refetch}
            />

            <Logs
                id={showPaycreditLogs}
                onHide={() => {
                    refetch();
                    setShowPaycreditLogs(null);
                }}
            />
        </>
    );
};

export { List };
