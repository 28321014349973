import RequestV2 from './request-v2.http';

export default class BranchHttpService {
    private static uri = 'branch';

    public static readMany(params: {
        skip?: number;
        take?: number;
        term?: string;
        cnpj?: string;
    }) {
        return RequestV2.get(`${this.uri}`, params);
    }
}
