import RequestV2 from './request-v2.http';

export default class RegionHttpService {
    private static uri = 'regions';

    private static uriV2 = 'region';

    public static readMany(params: { term: string; all: boolean }) {
        return RequestV2.get(this.uriV2, params);
    }
}
