import { Row, Col, ButtonGroup, Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { listGeneratorsRoute } from '../../../routes/config';
import { StyledPageTitle, StyledPageSubTitle } from '../../../styles/pageTitle';
import useInvoices from '../../../hooks/useInvoices';
import { ReactComponent as ReactEraserIcon } from '../../../assets/icons/eraser-solid.svg';
import { ReactComponent as ReactFilterIcon } from '../../../assets/icons/filter.svg';

export default function BudgetHeader() {
    const history = useHistory();

    const {
        filterForm,
        setShowFilterSection,
        showFilterSection,
        setFilterTerm,
        filterTerm,
    } = useInvoices();

    const showCleanFiltersButton = filterForm.formState.isDirty || filterTerm;

    return (
        <Row className="header align-items-center pr-2 pl-2">
            <Col>
                <StyledPageTitle className="mt-2">Orçamentos</StyledPageTitle>
                <StyledPageSubTitle>
                    Todas as informações dos orçamentos em um só lugar.
                </StyledPageSubTitle>
            </Col>
            <Col className="text-right">
                <ButtonGroup>
                    {showCleanFiltersButton && (
                        <Button
                            style={{
                                color: '#2F80ED',
                            }}
                            className="mr-2"
                            variant="text"
                            onClick={() => {
                                filterForm.reset();
                                setFilterTerm('');
                            }}
                        >
                            <ReactEraserIcon
                                fill="#2F80ED"
                                className="mr-2"
                                width={18}
                            />{' '}
                            Limpar filtros
                        </Button>
                    )}
                    <Button
                        className="mr-1"
                        variant="light"
                        style={{ backgroundColor: '#EEEEEE' }}
                        onClick={() => setShowFilterSection(!showFilterSection)}
                    >
                        <ReactFilterIcon
                            fill="#bdbdbd"
                            width="18"
                            height="18"
                        />
                    </Button>
                    <Button
                        className="float-right"
                        onClick={() => {
                            history.push(listGeneratorsRoute.path);
                        }}
                        style={{ width: '150px' }}
                    >
                        Novo Orçamento
                    </Button>
                </ButtonGroup>
            </Col>
        </Row>
    );
}
