import RequestV2 from './request-v2.http';

export default class ProductHttpService {
    private static uri = 'product';

    public static index(params: {}) {
        return RequestV2.get(this.uri, params);
    }

    public static getInverterBrands(params?: any) {
        return RequestV2.get(`${this.uri}/inverter/brand`, params);
    }

    public static show(productId: string) {
        return RequestV2.get(`${this.uri}/${productId}`);
    }

    public static getModules(data: any) {
        return RequestV2.get(`${this.uri}/modules`, data);
    }

    public static getInverters(params?: any) {
        return RequestV2.get(`${this.uri}/inverter`, params);
    }

    public static getImage(productErpCode: number) {
        return RequestV2.get(`${this.uri}/${productErpCode}/image`);
    }

    public static getRoofTypes(data: any) {
        return RequestV2.get(`${this.uri}/roof-types`, data);
    }

    public static getGroups() {
        return RequestV2.get(`${this.uri}/groups`);
    }

    public static getBrands(groupId: number) {
        return RequestV2.get(`${this.uri}/brands`, {
            groupId: `${groupId}`.replace('?', ''),
        });
    }

    public static getCategories() {
        return RequestV2.get(`${this.uri}/categories`);
    }
}
