import { useHistory } from 'react-router';
import { Dropdown, Button } from 'react-bootstrap';
import { Fragment } from 'react';
import { ReactComponent as ReactMoreIcon } from '../../../../assets/icons/more.svg';
import InvoiceAttachment from '../../../../shared/interfaces/invoiceAttachment.interface';
import formatDate from '../../../../utils/formatDate';
import getBankAccountString from '../../../../utils/getBankAccountString';
import { formatCurrency } from '../../../../utils/strings';
import AttachmentStatusBadge from '../AttachmentStatusBadge';
import InvoiceAttachmentStatus from '../../../../constants/InvoiceAttachmentStatus';
import { invoiceAttachmentsEditRoute } from '../../../../routes/config/invoices.route';
import InvoiceHttpService from '../../../../services/http/invoice-http';

interface InvoiceAttachmentTableRowProps {
    invoiceAttachment: InvoiceAttachment;
    onRemove: (id: number) => void;
    onSendToErp: (id: number) => void;
}

interface DropdownItem {
    label: string;
    onClick: () => void;
    visible: boolean;
    disabled?: boolean;
}

export default function InvoiceAttachmentTableRow({
    invoiceAttachment,
    onRemove,
    onSendToErp,
}: InvoiceAttachmentTableRowProps) {
    const history = useHistory();

    const defaultText = 'Não especificado';

    const isAttachmentSentOrProcessing = [
        InvoiceAttachmentStatus.Sent,
        InvoiceAttachmentStatus.Processing,
    ].includes(invoiceAttachment.status);

    const goToDetailsPage = () =>
        history.push(
            invoiceAttachmentsEditRoute.build({
                id: invoiceAttachment.invoice.id,
                attachmentId: invoiceAttachment.id,
            }),
        );

    const canUpdateAttachmentStatus =
        invoiceAttachment.voucherAmount &&
        invoiceAttachment.voucherDate &&
        invoiceAttachment.voucherNumber &&
        invoiceAttachment.attachment &&
        invoiceAttachment.status === InvoiceAttachmentStatus.Unsent;

    const dropdownItems: DropdownItem[] = [
        {
            label:
                invoiceAttachment.status === InvoiceAttachmentStatus.Unsent
                    ? 'Editar'
                    : 'Visualizar',
            onClick: goToDetailsPage,
            visible: true,
        },
        {
            label: 'Baixar',
            onClick: async () =>
                InvoiceHttpService.downloadAttachment(invoiceAttachment.id),
            visible: true,
        },
        {
            label: 'Remover',
            onClick: () => onRemove(invoiceAttachment.id),
            disabled: isAttachmentSentOrProcessing,
            visible: true,
        },
        {
            label: 'Enviar para o ERP',
            onClick: () => onSendToErp(invoiceAttachment.id),
            disabled: !canUpdateAttachmentStatus,
            visible: !isAttachmentSentOrProcessing,
        },
    ];

    const visibleDropdownItems = dropdownItems.filter((item) => item.visible);

    return (
        <tr>
            <td>{invoiceAttachment.voucherNumber || defaultText}</td>
            <td>
                {invoiceAttachment.voucherAmount
                    ? formatCurrency(invoiceAttachment.voucherAmount)
                    : defaultText}
            </td>
            <td>
                {invoiceAttachment.voucherDate
                    ? formatDate(invoiceAttachment.voucherDate)
                    : defaultText}
            </td>
            <td>
                {invoiceAttachment.bankAccountId
                    ? getBankAccountString(invoiceAttachment.bankAccountId)
                    : defaultText}
            </td>
            <td>
                {invoiceAttachment.status ? (
                    <AttachmentStatusBadge status={invoiceAttachment.status} />
                ) : (
                    defaultText
                )}
            </td>
            <td>
                <Dropdown key="left">
                    <Dropdown.Toggle
                        bsPrefix="nexen"
                        as={Button}
                        variant="text"
                        style={{
                            padding: '6px',
                            marginRight: '8px',
                        }}
                    >
                        <ReactMoreIcon fill="#a3a3a3" width="10" height="14" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {visibleDropdownItems.map((item, index) => (
                            <Fragment key={item.label}>
                                <Dropdown.Item
                                    onClick={item.onClick}
                                    disabled={item.disabled}
                                >
                                    {item.label}
                                </Dropdown.Item>
                                {visibleDropdownItems.length > index + 1 && (
                                    <Dropdown.Divider />
                                )}
                            </Fragment>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </td>
        </tr>
    );
}
