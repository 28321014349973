export function replaceItemAtIndex<T>(
    arr: T[],
    index: number,
    newValue: T,
): T[] {
    return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
}

export function removeItemAtIndex<T>(arr: T[], index: number): T[] {
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

export function isArray(value: unknown): value is unknown[] {
    return Boolean(value) && Array.isArray(value);
}

export function isRepeatedArray<T>(value: T[]): boolean {
    return value.every((item) => value[0] === item);
}
