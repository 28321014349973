/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const StyledDivInvoiceDelivery = styled.div`
    background: #f8f9fa;
    padding: 1rem;
    border-radius: 4px;
`;

export const SubText = styled.span`
    display: block;
    font-size: 12px;
    color: #28a745;
    font-weight: normal;
`;
