import { Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Select from '../Select';
import CustomerHttpService from '../../services/http/customer-http';

export interface ICustomerAddress {
    id: number;
    cep: string;
    reference: string;
    neighborhood: string;
    cityName: string;
    cityId: number;
    number: string;
    complement: string;
    uf: string;
}

interface props {
    customerId: number;
    disabled?: boolean;
    customerAddressId?: number;
    refetch?: boolean;
    onChange?: (data: ICustomerAddress) => void;
    onBlur?: (event: any) => void;
}

export default function CustomerAddressSelect({
    customerId,
    disabled,
    customerAddressId,
    onChange,
    refetch,
    onBlur,
}: props) {
    const [customerAddresses, setCustomerAddresses] = useState<
        ICustomerAddress[]
    >([]);
    const [customerAddressesOptions, setCustomerAddressesOptions] = useState<
        Array<{ value: number; label: string }>
    >([]);

    const [customerSelected, setCustomerSelected] = useState<
        { value: number; label: string } | undefined
    >(undefined);
    const [refetchRequest, setRefetchRequest] = useState(refetch);

    const getLabel = (value: ICustomerAddress) => {
        return `${value.cep} - ${value.cityName}, ${value.uf}`;
    };

    const loadData = async () => {
        const { data } = await CustomerHttpService.findAddress(customerId);
        setCustomerAddresses(data);
        setCustomerAddressesOptions(
            data.map((value: ICustomerAddress) => {
                return {
                    value: value.id,
                    label: getLabel(value),
                };
            }),
        );

        if (customerAddressId) {
            const address = data.find(
                (value: ICustomerAddress) =>
                    `${value.id}` === `${customerAddressId}`,
            );
            if (address) {
                setCustomerSelected({
                    value: address.id,
                    label: getLabel(address),
                });
            }
        }
    };

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId, customerAddressId]);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch]);

    const handleChange = (data: { value: number; label: string }) => {
        const address = customerAddresses.find(
            (addressData) => addressData.id === data.value,
        );
        if (address) {
            onChange(address);
            setCustomerSelected({
                value: address.id,
                label: getLabel(address),
            });
        }
    };

    return (
        <Form.Group>
            <Form.Label>Endereços do cliente</Form.Label>
            <Select
                disabled={disabled}
                options={customerAddressesOptions || []}
                value={customerSelected}
                name="customerAddress"
                type="text"
                onBlur={onBlur}
                onChange={handleChange}
                placeholder="Selecione..."
            />
        </Form.Group>
    );
}
