import { Nav } from 'react-bootstrap';
import Group from '../../../shared/interfaces/group.interface';
import GroupMenuItem from '../GroupMenuItem';
import { useOnlineStore } from '../../../contexts/onlineStoreContext';

interface props {
    loading?: boolean;
}
export default function GroupMenu({ loading }: props) {
    const {
        groups,
        groupId,
        setGroupId,
        viewType,
        setViewType,
        setBrandId,
        setPage,
    } = useOnlineStore();

    const handleSelect = (newGroupId: string) => {
        if (groupId !== newGroupId) {
            setBrandId(undefined);
            setPage(1);
            setGroupId(newGroupId);
        }

        if (viewType === 'detail') {
            setViewType('card');
        }
    };

    return (
        <>
            <Nav
                activeKey={groupId}
                className="ma-0 pt-3 ml-n3"
                onSelect={handleSelect}
            >
                {groups?.map((group: Group, index: number) => {
                    return (
                        <GroupMenuItem
                            key={index}
                            group={group}
                            disabled={loading}
                            active={
                                parseInt(`${groupId}`, 10) ===
                                parseInt(`${group.BM_GRUPO}`, 10)
                            }
                        />
                    );
                })}
            </Nav>
        </>
    );
}
