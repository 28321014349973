/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
import React, { useState, createContext, useContext, useEffect } from 'react';
import ContactHttpService from '../services/http/contact-http';

const ContactContext = createContext<any>({});
export const useContact = () => useContext(ContactContext);

export function ConsultantContactProvider(props: any) {
    const [consultants, setConsultants] = useState([]);

    async function loadContacts() {
        const { data } = await ContactHttpService.readMany({});

        let consultantByState = [];

        if (!data) return;

        for (let contact of data.data) {
            let states = contact.states.split(',');

            for (let state of states) {
                consultantByState.push({
                    state,
                    consultantName: contact.consultantName,
                    consultantWhatsapp: `55${contact.phoneNumber}`,
                });
            }
        }

        setConsultants(consultantByState);
    }

    useEffect(() => {
        loadContacts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContactContext.Provider
            value={{
                consultants,
                setConsultants,
            }}
            {...props}
        />
    );
}
