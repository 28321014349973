import { API_V2_URL } from '../../config/api';
import BannerLocation from '../../constants/bannerLocation';
import RequestV2 from './request-v2.http';

export default class BannerHttpService {
    private static uri = 'banner';

    public static readMany(params: {
        skip?: number;
        take?: number;
        term?: string;
        active?: boolean;
        location?: BannerLocation;
    }) {
        return RequestV2.get(`${this.uri}`, params);
    }

    public static readUrlGetImage(id: number) {
        return `${API_V2_URL}/${this.uri}/${id}`;
    }
}
