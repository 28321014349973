/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/prefer-default-export */
import React, { useEffect, useRef, useState } from 'react';
import { Col, ProgressBar, Row, Spinner } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import BaseLayout from '../../components/BaseLayout';
import { CustomerProps } from '../../components/Triangulation/CustomerSelection';
import InvoiceTriangulation, {
    InvoiceTriangulationProps,
} from '../../components/Triangulation/InvoiceTriangulation';
import {
    InvoiceStatus,
    InvoiceTriangulationStatus,
} from '../../constants/invoiceStatus';
import { Role } from '../../constants/role';
import { useAuth } from '../../contexts/authContext';
import { dashboardListRoute } from '../../routes/config';
import CustomerHttpService from '../../services/http/customer-http';
import InvoiceHttpService from '../../services/http/invoice-http';
import { StyledPageSubTitle, StyledPageTitle } from '../../styles/pageTitle';
import userHasRoles from '../../utils/userHasRoles';
import { StyledButton, StyledButtonsDiv, StyledStatusBadge } from './styles';

export function Triangulation() {
    const { id } = useParams<{ id: string }>();
    const componentIsMounted = useRef(true);
    const history = useHistory();

    const { user } = useAuth();
    const isIntegrator = userHasRoles(user, [
        Role.Integrator,
        Role.Manager,
        Role.Contributor,
    ]);
    const isCommercial = userHasRoles(user, [Role.Commercial]);
    const isAdministratorOrSupervisor = userHasRoles(user, [
        Role.Administrator,
        Role.CommercialSupervisor,
    ]);
    const [blockRoute, setBlockRoute] = useState(false);

    const newInvoiceTriangulation: InvoiceTriangulationProps = {
        customerDocument: null,
        customerName: null,
        addressCity: null,
        addressState: null,
        totalValue: null,
        additionalData: null,
        attachments: [],
        originalCustomerDocument: null,
        originalCustomerName: null,
        originalAddressCity: null,
        originalAddressState: null,
        originalTotalValue: null,
        originalAdditionalData: null,
        originalAttachments: [],
    };

    const [dataState, setDataState] = useState<
        'unchanged' | 'modified' | 'saving'
    >('unchanged');
    const [invoiceTriangulationData, setInvoiceTriangulationData] =
        useState<InvoiceTriangulationProps>(null);
    const [sellerErpCode, setSellerErpCode] = useState('');
    const [customer, setCustomer] = useState<CustomerProps>(null);
    const [approving, setApproving] = useState(false);
    const [disabledByStatus, setDisabledByStatus] = useState(true);
    const [disabledByCustomer, setDisabledByCustomer] = useState(true);
    const [disabledByCustomersList, setDisabledByCustomersList] =
        useState(true);

    const triangulationData = (data: any): InvoiceTriangulationProps => ({
        id: data.id,
        customerDocument: data.customerDocument,
        customerName: data.customerName,
        addressCity: data.addressCity,
        addressState: data.addressState,
        totalValue: data.totalValue,
        additionalData: data.additionalData,
        status: data.status,
        attachments: data.attachments,
        originalCustomerDocument: data.customerDocument,
        originalCustomerName: data.customerName,
        originalAddressCity: data.addressCity,
        originalAddressState: data.addressState,
        originalTotalValue: data.totalValue,
        originalAdditionalData: data.additionalData,
        originalAttachments: data.attachments,
    });

    useEffect(() => {
        if (blockRoute) {
            history.push(dashboardListRoute.path);
        }
    }, [history, blockRoute]);

    useEffect(() => {
        const unblock = history.block(
            dataState !== 'unchanged'
                ? 'Deseja realmente deixar a página? É possível que existam alterações não salvas'
                : true,
        );

        return function cleanup() {
            unblock();
        };
    }, [dataState, history]);

    useEffect(() => {
        const handler = (event: BeforeUnloadEvent) => {
            event.preventDefault();
            event.returnValue = '';
        };

        if (dataState !== 'unchanged') {
            window.addEventListener('beforeunload', handler);

            return () => {
                window.removeEventListener('beforeunload', handler);
            };
        }

        return () => {};
    }, [dataState]);

    useEffect(() => {
        const isAllowed = (isTriangulation: boolean, erpCode: string) => {
            const isCorrectIntegrator =
                isIntegrator &&
                (user.seller?.erpCode === erpCode ||
                    user.owner?.erpCode === erpCode);

            return (
                isTriangulation &&
                (isCorrectIntegrator ||
                    isAdministratorOrSupervisor ||
                    isCommercial)
            );
        };

        async function loadTriangulation() {
            try {
                const invoice = await InvoiceHttpService.show(id);

                if (
                    !isAllowed(
                        invoice.data?.isTriangulation,
                        invoice.data?.seller?.erpCode,
                    )
                ) {
                    setBlockRoute(true);

                    return;
                }

                if (
                    invoice.data?.data?.status === InvoiceStatus.InvoiceBilled
                ) {
                    setDisabledByStatus(false);
                }

                const response = await InvoiceHttpService.showTriangulation(id);

                const sellerErpCode = invoice.data?.data?.seller?.erpCode;

                setSellerErpCode(sellerErpCode);

                if (response.data) {
                    const customerDocument = response.data.customerDocument;

                    try {
                        const { data } = await CustomerHttpService.readMany({
                            sellerCode: sellerErpCode,
                            term: customerDocument,
                        });

                        if (data.length > 0) {
                            const customerData = data[0];

                            setCustomer({
                                label: `${customerData.name} / ${customerData.fancyName}`,
                                value: customerData.document,
                                addressDescription:
                                    customerData.addressDescription,
                                addressNeighborhood:
                                    customerData.addressNeighborhood,
                                addressNumber: customerData.addressNumber,
                                addressComplement:
                                    customerData.addressComplement,
                                addressCity: customerData.erpCityName,
                                addressState: customerData.erpState,
                                addressCep: customerData.addressCep,
                                legalName: customerData.name,
                                fantasyName: customerData.fancyName,
                            });
                        } else {
                            throw Error();
                        }
                    } catch (error) {
                        toast.error('Erro ao buscar cliente no ERP');
                    }

                    setInvoiceTriangulationData(
                        triangulationData(response.data),
                    );
                    setDisabledByCustomer(false);
                } else {
                    setInvoiceTriangulationData(newInvoiceTriangulation);
                    setDisabledByCustomer(false);
                }
            } catch (error) {
                console.log(error);
                toast.error('Erro ao buscar dados da operação');
            }
        }

        if (componentIsMounted.current) {
            loadTriangulation();
        }
    }, [
        id,
        newInvoiceTriangulation,
        user,
        isAdministratorOrSupervisor,
        isCommercial,
        isIntegrator,
    ]);

    useEffect(() => {
        if (customer) {
            const updatedCustomer = {
                customerDocument: customer.value,
                customerName: customer.fantasyName,
                addressCity: customer.addressCity,
                addressState: customer.addressState,
            };

            setInvoiceTriangulationData((prevInvoiceTriangulation) => ({
                ...prevInvoiceTriangulation,
                ...updatedCustomer,
            }));
        }
    }, [customer]);

    useEffect(() => {
        function equalAttachments(): boolean {
            if (
                invoiceTriangulationData.attachments.length !==
                invoiceTriangulationData.originalAttachments.length
            ) {
                return false;
            }

            let equals = true;

            invoiceTriangulationData.originalAttachments.forEach(
                (attachment, index) => {
                    if (
                        attachment !==
                        invoiceTriangulationData.attachments[index]
                    ) {
                        equals = false;
                    }
                },
            );

            return equals;
        }

        if (invoiceTriangulationData) {
            if (
                invoiceTriangulationData.customerDocument !==
                    invoiceTriangulationData.originalCustomerDocument ||
                invoiceTriangulationData.customerName !==
                    invoiceTriangulationData.originalCustomerName ||
                invoiceTriangulationData.addressCity !==
                    invoiceTriangulationData.originalAddressCity ||
                invoiceTriangulationData.addressState !==
                    invoiceTriangulationData.originalAddressState ||
                invoiceTriangulationData.totalValue !==
                    invoiceTriangulationData.originalTotalValue ||
                invoiceTriangulationData.additionalData !==
                    invoiceTriangulationData.originalAdditionalData ||
                !equalAttachments()
            ) {
                setDataState('modified');
            }
        }
    }, [invoiceTriangulationData]);

    useEffect(() => {
        componentIsMounted.current = false;
    }, []);

    const handleSave = async () => {
        if (!invoiceTriangulationData.customerDocument) {
            toast.error(
                'Para salvar os dados da operação, você precisa selecionar um cliente',
            );

            return;
        }

        const oldState = dataState;

        setDataState('saving');

        try {
            let response;
            const data: InvoiceTriangulationProps = {
                id: invoiceTriangulationData.id,
                customerDocument: invoiceTriangulationData.customerDocument,
                customerName: invoiceTriangulationData.customerName,
                addressCity: invoiceTriangulationData.addressCity,
                addressState: invoiceTriangulationData.addressState,
                totalValue: invoiceTriangulationData.totalValue,
                additionalData: invoiceTriangulationData.additionalData,
                status: invoiceTriangulationData.status,
                attachments: invoiceTriangulationData.attachments,
            };

            if (!data.id) {
                response = await InvoiceHttpService.createTriangulation(
                    id,
                    data,
                );
            } else {
                response = await InvoiceHttpService.updateTriangulation(
                    id,
                    data,
                );
            }

            setInvoiceTriangulationData(triangulationData(response.data));
            setDataState('unchanged');

            toast.success('Informações atualizadas com sucesso');
        } catch (error) {
            console.log(error);
            toast.error('Erro ao salvar alterações');
            setDataState(oldState);
        }
    };

    const handleApproval = async () => {
        setApproving(true);

        if (
            invoiceTriangulationData.status !==
            InvoiceTriangulationStatus.Completed
        ) {
            toast.error(
                'Para liberar a operação, é obrigatório informar o cliente e o valor total, além de enviar o arquivo da nota fiscal',
            );

            toast.info(
                'Caso esses campos já tenham sido informados, lembre-se de salvar as suas alterações',
            );

            setApproving(false);

            return;
        }

        try {
            const response = await InvoiceHttpService.approveTriangulation(id);

            setInvoiceTriangulationData(triangulationData(response.data));

            toast.success('Informações atualizadas com sucesso');
        } catch (error) {
            console.log(error);
            toast.error('Erro ao liberar operação');
        } finally {
            setApproving(false);
        }
    };

    const isProcessedOrApproved = () =>
        invoiceTriangulationData.status ===
            InvoiceTriangulationStatus.Processing ||
        invoiceTriangulationData.status === InvoiceTriangulationStatus.Approved;

    return (
        <BaseLayout>
            <Row className="header align-items-center pt-4 pb-3 pl-2">
                <Col>
                    <StyledPageTitle>
                        Operação de Venda por Conta e Ordem
                    </StyledPageTitle>
                    <StyledPageSubTitle>
                        {invoiceTriangulationData?.status ===
                        InvoiceTriangulationStatus.Processing ? (
                            <StyledStatusBadge>PROCESSANDO</StyledStatusBadge>
                        ) : invoiceTriangulationData?.status ===
                          InvoiceTriangulationStatus.Approved ? (
                            <StyledStatusBadge $approved>
                                LIBERADO
                            </StyledStatusBadge>
                        ) : (
                            ''
                        )}
                        Pedido {id}
                    </StyledPageSubTitle>
                </Col>
                <Col md={4}>
                    <StyledButtonsDiv>
                        {invoiceTriangulationData && (
                            <StyledButton
                                $hasSideButton={isAdministratorOrSupervisor}
                                onClick={handleSave}
                                disabled={
                                    disabledByCustomer ||
                                    disabledByCustomersList ||
                                    dataState === 'saving' ||
                                    approving ||
                                    isProcessedOrApproved() ||
                                    disabledByStatus
                                }
                            >
                                {dataState === 'saving' && (
                                    <Spinner
                                        animation="border"
                                        size="sm"
                                        style={{ marginRight: 10 }}
                                    />
                                )}
                                {dataState === 'saving'
                                    ? 'Salvando...'
                                    : 'Salvar alterações'}
                            </StyledButton>
                        )}

                        {invoiceTriangulationData &&
                            isAdministratorOrSupervisor && (
                                <StyledButton
                                    onClick={handleApproval}
                                    disabled={
                                        disabledByCustomer ||
                                        disabledByCustomersList ||
                                        dataState === 'saving' ||
                                        isProcessedOrApproved() ||
                                        disabledByStatus
                                    }
                                >
                                    {approving && (
                                        <Spinner
                                            animation="border"
                                            size="sm"
                                            style={{ marginRight: 10 }}
                                        />
                                    )}
                                    {approving
                                        ? 'Liberando...'
                                        : 'Liberar operação'}
                                </StyledButton>
                            )}
                    </StyledButtonsDiv>
                </Col>
            </Row>

            {(!invoiceTriangulationData || !sellerErpCode) && (
                <Row className="align-items-center pt-5 pb-3 pl-2">
                    <Col>
                        <ProgressBar
                            animated
                            now={100}
                            label={'Carregando dados da conta e ordem...'}
                        />
                    </Col>
                </Row>
            )}

            {invoiceTriangulationData && sellerErpCode && (
                <>
                    {isAdministratorOrSupervisor &&
                        disabledByStatus &&
                        !isProcessedOrApproved() && (
                            <Row className="align-items-center pt-5 pl-2">
                                <Col>
                                    <span style={{ color: '#df7210' }}>
                                        Não é possível alterar ou liberar a
                                        operação de venda por conta e ordem pois
                                        o pedido encontra-se em um status
                                        diferente de faturado.
                                    </span>
                                </Col>
                            </Row>
                        )}

                    {isProcessedOrApproved() && (
                        <Row className="align-items-center pt-5 pl-2">
                            <Col>
                                <span style={{ color: '#df7210' }}>
                                    A operação de venda por conta e ordem deste
                                    pedido já foi liberada ou está em processo
                                    de liberação, por isso as informações aqui
                                    apresentadas não podem mais ser alteradas.
                                </span>
                            </Col>
                        </Row>
                    )}

                    <InvoiceTriangulation
                        invoiceTriangulation={invoiceTriangulationData}
                        setInvoiceTriangulation={setInvoiceTriangulationData}
                        sellerErpCode={sellerErpCode}
                        customer={customer}
                        setCustomer={setCustomer}
                        disabled={
                            dataState === 'saving' ||
                            (disabledByStatus && !isProcessedOrApproved()) ||
                            disabledByCustomer ||
                            disabledByCustomersList
                        }
                        setDisabledByCustomersList={setDisabledByCustomersList}
                        readOnly={isProcessedOrApproved()}
                    />
                </>
            )}
        </BaseLayout>
    );
}
