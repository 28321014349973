import formatDate from '../../utils/formatDate';
import {
    StyledDateBadge,
    StyledImediateBadge,
    StyledOnDemandBadge,
} from './styles';

interface BaseAvailabilityBadgeProps {
    isImmediate: boolean;
    boardingDate: string | undefined;
}

export default function BaseAvailabilityBadge({
    isImmediate,
    boardingDate,
}: BaseAvailabilityBadgeProps) {
    if (isImmediate) {
        return (
            <StyledImediateBadge variant="primary">
                Imediato
            </StyledImediateBadge>
        );
    }

    if (boardingDate && boardingDate !== 'SEARCH') {
        return (
            <StyledDateBadge variant="primary">
                {formatDate(boardingDate)}
            </StyledDateBadge>
        );
    }

    return (
        <StyledOnDemandBadge variant="primary">
            Sob Consulta
        </StyledOnDemandBadge>
    );
}
