/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react';
import {
    Button,
    ButtonGroup,
    Col,
    Dropdown,
    OverlayTrigger,
    Row,
    Tooltip,
} from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as ReactMoreIcon } from '../../assets/icons/more.svg';
import { ReactComponent as ReactPrintIcon } from '../../assets/icons/print.svg';
import { ReactComponent as ReactTruckIcon } from '../../assets/icons/truck.svg';
import { ReactComponent as ReactUndoIcon } from '../../assets/icons/undo.svg';
import BaseLayout from '../../components/BaseLayout';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import InvoiceValidationModal from '../../components/Invoices/InvoiceValidationModal';
import ConfirmPaymentInvoiceModal from '../../components/OnlineStore/Invoice/ConfirmPaymentModal';
import Timeline from '../../components/OnlineStore/Invoice/Timeline';
import PaymentAndDelivery from '../../components/OnlineStore/PaymentAndDelivery';
import { NFE_URL } from '../../config/api';
import { InvoiceStatus } from '../../constants/invoiceStatus';
import { NotificationIcon } from '../../constants/notificationIcon';
import { PaymentMethod } from '../../constants/paymentMethod';
import { Role } from '../../constants/role';
import { AuthValues, useAuth } from '../../contexts/authContext';
import { useOnlineStore } from '../../contexts/onlineStoreContext';
import { budgetsDetailRoute, invoicesListRoute } from '../../routes/config';
import InvoiceHttpService from '../../services/http/invoice-http';
import { StyledPageSubTitle, StyledPageTitle } from '../../styles/pageTitle';
import formatDate from '../../utils/formatDate';
import isFriendlyHttpError from '../../utils/isFriendlyHttpError';
import userHasRoles from '../../utils/userHasRoles';
import { StyledDivInvoiceType } from './styles';
import handleResponseError from '../../utils/handleResponseError';

interface LocationState {
    tab?: string;
}

export function Detail() {
    const history = useHistory();
    const { user }: AuthValues = useAuth();
    const { id } = useParams<{ id: string }>();
    const tab = (history.location.state as LocationState)?.tab;
    const { setCart, cart } = useOnlineStore();

    const [validatedLog, setValidatedLog] = useState(null);
    const [showInvoiceValidationModal, setShowInvoiceValidationModal] =
        useState(false);
    const [showConfirmPaymentInvoiceModal, setShowConfirmPaymentInvoiceModal] =
        useState(false);
    const [billingOption, setBillingOption] = useState<string>('');
    const [loading, setLoading] = useState(true);

    const isCommercialOrAdmin = userHasRoles(user, [
        Role.Administrator,
        Role.Commercial,
        Role.CommercialSupervisor,
        Role.CustomerSuccess,
    ]);

    const isFinancialOrAdministrador = userHasRoles(user, [
        Role.Financial,
        Role.Administrator,
    ]);

    const isIntegratorOrManager = userHasRoles(user, [
        Role.Integrator,
        Role.Manager,
    ]);
    const isIntegratorOrManagerOrAdminOrContributor = userHasRoles(user, [
        Role.Integrator,
        Role.Manager,
        Role.Administrator,
        Role.Contributor,
    ]);
    const allowedToReprove = userHasRoles(user, [
        Role.Administrator,
        Role.Commercial,
        Role.CommercialSupervisor,
        Role.Financial,
    ]);

    const [showRemakeConfirmation, setShowRemakeConfirmation] = useState(false);
    const [showReproveConfirmation, setShowReproveConfirmation] =
        useState(false);

    async function loadInvoice() {
        try {
            const response: any = await InvoiceHttpService.show(id);

            if (response.data.status === InvoiceStatus.Budget) {
                history.push(`/orcamentos/${id}`);
            }

            setCart(response.data);

            return response.data;
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    async function loadLogs() {
        try {
            const { data } = await InvoiceHttpService.contributorLogs(
                parseInt(id, 10),
            );

            return data.map((log: any) => ({
                ...log,
                user:
                    log.user &&
                    log.user.avatar &&
                    Buffer.isBuffer(log.user.avatar)
                        ? {
                              avatar: Buffer.from(
                                  user.avatar,
                                  'base64',
                              ).toString('ascii'),
                          }
                        : log.user,
            }));
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    const { data, refetch } = useQuery(['invoice', id], loadInvoice, {
        staleTime: 300000,
        refetchOnWindowFocus: false,
        refetchOnMount: 'always',
    });
    const logs = useQuery(['invoiceLogs', id], loadLogs, {
        staleTime: 300000,
        refetchOnWindowFocus: false,
        refetchOnMount: 'always',
    });

    async function loadAllLogs() {
        try {
            const response = await InvoiceHttpService.logs(+id);

            response.data.forEach((log: any) => {
                if (log.description.includes('aprovou o pedido')) {
                    setValidatedLog(log.description);
                }
            });
        } catch (err) {
            console.error(err);
        }
    }

    const mutationRemake = useMutation({
        mutationKey: ['remake-invoice'],
        mutationFn: async ({
            id,
            keepAttachments,
        }: {
            id: number;
            keepAttachments: boolean;
        }) => InvoiceHttpService.remake(id, keepAttachments),
        onError: (error: any) => {
            handleResponseError(error, 'Ocorreu um erro ao refazer o pedido');
        },
        onSuccess: (successData) => {
            toast.success('Pedido refeito com sucesso');

            history.push(budgetsDetailRoute.build({ id: successData.data.id }));
        },
    });

    const invalidate = async (invoiceId: string, feedback: string) => {
        try {
            await InvoiceHttpService.invalidate(invoiceId, {
                feedback,
            });

            history.push(invoicesListRoute.path);
            toast.success('Pedido reprovado com sucesso');
        } catch (err) {
            toast.error('Ocorreu um erro ao reprovar o pedido');
        }
    };

    const mutationPrint = useMutation(
        async (invoice: any) => InvoiceHttpService.print(invoice?.id, 'order'),
        {
            onError: (error: any) => {
                if (isFriendlyHttpError(error)) {
                    toast.error(error.message);

                    return;
                }

                toast.error('Ocorreu um erro ao gerar o arquivo.');
            },
            onSettled: () => {
                toast.success('Arquivo gerado com sucesso');
            },
        },
    );

    const getNFeSimpleShipment = async () => {
        if (data.xmlSimpleShipment) {
            const a = document.createElement('a');

            a.href = `data:application/pdf;base64,${data.xmlSimpleShipment}`;
            a.download = 'NFe_Venda.pdf';
            a.click();
            return;
        }

        if (data.nfeSimpleShipement) {
            window.location.href = NFE_URL + data.nfeSimpleShipement;
            return;
        }

        const result = await InvoiceHttpService.getNfeSimpleShipment(data.id);
        const a = document.createElement('a');

        a.href = `data:application/pdf;base64,${result.data.data}`;
        a.download = 'NFe_Venda.pdf';
        a.click();
    };

    const getNFeFutureSale = async () => {
        if (data.xmlFutureSale) {
            const a = document.createElement('a');

            a.href = `data:application/pdf;base64,${data.xmlFutureSale}`;
            a.download = 'NFe_Venda_Futura.pdf';
            a.click();
            return;
        }

        if (data.nfeFutureSale) {
            window.location.href = NFE_URL + data.nfeFutureSale;
            return;
        }

        try {
            const result = await InvoiceHttpService.getNfeFutureSale(data.id);

            const a = document.createElement('a');
            a.href = `data:application/pdf;base64,${result?.data?.data}`;
            a.download = 'NFe_Venda_Futura.pdf';
            a.click();
        } catch (e: any) {
            return toast.error(e?.message ?? 'Erro ao buscar arquivo.');
        }
    };

    const resolve = () => {
        setShowInvoiceValidationModal(true);
    };

    const confirmPayment = (type: string) => {
        setBillingOption(type);
        setShowConfirmPaymentInvoiceModal(true);
    };

    const getTab = () => {
        if (tab === 'branch') {
            return 7;
        }

        return null;
    };

    useEffect(() => {
        loadAllLogs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart]);

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart]);

    const invoiceHasAttachments = data?.invoiceAttachments.filter(
        (attachment: any) => !attachment.deletedAt,
    ).length;

    const canConfirmBilling =
        isFinancialOrAdministrador &&
        data?.futureSale === 0 &&
        data?.paymentMethod === PaymentMethod.Financing &&
        data?.status === InvoiceStatus.InvoiceApproved;

    return (
        <BaseLayout>
            <Row className="header align-items-center pr-2 pl-2">
                <Col className="ml-4" lg="10" style={{ minHeight: 80 }}>
                    <div className="float-left ml-n2 mt-2 mr-2 mb-2">
                        <StyledPageTitle>
                            Detalhes do pedido {id}
                        </StyledPageTitle>
                        <StyledPageSubTitle>
                            Estimativa de embarque:{' '}
                            {formatDate(data?.deliveryDate)}
                        </StyledPageSubTitle>
                        {data?.estimatedDeliveryDate && (
                            <StyledPageSubTitle style={{ marginTop: -10 }}>
                                Previsão de entrega informada pela
                                transportadora:{' '}
                                {formatDate(data?.estimatedDeliveryDate)}
                            </StyledPageSubTitle>
                        )}
                    </div>

                    <StyledDivInvoiceType className="text-center mt-2 ml-2">
                        <div>
                            <strong>
                                {data?.branchName || 'Filial não encontrada'}
                            </strong>
                        </div>
                    </StyledDivInvoiceType>

                    <StyledDivInvoiceType className="text-center mt-2 ml-2">
                        {data && data?.type === 'PA' && (
                            <div>
                                Este pedido é <strong>um kit pronto</strong>
                            </div>
                        )}
                        {data && data?.type === 'MP' && (
                            <div>
                                Este pedido foi{' '}
                                <strong>criado com monte seu gerador</strong>
                            </div>
                        )}
                        {data && data?.type === 'BF' && (
                            <div>
                                Este pedido é{' '}
                                <strong>
                                    formado por produtos de uma promoção
                                </strong>
                            </div>
                        )}
                    </StyledDivInvoiceType>

                    {isIntegratorOrManager &&
                        logs &&
                        logs.data?.map((item: any) => (
                            <StyledDivInvoiceType className="text-center mt-2 ml-2">
                                <div>{item.description}</div>
                            </StyledDivInvoiceType>
                        ))}

                    {data && !!data?.futureSale && (
                        <StyledDivInvoiceType className="text-center mt-2 ml-2">
                            <div>
                                Este pedido é uma<strong> venda futura</strong>
                            </div>
                        </StyledDivInvoiceType>
                    )}

                    {validatedLog &&
                        isIntegratorOrManagerOrAdminOrContributor && (
                            <StyledDivInvoiceType className="text-center mt-2 ml-2">
                                {validatedLog}
                            </StyledDivInvoiceType>
                        )}

                    {data?.status === InvoiceStatus.InvoiceCanceled ||
                    data?.status === InvoiceStatus.InvoiceReproved ? (
                        <StyledDivInvoiceType className="text-center mt-2 ml-2">
                            <div>
                                Este pedido foi{' '}
                                <strong>
                                    {data?.status ===
                                        InvoiceStatus.InvoiceCanceled &&
                                        'cancelado'}
                                    {data?.status ===
                                        InvoiceStatus.InvoiceReproved &&
                                        'reprovado'}
                                </strong>
                            </div>
                        </StyledDivInvoiceType>
                    ) : (
                        ''
                    )}
                </Col>
                {!loading && (
                    <Col className="text-right">
                        <ButtonGroup>
                            <Dropdown key="left" drop="left">
                                <Dropdown.Toggle
                                    bsPrefix="nexen"
                                    as={Button}
                                    variant="light"
                                    className="mr-1"
                                    style={{
                                        backgroundColor: '#EEEEEE',
                                    }}
                                >
                                    <ReactMoreIcon
                                        fill="#bdbdbd"
                                        width="10"
                                        height="18"
                                    />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {data?.type !== 'BF' && (
                                        <>
                                            <Dropdown.Item
                                                onClick={() =>
                                                    setShowRemakeConfirmation(
                                                        true,
                                                    )
                                                }
                                            >
                                                <ReactUndoIcon
                                                    fill="#707070"
                                                    width="18"
                                                    height="18"
                                                    className="ml-n3 mr-2"
                                                />
                                                Refazer orçamento
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                        </>
                                    )}

                                    <Dropdown.Item
                                        onClick={() =>
                                            mutationPrint.mutateAsync(data)
                                        }
                                    >
                                        <ReactPrintIcon
                                            fill="#707070"
                                            width="18"
                                            height="18"
                                            className="ml-n3 mr-2"
                                        />
                                        Baixar pedido
                                    </Dropdown.Item>

                                    {data?.nfe && (
                                        <>
                                            <Dropdown.Divider />
                                            <Dropdown.Item
                                                onClick={async () =>
                                                    getNFeSimpleShipment()
                                                }
                                            >
                                                <ReactTruckIcon
                                                    fill="#707070"
                                                    width="18"
                                                    height="18"
                                                    className="ml-n3 mr-2"
                                                />
                                                NFE
                                            </Dropdown.Item>
                                        </>
                                    )}
                                    {data?.nfeFutureSale && (
                                        <>
                                            <Dropdown.Divider />
                                            <Dropdown.Item
                                                onClick={async () =>
                                                    getNFeFutureSale()
                                                }
                                            >
                                                <ReactTruckIcon
                                                    fill="#707070"
                                                    width="18"
                                                    height="18"
                                                    className="ml-n3 mr-2"
                                                />
                                                NFE venda futura
                                            </Dropdown.Item>
                                        </>
                                    )}

                                    {data?.type !== 'BF' &&
                                        data?.status ===
                                            InvoiceStatus.Invoice &&
                                        allowedToReprove && (
                                            <>
                                                <Dropdown.Divider />
                                                <Dropdown.Item
                                                    onClick={() =>
                                                        setShowReproveConfirmation(
                                                            true,
                                                        )
                                                    }
                                                >
                                                    <ReactUndoIcon
                                                        fill="#707070"
                                                        width="18"
                                                        height="18"
                                                        className="ml-n3 mr-2"
                                                    />
                                                    Reprovar pedido
                                                </Dropdown.Item>
                                            </>
                                        )}
                                </Dropdown.Menu>
                            </Dropdown>

                            {canConfirmBilling && (
                                <Button
                                    className="float-right"
                                    onClick={() => {
                                        confirmPayment(
                                            InvoiceStatus.InvoiceBilling,
                                        );
                                    }}
                                    style={{
                                        width: '180px',
                                        marginRight: 4,
                                        borderRadius: '0.25rem',
                                    }}
                                >
                                    Confirmar faturamento
                                </Button>
                            )}

                            {isCommercialOrAdmin &&
                                data?.status === InvoiceStatus.Invoice && (
                                    <>
                                        {data?.paymentMethod !==
                                        PaymentMethod.Others ? (
                                            <Button
                                                className="float-right"
                                                onClick={resolve}
                                                style={{ width: '150px' }}
                                            >
                                                Validar Pedido
                                            </Button>
                                        ) : (
                                            <>
                                                {data?.othersPaymentMethodCode ? (
                                                    <Button
                                                        className="float-right"
                                                        onClick={resolve}
                                                        style={{
                                                            width: '150px',
                                                        }}
                                                    >
                                                        Validar Pedido
                                                    </Button>
                                                ) : (
                                                    <OverlayTrigger
                                                        placement="left"
                                                        delay={{
                                                            show: 250,
                                                            hide: 400,
                                                        }}
                                                        overlay={
                                                            <Tooltip id="overlay-example">
                                                                Espere o
                                                                financeiro
                                                                validar a forma
                                                                de pagamento
                                                                antes de
                                                                prosseguir.
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <Button
                                                            style={{
                                                                width: '150px',
                                                            }}
                                                            className="btn btn-primary disabled"
                                                        >
                                                            Validar Pedido
                                                        </Button>
                                                    </OverlayTrigger>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                        </ButtonGroup>
                    </Col>
                )}
            </Row>

            <Row className="mt-5">
                <Col className="ml-2 mr-2">
                    <Timeline invoice={{ status: data?.status }} />
                </Col>
            </Row>

            <Row className="mt-5">
                <Col className="ml-2 mr-2">
                    <PaymentAndDelivery
                        disabled={
                            !isCommercialOrAdmin ||
                            data?.status !== InvoiceStatus.Invoice ||
                            data?.type === 'BF'
                        }
                        loading={loading}
                        invoice={cart ?? {}}
                        showLogsOption
                        tab={getTab()}
                    />
                </Col>
            </Row>

            <ConfirmPaymentInvoiceModal
                show={showConfirmPaymentInvoiceModal}
                option={billingOption}
                invoice={data}
                onHide={() => {
                    setShowConfirmPaymentInvoiceModal(false);
                }}
            />

            {showInvoiceValidationModal && (
                <InvoiceValidationModal
                    show={showInvoiceValidationModal}
                    invoiceId={data?.id}
                    onHide={() => {
                        refetch();
                        setShowInvoiceValidationModal(false);
                    }}
                />
            )}

            {showRemakeConfirmation && (
                <ConfirmationDialog
                    show={showRemakeConfirmation}
                    onHide={() => {
                        setShowRemakeConfirmation(false);
                    }}
                    onConfirmWithCheckbox={
                        invoiceHasAttachments
                            ? (keepAttachments) =>
                                  mutationRemake.mutateAsync({
                                      id: +id,
                                      keepAttachments,
                                  })
                            : undefined
                    }
                    onConfirm={
                        !invoiceHasAttachments
                            ? () =>
                                  mutationRemake.mutateAsync({
                                      id: +id,
                                      keepAttachments: false,
                                  })
                            : undefined
                    }
                    title="Refazer pedido"
                    text="Deseja refazer o pedido selecionado?"
                    icon={NotificationIcon.Info}
                    label="Desejo manter os anexos do pedido original"
                />
            )}

            {showReproveConfirmation && (
                <ConfirmationDialog
                    show={showReproveConfirmation}
                    onHide={() => setShowReproveConfirmation(false)}
                    onConfirmWithFeedback={(feedback: string) =>
                        invalidate(id, feedback)
                    }
                    title="Reprovar pedido"
                    text="Deseja reprovar o pedido selecionado?"
                    icon={NotificationIcon.Warning}
                    required={false}
                />
            )}
        </BaseLayout>
    );
}
