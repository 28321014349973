import RequestV2 from './request-v2.http';

export default class PaymentHttpService {
    private static uri = 'payments';

    //= ============v2=================

    public static listErpPaymentMethods() {
        return RequestV2.get(`${this.uri}/erp`);
    }

    public static listPaymentMethods() {
        return RequestV2.get(`${this.uri}`);
    }

    public static readManyOthersPaymentMethods(data: {}) {
        return RequestV2.get(`${this.uri}/others`, data);
    }

    public static generateCheckout(invoiceId: string) {
        return RequestV2.post(`${this.uri}/invoice/${invoiceId}/checkout`);
    }

    public static generatePix(invoiceId: string) {
        return RequestV2.post(`${this.uri}/invoice/${invoiceId}/pix`);
    }

    public static listBankAccounts() {
        return RequestV2.get(`${this.uri}/bank-accounts`);
    }
}
