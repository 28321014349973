import { Dropdown, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { budgetsDetailRoute } from '../../../routes/config';
import { ReactComponent as ReactPlayIcon } from '../../../assets/icons/play.svg';
import { ReactComponent as ReactMoreIcon } from '../../../assets/icons/more.svg';
import { ReactComponent as ReactCancelIcon } from '../../../assets/icons/cancel.svg';
import { ReactComponent as ReactPrintIcon } from '../../../assets/icons/print.svg';
import { ReactComponent as ReactListIcon } from '../../../assets/icons/list.svg';
import { ReactComponent as ReactUndoIcon } from '../../../assets/icons/undo.svg';
import useInvoices from '../../../hooks/useInvoices';
import InvoiceHttpService from '../../../services/http/invoice-http';
import isFriendlyHttpError from '../../../utils/isFriendlyHttpError';

interface BudgetTableRowDropdownProps {
    budget: any;
}

export default function BudgetTableRowDropdown({
    budget,
}: BudgetTableRowDropdownProps) {
    const {
        setSelectedInvoice,
        setShowMakeConfirmation,
        setShowRemakeConfirmation,
        setShowRemoveConfirmation,
    } = useInvoices();

    const printMutation = useMutation({
        mutationFn: async (id: number) =>
            InvoiceHttpService.print(id, 'budget'),
        onError: (error: any) => {
            if (isFriendlyHttpError(error)) {
                toast.error(error.message);
                return;
            }

            toast.error('Ocorreu um erro ao gerar o arquivo.');
        },
        onSuccess: () => {
            toast.success('Arquivo gerado com sucesso');
        },
    });

    const currentDate = new Date();
    const invoiceDate = new Date(budget?.finishedAt);
    const expirationDate = new Date(
        invoiceDate.getTime() + 7 * 24 * 60 * 60 * 1000,
    );

    const isExpired = expirationDate.getTime() <= currentDate.getTime();

    return (
        <Dropdown key="left">
            <Dropdown.Toggle bsPrefix="nexen" as={Button} variant="text">
                <ReactMoreIcon fill="#bdbdbd" width="10" height="20" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item as={Link} to={budgetsDetailRoute.build(budget)}>
                    <ReactListIcon
                        fill="#707070"
                        width="18"
                        height="18"
                        className="ml-n3 mr-2"
                    />
                    Detalhes do orçamento
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                    onClick={() =>
                        printMutation.mutateAsync(budget.id as number)
                    }
                >
                    <ReactPrintIcon
                        fill="#707070"
                        width="18"
                        height="18"
                        className="ml-n3 mr-2"
                    />
                    Baixar orçamento
                </Dropdown.Item>
                <Dropdown.Divider />
                {isExpired ? (
                    <>
                        <Dropdown.Item
                            onClick={() => {
                                setSelectedInvoice(budget);
                                setShowRemakeConfirmation(true);
                            }}
                        >
                            <ReactUndoIcon
                                fill="#707070"
                                width="18"
                                height="18"
                                className="ml-n3 mr-2"
                            />
                            Refazer orçamento
                        </Dropdown.Item>

                        <Dropdown.Divider />
                    </>
                ) : (
                    <>
                        <Dropdown.Item
                            onClick={() => {
                                setSelectedInvoice(budget);
                                setShowMakeConfirmation(true);
                            }}
                        >
                            <ReactPlayIcon
                                fill="#707070"
                                width="18"
                                height="18"
                                className="ml-n3 mr-2"
                            />
                            Fazer pedido
                        </Dropdown.Item>
                        <Dropdown.Divider />
                    </>
                )}
                <Dropdown.Item
                    onClick={() => {
                        setSelectedInvoice(budget);
                        setShowRemoveConfirmation(true);
                    }}
                >
                    <ReactCancelIcon
                        fill="#707070"
                        width="18"
                        height="18"
                        className="ml-n3 mr-2"
                    />
                    Remover orçamento
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}
