import { StyledNumericInput } from '../../../pages/OnlineStore/styles';

export interface ILocalItemProductNumericInput {
    B1_COD: string;
    B1_DESC: string;
    BM_DESC: string;
    CAT_DESC: string;
    VLR_TOT: number;
    boardingDate: string;
}

export interface ILocalItemWithQuantityProductNumericInput
    extends ILocalItemProductNumericInput {
    quantity: number;
    totalValue: number;
}

export interface IPropsProductNumericInput {
    localItem: ILocalItemProductNumericInput;
    quantityValue?: number;
    onChange: (data: ILocalItemWithQuantityProductNumericInput) => void;
    disabled?: boolean;
}

export default function ProductNumericInput({
    localItem,
    onChange,
    quantityValue,
    disabled,
}: IPropsProductNumericInput) {
    function updateLocalItem(quantity: number) {
        const localItemWithQuantity: ILocalItemWithQuantityProductNumericInput =
            {
                ...localItem,
                quantity,
                totalValue:
                    parseFloat(`${localItem.VLR_TOT}`) *
                    parseInt(`${quantity}`, 10),
            };
        return onChange(localItemWithQuantity);
    }

    function handleChange(quantity: number) {
        return updateLocalItem(parseInt(`${quantity}`, 10));
    }

    return (
        <StyledNumericInput
            type="number"
            disabled={disabled}
            className="react-numeric-input-small"
            onChange={(value, _, input) => {
                const newValue = handleChange(value);
                // eslint-disable-next-line no-param-reassign
                input.value = `${newValue}`;
            }}
            value={quantityValue ?? 0}
            min={0}
            size={5}
            mobile
        />
    );
}
