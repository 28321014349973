/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Spinner } from 'react-bootstrap';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import BaseLayout from '../../BaseLayout';
import { useOnlineStore } from '../../../contexts/onlineStoreContext';
import SideNavBar from '../NavBar/SideNavBar';
import InitialInformations from '../InitialInformations';
import InvoiceHttpService from '../../../services/http/invoice-http';
import Cart from '../Cart';
import Detail from '../Detail';
import { getSelectedSeller } from '../../../utils/getSelectedSeller';
import SideCart from '../SideCart';
import PaymentAndDelivery from '../PaymentAndDelivery';
import CartAlertModal from '../CartAlertModal';
import Steps from '../Steps';
import ProductFilter from '../ProductFilter';
import { SellerValues, useSeller } from '../../../contexts/sellerContext';
import ProductHttpService from '../../../services/http/product.http';
import DownloadTechnicalDocumentProduct from '../Download-Technical-Document-Product';

interface Props {
    content: any;
    productType: any;
}

const Base: React.FC<Props> = ({ content, productType }) => {
    const { seller }: SellerValues = useSeller();

    const [isFirstRender, setIsFirstRender] = useState(true);

    const rowsPerPage = 25;
    const {
        products,
        setProducts,
        groupId,
        setGroupId,
        groups,
        loading,
        setLoading,
        setGroups,
        step,
        setCart,
        showDetailsModal,
        setShowDetailsModal,
        sideCartVisible,
        reloadCart,
        setReloadCart,
        cart,
        paymentItem,
        term,
        reload,
        brandId,
        setBrands,
        page,
        setPage,
        setBrandId,
        showDownloadTechnicalDocumentModal,
        setShowDownloadTechnicalDocumentModal,
        items,
    } = useOnlineStore();

    const [showAlertModal, setShowAlertModal] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(false);

    const infiniteRef: any = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: () => {
            if (hasNextPage) {
                setPage((p: any) => (p += 1));
            }
        },
    });

    async function leadGroups() {
        if (productType === 'MP') {
            const groups = await ProductHttpService.getGroups();
            setGroups(groups.data);
            setGroupId('1001');
        } else {
            const categories = await ProductHttpService.getCategories();
            setGroups(categories.data);
        }
    }

    async function loadProducts(): Promise<void> {
        try {
            setLoading(true);

            let brandIdParams = brandId;

            const seller = getSelectedSeller();

            if (groupId === '1010' && productType === 'MP') {
                const responseBrands = await ProductHttpService.getBrands(
                    groupId,
                );
                const newBrands = responseBrands.data;

                if (!brandId) {
                    brandIdParams = newBrands[0].ZAM_COD;
                    setBrandId(brandIdParams);
                }
                setBrands(newBrands || []);
            }

            const params: any = {
                sellerId: seller ? seller.erpCode : '',
                groupId: groupId,
                term: encodeURIComponent(term),
                skip: page,
                take: rowsPerPage,
                type: productType,
                brandId: groupId === '1010' ? brandIdParams : undefined,
            };

            const response = await ProductHttpService.index(params);

            setHasNextPage(response.data?.data?.length >= rowsPerPage);
            if (!groups.length) await leadGroups();

            if (page === 1) {
                setProducts(response.data.data);
            } else {
                setProducts((products: any) => [
                    ...products,
                    ...response.data.data,
                ]);
            }
        } catch (err) {
            setHasNextPage(false);
        } finally {
            setLoading(false);
            setIsFirstRender(false);
        }
    }

    async function loadCart(): Promise<void> {
        try {
            const resp = await InvoiceHttpService.getUserCart();

            setCart(resp.data);
        } catch (error) {
            setCart({});
        }
    }

    useEffect(() => {
        if (!isFirstRender && term) {
            const timer = setTimeout(() => {
                setProducts([]);
                setPage(1);
                loadProducts();
            }, 800);

            return () => clearTimeout(timer);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [term]);

    useEffect(() => {
        if (step === 3 || paymentItem === 0) {
            setReloadCart((reloadCart: boolean) => !reloadCart);
        }

        if (step === 2 && !isFirstRender) {
            setProducts([]);
            setPage(1);
            loadProducts();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    useEffect(() => {
        if (!isFirstRender) {
            loadProducts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    useEffect(() => {
        async function load() {
            await loadCart();
        }

        load();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadCart]);

    useEffect(() => {
        if (!isFirstRender) {
            setLoading(true);
            setProducts([]);
            setPage(1);
            loadProducts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupId, brandId, reload]);

    useEffect(() => {
        loadCart();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seller]);

    useEffect(() => {
        if (step === 1 && localStorage.getItem('showCartAlert') !== '0') {
            setShowAlertModal(true);
        }

        loadProducts();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log(items);
    }, [items, items.length]);

    return (
        <>
            <Detail
                show={showDetailsModal}
                onHide={() => {
                    setShowDetailsModal(false);
                }}
            />
            <DownloadTechnicalDocumentProduct
                files={showDownloadTechnicalDocumentModal}
                onHide={() => {
                    setShowDownloadTechnicalDocumentModal([]);
                }}
            />

            <BaseLayout>
                <Steps />
                <br />

                <Row className={'mt-n3'} style={{ height: '100vh' }}>
                    <Col
                        style={{
                            backgroundColor: '#FAFAFA',
                            borderRight: '1px solid #f2f2f2',
                        }}
                        lg={2}
                        md={12}
                        sm={12}
                        xl={2}
                    >
                        <SideNavBar />
                    </Col>

                    <Col className={'mt-4 mx-2'}>
                        <>
                            {step === 1 && <InitialInformations />}

                            {step === 2 && (
                                <>
                                    <ProductFilter loading={loading} />

                                    <Row className="mx-0" ref={infiniteRef}>
                                        {content}
                                    </Row>

                                    <Row>
                                        <br />

                                        {((loading &&
                                            hasNextPage &&
                                            products.length !== 0) ||
                                            (loading &&
                                                products.length === 0)) && (
                                            <Col
                                                md={12}
                                                className="text-center"
                                            >
                                                <Spinner animation={'border'} />
                                            </Col>
                                        )}

                                        {!hasNextPage &&
                                            !loading &&
                                            products.length === 0 && (
                                                <Col
                                                    md={12}
                                                    className="text-center"
                                                >
                                                    <strong
                                                        style={{
                                                            color: '#adadad',
                                                        }}
                                                    >
                                                        Sem itens para carregar
                                                    </strong>
                                                </Col>
                                            )}
                                    </Row>
                                </>
                            )}
                            {step === 3 && (
                                <Cart invoice={cart ?? {}} key={step} />
                            )}
                            {step === 4 && (
                                <PaymentAndDelivery
                                    disabled={false}
                                    invoice={cart ?? {}}
                                />
                            )}
                        </>
                    </Col>
                </Row>
                <CartAlertModal
                    show={showAlertModal}
                    onHide={() => {
                        setShowAlertModal(false);
                    }}
                />

                <div style={{ display: sideCartVisible ? 'block' : 'none' }}>
                    <SideCart />
                </div>
            </BaseLayout>
        </>
    );
};

Base.propTypes = {
    content: PropTypes.object,
    productType: PropTypes.string,
};

export default Base;
