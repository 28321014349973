import RequestV2 from './request-v2.http';

export default class FreightsHttpService {
    private static uri = 'freight';

    public static async readOne(addressCep: string) {
        return RequestV2.get(`${this.uri}/cep/${addressCep}`);
    }

    public static async getFreightPercentageByOrderValue(
        orderValue: number,
        stateAcronym: string,
    ) {
        return RequestV2.get(`${this.uri}/rate/${orderValue}/${stateAcronym}`);
    }
}
