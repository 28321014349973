/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable prefer-const */
/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-return-await */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Col, Dropdown, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import BaseLayout from '../../components/BaseLayout';
import Timeline from '../../components/OnlineStore/Invoice/Timeline';
import InvoiceHttpService from '../../services/http/invoice-http';
import formatDate from '../../utils/formatDate';
import StyledDivInvoiceType from './styles';
import { InvoiceStatus } from '../../constants/invoiceStatus';
import PaymentAndDelivery from '../../components/OnlineStore/PaymentAndDelivery';
import { useOnlineStore } from '../../contexts/onlineStoreContext';
import { StyledPageSubTitle, StyledPageTitle } from '../../styles/pageTitle';
import { ReactComponent as ReactCancelIcon } from '../../assets/icons/cancel.svg';
import { ReactComponent as ReactMoreIcon } from '../../assets/icons/more.svg';
import { ReactComponent as ReactPlayIcon } from '../../assets/icons/play.svg';
import { ReactComponent as ReactUndoIcon } from '../../assets/icons/undo.svg';
import { ReactComponent as ReactPrintIcon } from '../../assets/icons/print.svg';
import isFriendlyHttpError from '../../utils/isFriendlyHttpError';
import {
    budgetsDetailRoute,
    budgetsListRoute,
    invoiceDetailRoute,
} from '../../routes/config';
import { SellerValues, useSeller } from '../../contexts/sellerContext';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { NotificationIcon } from '../../constants/notificationIcon';
import BranchHttpService from '../../services/http/branch-http';

const Detail: React.FC = () => {
    const history = useHistory();

    const { id } = useParams<{ id: string }>();
    const { setCart, cart } = useOnlineStore();
    const { seller }: SellerValues = useSeller();

    const [showMakeConfirmation, setShowMakeConfirmation] = useState(false);
    const [showRemakeConfirmation, setShowRemakeConfirmation] = useState(false);
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);

    async function loadBudget() {
        const response: any = await InvoiceHttpService.show(id);

        const branchesResponse = await BranchHttpService.readMany({});

        const companyCode = response.data.erpTenantId.split(',')[0];

        const branchCode = response.data.erpTenantId.split(',')[1];

        const branch = branchesResponse.data.data.find(
            (branch: any) =>
                branch.companyCode === companyCode &&
                branch.branchCode === branchCode,
        );

        response.data.branchName = branch
            ? branch.name
            : 'Filial não encontrada';

        setCart(response.data);

        return response.data;
    }

    const { data } = useQuery(['budget', id], loadBudget, {
        refetchOnWindowFocus: false,
    });

    const mutationMake = useMutation(
        async (id: any) =>
            await InvoiceHttpService.setStatus(id, {
                status: InvoiceStatus.Invoice,
            }),
        {
            onError: (error: any) => {
                console.log(error);
                if (isFriendlyHttpError(error)) {
                    toast.error(error.message);

                    return;
                }

                toast.error('Ocorreu um erro ao fazer o pedido.');
            },
            onSuccess: () => {
                toast.success('Pedido realizado com sucesso');

                history.push(invoiceDetailRoute.build({ id }));
            },
        },
    );

    const mutationRemake = useMutation({
        mutationKey: ['remake-invoice'],
        mutationFn: async ({
            id,
            keepAttachments,
        }: {
            id: number;
            keepAttachments: boolean;
        }) => await InvoiceHttpService.remake(id, keepAttachments),
        onError: (error: any) => {
            if (isFriendlyHttpError(error)) {
                toast.error(error.message);

                return;
            }

            toast.error('Ocorreu um erro ao refazer o orçamento.');
        },
        onSuccess: (data) => {
            toast.success('Orçamento refeito com sucesso');

            history.push(budgetsDetailRoute.build({ id: data.data.id }));
        },
    });

    const mutationPrint = useMutation(
        async (invoice: any) =>
            await InvoiceHttpService.print(invoice?.id, 'budget'),
        {
            onError: (error: any) => {
                if (isFriendlyHttpError(error)) {
                    toast.error(error.message);

                    return;
                }

                toast.error('Ocorreu um erro ao gerar o arquivo.');
            },
            onSettled: () => {
                toast.success('Arquivo gerado com sucesso');
            },
        },
    );

    const mutationRemove = useMutation(
        async (id: any) => await InvoiceHttpService.destroy(id),
        {
            onError: (error: any) => {
                if (isFriendlyHttpError(error)) {
                    toast.error(error.message);

                    return;
                }

                toast.error('Ocorreu um erro ao remover o orçamento.');
            },
            onSuccess: () => {
                toast.success('Orçamento removido com sucesso');

                history.push(budgetsListRoute.build());
            },
        },
    );

    const checkExpiration = (status: any) => {
        if (status === InvoiceStatus.BudgetExpired) {
            return true;
        }

        return false;
    };

    useEffect(() => {
        loadBudget();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seller]);

    const budgetHasAttachments = data?.invoiceAttachments.filter(
        (attachment: any) => !attachment.deletedAt,
    ).length;

    return (
        <BaseLayout>
            <Row className="header align-items-center pr-2 pl-2">
                <Col className="ml-4" lg="10" style={{ minHeight: 80 }}>
                    <div className="float-left ml-n2 mt-2 mr-2 mb-2">
                        <StyledPageTitle>
                            Detalhes do orçamento {id}
                        </StyledPageTitle>
                        <StyledPageSubTitle>
                            Estimativa de embarque:{' '}
                            {formatDate(data?.deliveryDate)}
                        </StyledPageSubTitle>
                    </div>

                    <StyledDivInvoiceType className="text-center mt-2 ml-2">
                        <div>
                            <strong>{data?.branchName}</strong>
                        </div>
                    </StyledDivInvoiceType>

                    <StyledDivInvoiceType className="text-center mt-2 ml-2">
                        {data && data?.type === 'PA' && (
                            <div>
                                Este pedido é <strong>um kit pronto</strong>
                            </div>
                        )}
                        {data && data?.type === 'MP' && (
                            <div>
                                Este pedido foi{' '}
                                <strong>criado com monte seu gerador</strong>
                            </div>
                        )}
                    </StyledDivInvoiceType>
                    {data?.status === InvoiceStatus.InvoiceCanceled ||
                    data?.status === InvoiceStatus.InvoiceReproved ? (
                        <StyledDivInvoiceType className="text-center mt-2 ml-2">
                            Este pedido foi {''}{' '}
                            <strong>
                                {data?.status ===
                                    InvoiceStatus.InvoiceCanceled &&
                                    'cancelado'}
                                {data?.status ===
                                    InvoiceStatus.InvoiceReproved &&
                                    'reprovado'}
                            </strong>
                        </StyledDivInvoiceType>
                    ) : (
                        ''
                    )}
                </Col>

                <Col className="text-right">
                    <ButtonGroup>
                        <Dropdown key="left" drop="left">
                            <Dropdown.Toggle
                                bsPrefix="nexen"
                                as={Button}
                                variant={'light'}
                                className="mr-1"
                                style={{
                                    backgroundColor: '#EEEEEE',
                                }}
                            >
                                <ReactMoreIcon
                                    fill="#bdbdbd"
                                    width="10"
                                    height="18"
                                />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() =>
                                        mutationPrint.mutateAsync(data ?? {})
                                    }
                                >
                                    <ReactPrintIcon
                                        fill="#707070"
                                        width="18"
                                        height="18"
                                        className="ml-n3 mr-2"
                                    />
                                    Baixar orçamento
                                </Dropdown.Item>
                                <Dropdown.Divider />

                                {!checkExpiration(data?.status) && (
                                    <>
                                        <Dropdown.Item
                                            onClick={() =>
                                                setShowMakeConfirmation(true)
                                            }
                                        >
                                            <ReactPlayIcon
                                                fill="#707070"
                                                width="18"
                                                height="18"
                                                className="ml-n3 mr-2"
                                            />
                                            Fazer pedido
                                        </Dropdown.Item>
                                        <Dropdown.Divider />
                                    </>
                                )}

                                {checkExpiration(data?.status) && (
                                    <>
                                        <Dropdown.Item
                                            onClick={() =>
                                                setShowRemakeConfirmation(true)
                                            }
                                        >
                                            <ReactUndoIcon
                                                fill="#707070"
                                                width="18"
                                                height="18"
                                                className="ml-n3 mr-2"
                                            />
                                            Refazer orçamento
                                        </Dropdown.Item>

                                        <Dropdown.Divider />
                                    </>
                                )}

                                <Dropdown.Item
                                    onClick={() =>
                                        setShowRemoveConfirmation(true)
                                    }
                                >
                                    <ReactCancelIcon
                                        fill="#707070"
                                        width="18"
                                        height="18"
                                        className="ml-n3 mr-2"
                                    />
                                    Remover orçamento
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </ButtonGroup>
                </Col>
            </Row>

            <Row className="mt-5">
                <Col className="ml-2 mr-2">
                    <Timeline invoice={{ status: data?.status }} />
                </Col>
            </Row>

            <Row className="mt-5">
                <Col className="ml-2 mr-2">
                    <PaymentAndDelivery
                        disabled={false}
                        invoice={cart ?? {}}
                        showLogsOption={true}
                    />
                </Col>
            </Row>

            {showMakeConfirmation && (
                <ConfirmationDialog
                    show={showMakeConfirmation}
                    onHide={() => setShowMakeConfirmation(false)}
                    onConfirm={() => mutationMake.mutateAsync(id)}
                    title="Fazer pedido"
                    text="Deseja confirmar o orçamento selecionado como um pedido?"
                    icon={NotificationIcon.Info}
                />
            )}

            {showRemakeConfirmation && (
                <ConfirmationDialog
                    show={showRemakeConfirmation}
                    onHide={() => {
                        setShowRemakeConfirmation(false);
                    }}
                    onConfirmWithCheckbox={
                        budgetHasAttachments
                            ? (keepAttachments) =>
                                  mutationRemake.mutateAsync({
                                      id: +id,
                                      keepAttachments,
                                  })
                            : undefined
                    }
                    onConfirm={
                        !budgetHasAttachments
                            ? () =>
                                  mutationRemake.mutateAsync({
                                      id: +id,
                                      keepAttachments: false,
                                  })
                            : undefined
                    }
                    title="Refazer pedido"
                    text="Deseja refazer o pedido selecionado?"
                    icon={NotificationIcon.Info}
                    label="Desejo manter os anexos do pedido original"
                />
            )}

            {showRemoveConfirmation && (
                <ConfirmationDialog
                    show={showRemoveConfirmation}
                    onHide={() => setShowRemoveConfirmation(false)}
                    onConfirm={() => mutationRemove.mutateAsync(id)}
                    title="Remover orçamento"
                    text="Deseja remover o orçamento selecionado?"
                    icon={NotificationIcon.Warning}
                />
            )}
        </BaseLayout>
    );
};

export { Detail };
