/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import { useEffect, useRef, useState } from 'react';
import {
    Col,
    Form,
    InputGroup,
    Nav,
    Row,
    Spinner,
    Table,
} from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { InvoiceStatus } from '../../../constants/invoiceStatus';
import ISaveInvoiceItemDto from '../../../dtos/ISaveInvoiceItemDto';
import { StyledTdLink, StyledTh } from '../../../pages/OnlineStore/styles';
import { formatToQueryParam } from '../../../utils/strings';
import { toFixed } from '../../../utils/toFixed';
import NumericInput from '../../NumericInput';
import {
    steps as generatorSteps,
    readyKitsSteps,
} from '../../../constants/onlineStore';
import { ReactComponent as ReactArrowRight } from '../../../assets/icons/timeline/arrow_right.svg';
import { StyledSquare, StyledTitleNav } from '../../OnlineStore/Base/styles';
import useWindowSize from '../../../hooks/useWindowSize';
import { rowsPerPage } from '../../../constants/pagination';
import AvailabilityBadge from '../../AvailabilityBadge';
import ProductHttpService from '../../../services/http/product.http';
import { getSelectedSeller } from '../../../utils/getSelectedSeller';
import BrandMenuItem from '../../OnlineStore/BrandMenuItem';
import Brand from '../../../shared/interfaces/brand.interface';
import Cart from '../../OnlineStore/Cart';

interface Props {
    invoice: any;
    step: number;
    enableEdit: boolean;
    disabled: boolean;
    addToInvoiceItems: ({
        invoiceItems,
        totalInvoice,
    }: {
        invoiceItems: any[];
        totalInvoice: number;
    }) => void;
    setInvoice: (value: any) => void;
    setStep: (step: number) => void;
}

export default function ProductsList({
    invoice,
    step,
    enableEdit,
    disabled,
    addToInvoiceItems,
    setInvoice,
    setStep,
}: Props) {
    const [term, setTerm] = useState('');
    const [tab, setTab] = useState('0');
    const [steps, setSteps] = useState([]);
    const [groups, setGroups] = useState([]);
    const [groupId, setGroupId] = useState(1001);
    const [brands, setBrands] = useState([]);
    const [brandId, setBrandId] = useState(null);
    const [hasNextPage, setHasNextPage] = useState(false);

    const [isFirstRender, setIsFirstRender] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);

    const [items, setItems] = useState([]);

    const size = useWindowSize();
    const loadMoreRef = useRef();

    const infiniteRef: any = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: () => {
            if (hasNextPage) {
                setCurrentPage((p: any) => p + 1);
            }
        },
    });

    function handleUpdateProducts(products: any) {
        setItems(() => {
            return products.map((itemData: any) => {
                const itemFromInvoice = invoice.invoiceItems.find(
                    (invoiceItem: any) =>
                        itemData.B1_COD === invoiceItem.productErpCode,
                );
                if (itemFromInvoice) {
                    itemData = {
                        ...itemData,
                        quantity: itemFromInvoice.quantity,
                    };
                }
                return itemData;
            });
        });
    }

    async function leadGroups() {
        if (invoice.type === 'MP') {
            const responseGroups = await ProductHttpService.getGroups();
            setGroups(responseGroups.data);
            setGroupId(1001);
        }
    }

    async function loadProducts(): Promise<void> {
        try {
            setLoading(true);

            let brandIdParams = brandId;
            if (!groups.length) await leadGroups();

            const seller = getSelectedSeller();

            if (groupId === 1010 && invoice.type === 'MP') {
                const responseBrands = await ProductHttpService.getBrands(
                    groupId,
                );
                const newBrands = responseBrands.data;

                if (!brandId) {
                    brandIdParams = newBrands[0].ZAM_COD;
                    setBrandId(brandIdParams);
                }
                setBrands(newBrands || []);
            }

            const params: any = {
                sellerId: seller ? seller.erpCode : '',
                groupId,
                term: encodeURIComponent(term),
                skip: currentPage,
                take: rowsPerPage,
                type: invoice.type,
                brandId: groupId === 1010 ? brandIdParams : undefined,
                invoiceId: invoice.id,
            };

            const response = await ProductHttpService.index(params);

            setHasNextPage(response.data?.data?.length >= rowsPerPage);

            if (currentPage === 1) {
                handleUpdateProducts(response.data.data);
            } else {
                handleUpdateProducts([...items, ...response.data.data]);
            }
        } catch (err) {
            setHasNextPage(false);
        } finally {
            setLoading(false);
            setIsFirstRender(false);
        }
    }

    const handleGroup = (id: number) => {
        setGroupId(id);
    };

    const handleBrand = (id: string) => {
        setBrandId(id);
    };

    const nextStep = (item: any) => {
        if (invoice.invoiceItems && invoice.invoiceItems.length > 0) {
            setStep(item.number);
        } else if (item.number > 2) {
            setStep(step);
        } else {
            setStep(item.number);
        }
    };

    useEffect(() => {
        if (!isFirstRender && term) {
            const timer = setTimeout(() => {
                setItems([]);
                setCurrentPage(1);
                loadProducts();
            }, 800);

            return () => clearTimeout(timer);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [term]);

    useEffect(() => {
        if (!isFirstRender) {
            loadProducts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    useEffect(() => {
        const generators = generatorSteps.filter(
            (item: any) => item.number !== 1 && item.number !== 4,
        );
        const kits = readyKitsSteps.filter((item: any) => item.number !== 4);

        setSteps(invoice.type === 'MP' ? generators : kits);

        loadProducts();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isFirstRender) {
            setLoading(true);
            setItems([]);
            setCurrentPage(1);
            loadProducts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupId, brandId]);

    return (
        <>
            {step === 2 && enableEdit && (
                <>
                    <Col>
                        <Row className="align-items-center">
                            <Row>
                                {steps.map((item: any, index: any) => (
                                    <div key={index}>
                                        <Nav.Item>
                                            <Nav.Link
                                                style={
                                                    step === item.number
                                                        ? {
                                                              color: '#F2994A',
                                                              fontWeight: 600,
                                                          }
                                                        : {
                                                              color: 'rgba(112, 112, 112, 0.3)',
                                                          }
                                                }
                                                eventKey={item.number}
                                                onClick={async () => {
                                                    nextStep(item);
                                                }}
                                            >
                                                <Row>
                                                    <Col xl={1}>
                                                        <StyledSquare
                                                            background={
                                                                step ===
                                                                item.number
                                                                    ? '#F2994A'
                                                                    : '#fbf3e6'
                                                            }
                                                        >
                                                            <h5
                                                                style={{
                                                                    fontSize: 38,
                                                                    fontWeight: 600,
                                                                    color:
                                                                        step ===
                                                                        item.number
                                                                            ? '#F2994A'
                                                                            : 'rgba(112, 112, 112, 0.3)',
                                                                }}
                                                            >
                                                                {index + 1}
                                                            </h5>
                                                        </StyledSquare>
                                                    </Col>

                                                    <Col className="my-auto">
                                                        <StyledTitleNav
                                                            className={
                                                                size.width <
                                                                    1500 &&
                                                                invoice.type ===
                                                                    'MP'
                                                                    ? 'mr-n4'
                                                                    : 'ml-3'
                                                            }
                                                            color={
                                                                step ===
                                                                item.number
                                                                    ? '#F2994A'
                                                                    : 'rgba(112, 112, 112, 0.3)'
                                                            }
                                                        >
                                                            {item.title}
                                                        </StyledTitleNav>
                                                    </Col>
                                                    {item.number !== 3 && (
                                                        <ReactArrowRight
                                                            style={{
                                                                position:
                                                                    'relative',
                                                                top: '-3px',
                                                            }}
                                                            className={`my-auto ${
                                                                size.width <
                                                                    1500 &&
                                                                invoice.type ===
                                                                    'MP'
                                                                    ? ''
                                                                    : 'mr-4'
                                                            }  `}
                                                            fill={
                                                                step ===
                                                                item.number
                                                                    ? '#F2994A'
                                                                    : 'rgba(112, 112, 112, 0.3)'
                                                            }
                                                        />
                                                    )}
                                                </Row>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </div>
                                ))}
                            </Row>
                        </Row>
                    </Col>
                    <Row>
                        <Col>
                            <Nav
                                activeKey={tab}
                                className="ma-0 pt-3 ml-n3"
                                onSelect={(selectedKey: string) =>
                                    setTab(selectedKey)
                                }
                            >
                                {groups.map((item: any, index: any) => (
                                    <Nav.Item key={index}>
                                        <Nav.Link
                                            disabled={loading}
                                            style={
                                                parseInt(tab, 10) === index
                                                    ? {
                                                          color: '#F2994A',
                                                          fontWeight: 600,
                                                      }
                                                    : {
                                                          color: '#707070',
                                                      }
                                            }
                                            eventKey={index}
                                            onClick={() =>
                                                handleGroup(item.BM_GRUPO)
                                            }
                                        >
                                            {item.BM_DESC}
                                        </Nav.Link>
                                    </Nav.Item>
                                ))}

                                {brands.length > 0 && groupId === 1010 && (
                                    <Nav
                                        activeKey={brandId}
                                        className="ma-0 pt-2 ml-n3"
                                        onSelect={handleBrand}
                                    >
                                        {brands?.map((brand: Brand) => (
                                            <BrandMenuItem
                                                key={brand.ZAM_COD}
                                                brand={brand}
                                                active={
                                                    parseInt(
                                                        `${brandId}`,
                                                        10,
                                                    ) ===
                                                    parseInt(
                                                        `${brand.ZAM_COD}`,
                                                        10,
                                                    )
                                                }
                                            />
                                        ))}
                                    </Nav>
                                )}
                            </Nav>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col md="6" lg="12">
                            <Form>
                                <Form.Group>
                                    <InputGroup size="lg">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>
                                                <i className="fas fa-search" />
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            type="text"
                                            value={term}
                                            onChange={(event) =>
                                                setTerm(event.target.value)
                                            }
                                            style={{ borderColor: '#F2F2F2' }}
                                            placeholder="Digite aqui o que procura..."
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                    <Row className="mx-0" ref={infiniteRef}>
                        <Table
                            hover
                            bordered
                            className="mx-0 text-center"
                            size="sm"
                        >
                            <thead>
                                <tr>
                                    <StyledTh>CÓDIGO</StyledTh>
                                    <StyledTh>DESCRIÇÃO</StyledTh>
                                    <StyledTh>DISPONIBILIDADE</StyledTh>
                                    <StyledTh>VALOR UNITÁRIO</StyledTh>
                                    <StyledTh>QUANTIDADE</StyledTh>
                                    <StyledTh>VALOR TOTAL</StyledTh>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item, index) => {
                                    return (
                                        <tr
                                            style={{
                                                backgroundColor:
                                                    item?.quantity > 0
                                                        ? 'rgba(242, 153, 74, 0.08)'
                                                        : '#ffffff',
                                            }}
                                            key={index}
                                        >
                                            <td>
                                                <Highlighter
                                                    autoEscape
                                                    highlightClassName="highlight-term"
                                                    searchWords={[
                                                        formatToQueryParam(
                                                            term,
                                                        ),
                                                    ]}
                                                    textToHighlight={
                                                        item?.B1_COD
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <StyledTdLink
                                                    onClick={() => {
                                                        // setSelectedProduct(item);
                                                        // setSelectedProductId(item?.URL_ID);
                                                        // setShowDetailsModal(true);
                                                    }}
                                                >
                                                    <Highlighter
                                                        autoEscape
                                                        highlightClassName="highlight-term"
                                                        searchWords={[
                                                            formatToQueryParam(
                                                                term,
                                                            ),
                                                        ]}
                                                        textToHighlight={
                                                            item?.B1_DESC
                                                        }
                                                    />
                                                </StyledTdLink>
                                            </td>
                                            <td>
                                                <AvailabilityBadge
                                                    product={item}
                                                />
                                            </td>
                                            <td>
                                                {toFixed(
                                                    Number.isNaN(
                                                        parseFloat(
                                                            item?.VLR_TOT,
                                                        ),
                                                    )
                                                        ? 0
                                                        : item?.VLR_TOT,
                                                    2,
                                                ).toLocaleString('pt-br', {
                                                    style: 'currency',
                                                    currency: 'BRL',
                                                })}
                                            </td>
                                            <td
                                                style={{
                                                    verticalAlign: 'middle',
                                                    width: '10%',
                                                }}
                                            >
                                                <NumericInput
                                                    onBudgetEditing
                                                    disabled={false}
                                                    tableItem={item ?? {}}
                                                    createCartEditing
                                                    item={
                                                        {
                                                            status: InvoiceStatus.Budget,
                                                            isCart: true,
                                                            invoiceId:
                                                                invoice.id,
                                                            id: item?.INVOICE_ITEM_ID,
                                                            productErpCode:
                                                                item?.B1_COD,
                                                            productName:
                                                                item?.B1_DESC,
                                                            productCategory:
                                                                item?.CAT_DESC,
                                                            unitValue:
                                                                item?.VLR_TOT,
                                                            availability:
                                                                item?.boardingDate,
                                                        } as ISaveInvoiceItemDto
                                                    }
                                                    invoice={invoice}
                                                    setInvoice={setInvoice}
                                                />
                                            </td>
                                            <td>
                                                {(Number.isNaN(
                                                    item?.quantity *
                                                        item?.VLR_TOT,
                                                )
                                                    ? 0
                                                    : item?.quantity *
                                                      item?.VLR_TOT
                                                )?.toLocaleString('pt-br', {
                                                    style: 'currency',
                                                    currency: 'BRL',
                                                })}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Row>
                    <Row>
                        <Col md={12} className="text-center" ref={loadMoreRef}>
                            {loading && (
                                <Col md={12} className="text-center">
                                    <Spinner
                                        animation="border"
                                        className="mr-2 mt-1"
                                        size="sm"
                                    />
                                    Carregando...
                                </Col>
                            )}
                            {!loading && items.length === 0 && (
                                <Col md={12} className="text-center">
                                    <strong style={{ color: '#adadad' }}>
                                        Sem produtos para carregar
                                    </strong>
                                </Col>
                            )}
                        </Col>
                    </Row>
                </>
            )}

            {step === 3 && (
                <Cart
                    onBudgetEditing={enableEdit}
                    budget
                    disabled={disabled}
                    invoice={invoice}
                    step={step}
                    setStep={setStep}
                    /* eslint-disable-next-line react/jsx-no-bind */
                    setBudget={setInvoice}
                    addToInvoiceItems={addToInvoiceItems}
                />
            )}
        </>
    );
}
