/* eslint-disable import/prefer-default-export */
import { useState } from 'react';
import { useQuery } from 'react-query';
import BaseLayout from '../../components/BaseLayout';
import StyledGrid from './styles';
import AdAlertModal from '../../components/OnlineStore/AdAlertModal';
import BannerLocation from '../../constants/bannerLocation';
import HomeCard from '../../components/Dashboard/HomeCard';
import useAccessibleCards from '../../hooks/useAccessibleCards';
import BannerHttpService from '../../services/http/banner-http';

export function List() {
    const [showAdAlertModal, setShowAdAlertModal] = useState(false);

    const cards = useAccessibleCards();

    const getBanners = async () => {
        const response = await BannerHttpService.readMany({
            active: true,
            location: BannerLocation.dashboard,
        });

        return response.data.data;
    };

    const bannersQuery = useQuery({
        queryKey: 'dashboard-banners',
        queryFn: getBanners,
        onSuccess: (banners) => {
            if (banners.length) {
                setShowAdAlertModal(true);
            }
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: true,
        staleTime: 86400000,
    });

    return (
        <BaseLayout>
            <StyledGrid>
                {cards.map((card) => (
                    <HomeCard
                        key={card.title}
                        onClick={card.onClick}
                        icon={card.icon}
                        title={card.title}
                        description={card.description}
                    />
                ))}
            </StyledGrid>
            <AdAlertModal
                show={showAdAlertModal}
                onHide={() => setShowAdAlertModal(false)}
                banners={bannersQuery.data}
            />
        </BaseLayout>
    );
}
