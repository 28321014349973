/* eslint-disable no-return-await */
import RequestV2 from './request-v2.http';

export default class CustomerHttpService {
    public static uriV2 = 'invoice/customer';

    public static readMany(params: {}) {
        return RequestV2.get(this.uriV2, params);
    }

    public static readOne(id: number) {
        return RequestV2.get(`${this.uriV2}/${id}`);
    }

    public static create(data: {}) {
        return RequestV2.post(this.uriV2, data);
    }

    public static createAddress(data: {}, id: number) {
        return RequestV2.post(`${this.uriV2}/${id}/address`, data);
    }

    public static findAddress(id: number) {
        return RequestV2.get(`${this.uriV2}/${id}/address`);
    }
}
