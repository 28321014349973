import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import { StyledTh, StyledTdLink } from '../../../pages/OnlineStore/styles';
import { useOnlineStore } from '../../../contexts/onlineStoreContext';
import { formatToQueryParam } from '../../../utils/strings';
import AvailabilityBadge from '../../AvailabilityBadge';
import { useAuth } from '../../../contexts/authContext';
import userHasRoles from '../../../utils/userHasRoles';
import { Role } from '../../../constants/role';
import ProductVisibilityBadge from '../../ProductVisibilityBadge';
import LibraryHttpService from '../../../services/http/library-http';
import { replaceItemAtIndex } from '../../../utils/replace-array';
import CartProductNumericInput from '../InvoiceProductNumericInput/components/cart-product-numeric-input';

interface Props {
    data: any;
}

const GeneratorsTable: React.FC<Props> = ({ data }) => {
    const {
        setShowDownloadTechnicalDocumentModal,
        cart,
        term,
        productType,
        setLoadingCart,
    } = useOnlineStore();
    const [storageItens, setStorageItens] = useState(data);

    function toFixed(num: number, fixed: number) {
        const regex = new RegExp(`^-?\\d+(?:.\\d{0,${fixed || -1}})?`);
        const value = num.toString(2).match(regex)[0];

        return parseFloat(value);
    }

    async function downloadFT(link: string) {
        const linkArray = link.split('/');
        const folder = linkArray[1];
        const file = linkArray[2];
        await LibraryHttpService.downloadItem(folder, file);
    }

    const { user } = useAuth();

    const isAllowedToViewProductVisibility = userHasRoles(user, [
        Role.Administrator,
        Role.PointsAdministrator,
        Role.CommercialSupervisor,
        Role.Commercial,
        Role.Financial,
        Role.Logistics,
        Role.CustomerSuccess,
        Role.ShippingCompany,
        Role.Production,
        Role.Registration,
        Role.Purchase,
        Role.Marketing,
    ]);

    useEffect(() => {
        setStorageItens(data);
    }, [data]);

    useEffect(() => {
        if (cart) {
            setStorageItens(() => {
                return [
                    ...data.map((product: any) => {
                        const cartItem = (
                            (cart?.invoiceItems as any[]) ?? []
                        ).find(
                            (item) => item.productErpCode === product.B1_COD,
                        );
                        if (cartItem) {
                            // eslint-disable-next-line no-param-reassign
                            product.quantity = cartItem.quantity;
                        }
                        return { ...product };
                    }),
                ];
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart, data]);

    return (
        <Table hover bordered className="mx-0 text-center" size="sm">
            <thead>
                <tr>
                    {isAllowedToViewProductVisibility && <StyledTh />}
                    <StyledTh>CÓDIGO</StyledTh>
                    <StyledTh>DESCRIÇÃO</StyledTh>
                    <StyledTh>DISPONIBILIDADE</StyledTh>
                    <StyledTh>VALOR UNITÁRIO</StyledTh>
                    <StyledTh>QUANTIDADE</StyledTh>
                    <StyledTh>VALOR TOTAL</StyledTh>
                </tr>
            </thead>
            <tbody>
                {storageItens?.map((item: any, index: any) => (
                    <tr
                        style={{
                            backgroundColor:
                                item?.quantity > 0
                                    ? 'rgba(242, 153, 74, 0.08)'
                                    : '#ffffff',
                        }}
                        key={index}
                    >
                        {isAllowedToViewProductVisibility && (
                            <td>
                                <ProductVisibilityBadge
                                    isInternalProduct={
                                        item?.BLK_INT.toUpperCase() === 'S'
                                    }
                                />
                            </td>
                        )}

                        <td>
                            <Highlighter
                                autoEscape
                                highlightClassName="highlight-term"
                                searchWords={[formatToQueryParam(term)]}
                                textToHighlight={item?.B1_COD}
                            />
                        </td>
                        <td>
                            <StyledTdLink
                                onClick={() => {
                                    const files = item.FT as Array<{
                                        nameFile: string;
                                        link: string;
                                    }>;
                                    if (files.length === 1) {
                                        downloadFT(files[0].link);
                                        return;
                                    }

                                    setShowDownloadTechnicalDocumentModal(
                                        files,
                                    );
                                }}
                            >
                                <Highlighter
                                    autoEscape
                                    highlightClassName="highlight-term"
                                    searchWords={[formatToQueryParam(term)]}
                                    textToHighlight={item?.B1_DESC}
                                />
                            </StyledTdLink>
                        </td>
                        <td>
                            <AvailabilityBadge product={item} />
                        </td>
                        <td>
                            {toFixed(item?.VLR_TOT, 2).toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                            })}
                        </td>
                        <td
                            style={{
                                verticalAlign: 'middle',
                                width: '10%',
                            }}
                        >
                            <CartProductNumericInput
                                localItem={item}
                                quantityValue={item.quantity}
                                allItens={cart?.invoiceItems ?? []}
                                productType={productType}
                                setLoading={(loadingData) => {
                                    if (loadingData) {
                                        setLoadingCart(loadingData);
                                    }
                                }}
                                cartId={cart?.id}
                                onChange={(dataItem) => {
                                    setStorageItens((valueData: any[]) => {
                                        const itemToUpdateIndex =
                                            valueData.findIndex(
                                                (value) =>
                                                    value.B1_COD ===
                                                    dataItem.B1_COD,
                                            );
                                        if (itemToUpdateIndex !== -1) {
                                            return replaceItemAtIndex(
                                                valueData,
                                                itemToUpdateIndex,
                                                {
                                                    ...valueData[
                                                        itemToUpdateIndex
                                                    ],
                                                    quantity: dataItem.quantity,
                                                },
                                            );
                                        }
                                        return valueData;
                                    });
                                }}
                            />
                        </td>
                        <td>
                            {(
                                (item?.quantity ?? 0) * item?.VLR_TOT
                            )?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                            })}
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default GeneratorsTable;
