import React, { useEffect, useRef, useState } from 'react';
import { Form, Image, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { InvoiceStatus } from '../../../constants/invoiceStatus';
import {
    PaymentMethod as PaymentMethods,
    paymentMethodsItems as paymentMethodsItemsBruto,
} from '../../../constants/onlineStore';
import { useOnlineStore } from '../../../contexts/onlineStoreContext';
import InvoiceHttpService from '../../../services/http/invoice-http';
import PaymentHttpService from '../../../services/http/payment-http';
import { formatCurrency } from '../../../utils/strings';
import BraspagCreditCard from './BraspagCreditCard';
import CreditCard from './CreditCard';
import Financing from './Financing';
import Others from './Others';
import {
    StyledInterestLabel,
    StyledLink,
    StyledPaymentMethodCard,
    StyledSpan,
    StyledValueLabel,
} from './styles';
import { Role } from '../../../constants/role';
import { useAuth } from '../../../contexts/authContext';
import userHasRoles from '../../../utils/userHasRoles';
import { othersPaymentMethodsListRoute } from '../../../routes/config/othersPaymentMethodsList.route';

interface Props {
    invoice: any;
    disabled: any;
}

export interface InterestRatesProps {
    id: number;
    installment: number;
    paymentMethodId: number;
    percentage: string;
}

interface PaymentMethodProps {
    id: number;
    reference: string;
    name: string;
    maxInstallments: number;
    defaultInstallment: number;
    isDefaultPaymentMethod: boolean;
    interestRates: InterestRatesProps[];
}

const PaymentMethod: React.FC<Props> = ({ invoice, disabled }) => {
    const { setCart, cart } = useOnlineStore();
    const paymentMethodsItems =
        invoice.type === 'BF'
            ? paymentMethodsItemsBruto.filter(
                  (m: any) =>
                      m.method === PaymentMethods.Barcode ||
                      m.method === PaymentMethods.Pix,
              )
            : paymentMethodsItemsBruto;
    const [disabledSelectedMethod, setDisabledSelectedMethod] = useState(false);
    const [maxInstallments, setMaxInstallments] = useState(0);
    const [interestRates, setInterestRates] = useState<InterestRatesProps[]>(
        [],
    );
    const isFirstAssignment = useRef(true);

    async function loadPaymentMethods() {
        const response: any = await PaymentHttpService.listPaymentMethods();

        return response.data;
    }

    const paymentMethods = useQuery('paymentMethods', loadPaymentMethods, {
        staleTime: 3600000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
    });

    function getPaymentMethodByReference(
        reference: string,
        paymentMethodsData: PaymentMethodProps[] | undefined,
    ): PaymentMethodProps | undefined {
        return paymentMethodsData?.find(
            (paymentMethod: PaymentMethodProps) =>
                paymentMethod.reference === reference,
        );
    }

    const handlePaymentMethod = async (item: any) => {
        if (disabled || paymentMethods.isLoading || paymentMethods.error) {
            return;
        }

        if (item?.method !== invoice.paymentMethod) {
            setDisabledSelectedMethod(true);
            const updatedInvoice = { ...invoice };

            updatedInvoice.paymentMethod = item?.method;

            const paymentMethod: PaymentMethodProps =
                getPaymentMethodByReference(
                    item?.method,
                    paymentMethods.data?.data,
                );

            setMaxInstallments(paymentMethod.maxInstallments || 0);
            setInterestRates(paymentMethod.interestRates || []);

            updatedInvoice.cardMaxInstallmentNumber =
                paymentMethod.defaultInstallment || 1;

            updatedInvoice.financingBank =
                item?.method === PaymentMethods.Financing ? 'OTHERS' : null;

            try {
                const resp = await InvoiceHttpService.update(updatedInvoice);

                setCart(resp.data);
                setDisabledSelectedMethod(false);
            } catch (err) {
                const error = err as any;

                setDisabledSelectedMethod(false);
                toast.error(error.message || 'Erro ao salvar!');
            }
        }
    };

    const getValue = (type: string, item: any) => {
        let interestPercentage = 0;
        let installment = 1;

        if (item.method === invoice.paymentMethod) {
            interestPercentage = invoice.interestPercentage;
            installment = invoice.cardMaxInstallmentNumber || 1;
        } else {
            const paymentMethod: PaymentMethodProps =
                getPaymentMethodByReference(
                    item?.method,
                    paymentMethods?.data?.data,
                );
            if (paymentMethod) {
                installment = paymentMethod.defaultInstallment;

                const interestRate = paymentMethod.interestRates?.find(
                    (ir) => ir.installment === paymentMethod.defaultInstallment,
                );

                interestPercentage = +interestRate.percentage;
            }
        }

        const total =
            invoice.rawInvoice +
            +invoice.grossCommission -
            (invoice.discountValue || 0);

        const interestValue = (total * interestPercentage) / 100;

        if (type === 'totalAndInstallment') {
            return `${formatCurrency(total + interestValue)} (${installment}x)`;
        }

        if (type === 'interest') {
            return formatCurrency(interestValue);
        }

        return '';
    };

    useEffect(() => {
        if (
            paymentMethods.isFetched &&
            invoice.paymentMethod &&
            isFirstAssignment.current
        ) {
            const paymentMethod: PaymentMethodProps =
                getPaymentMethodByReference(
                    invoice.paymentMethod,
                    paymentMethods.data?.data,
                );

            setMaxInstallments(paymentMethod?.maxInstallments || 0);
            setInterestRates(paymentMethod?.interestRates || []);

            isFirstAssignment.current = false;
        }
    }, [invoice.paymentMethod, paymentMethods.data, paymentMethods.isFetched]);

    useEffect(() => {
        if (paymentMethods.error) {
            toast.error('Erro ao carregar métodos de pagamento');
        }
    }, [paymentMethods.error]);

    const { user } = useAuth();

    const isInternalUser = userHasRoles(user, [
        Role.Administrator,
        Role.CommercialSupervisor,
        Role.Commercial,
        Role.Logistics,
        Role.CustomerSuccess,
        Role.Registration,
        Role.Purchase,
        Role.Production,
        Role.PointsAdministrator,
        Role.Marketing,
        Role.Financial,
    ]);

    const isBudgetOrCartOrInvoice = [
        InvoiceStatus.Budget,
        InvoiceStatus.Invoice,
        InvoiceStatus.Cart,
    ].includes(invoice.status);

    const canAcessOthersPaymentMethodOptions =
        isBudgetOrCartOrInvoice && isInternalUser;

    return (
        <>
            <Form
                style={{
                    width: '100%',
                    backgroundColor: '#fafafa',
                }}
            >
                <Row className="justify-content-center">
                    {paymentMethodsItems
                        .filter((methods) => {
                            if (
                                invoice.status !== InvoiceStatus.Cart ||
                                methods.method !== PaymentMethods.Deposit
                            ) {
                                return true;
                            }

                            return new Date() < new Date('2022-03-01 00:00:00');
                        })
                        .map((item) => (
                            <StyledPaymentMethodCard
                                aria-disabled
                                onClick={() => {
                                    if (!disabledSelectedMethod) {
                                        handlePaymentMethod(item);
                                    }
                                }}
                                key={item.name}
                            >
                                <Form.Check className="p-4">
                                    <Row className="justify-content-center">
                                        <Form.Label
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {item.name}
                                        </Form.Label>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Image
                                            className="mt-3 mb-3"
                                            style={{ height: '3rem' }}
                                            src={item.image}
                                        />
                                    </Row>

                                    {!disabled && (
                                        <>
                                            <Row className="justify-content-center">
                                                <StyledInterestLabel>
                                                    Acréscimos:{' '}
                                                    {disabledSelectedMethod
                                                        ? '...'
                                                        : getValue(
                                                              'interest',
                                                              item,
                                                          )}
                                                </StyledInterestLabel>
                                            </Row>
                                            <Row className="justify-content-center">
                                                <StyledValueLabel>
                                                    Total:{' '}
                                                    {disabledSelectedMethod
                                                        ? '...'
                                                        : getValue(
                                                              'totalAndInstallment',
                                                              item,
                                                          )}
                                                </StyledValueLabel>
                                            </Row>
                                        </>
                                    )}

                                    <Row className="justify-content-center">
                                        <Form.Control
                                            onChange={() => false}
                                            onClick={() =>
                                                !disabledSelectedMethod
                                                    ? handlePaymentMethod(item)
                                                    : null
                                            }
                                            disabled={
                                                disabled ||
                                                disabledSelectedMethod ||
                                                paymentMethods.isLoading ||
                                                paymentMethods.error
                                            }
                                            style={{
                                                width: '15px',
                                                cursor: 'pointer',
                                            }}
                                            name="aditionalvalue"
                                            checked={
                                                !!(
                                                    cart &&
                                                    cart.paymentMethod &&
                                                    (item?.method ===
                                                        cart.paymentMethod ||
                                                        (cart.paymentMethod ===
                                                            PaymentMethods.CreditCardAndCreditCard &&
                                                            item?.method ===
                                                                PaymentMethods.CreditCard))
                                                )
                                            }
                                            type="radio"
                                        />
                                    </Row>
                                    {item?.method === PaymentMethods.Others && (
                                        <div>
                                            <Row className="justify-content-center">
                                                <StyledSpan>
                                                    {invoice.othersPaymentMethod
                                                        ?.description || ''}
                                                </StyledSpan>
                                            </Row>
                                            {canAcessOthersPaymentMethodOptions && (
                                                <Row className="justify-content-center">
                                                    <StyledLink
                                                        to={
                                                            othersPaymentMethodsListRoute.path
                                                        }
                                                        target="_blank"
                                                    >
                                                        Visualizar Opções
                                                    </StyledLink>
                                                </Row>
                                            )}
                                        </div>
                                    )}
                                </Form.Check>
                            </StyledPaymentMethodCard>
                        ))}
                </Row>
            </Form>

            {invoice.paymentMethod === PaymentMethods.CreditCard &&
                invoice.card1value && (
                    <BraspagCreditCard disabled={disabled} invoice={invoice} />
                )}

            {(invoice.paymentMethod === PaymentMethods.CreditCard ||
                invoice.paymentMethod === PaymentMethods.CreditCardAndBarcode ||
                invoice.paymentMethod ===
                    PaymentMethods.CreditCardAndCreditCard) &&
                !invoice.card1value && (
                    <CreditCard
                        disabled={
                            disabled ||
                            !maxInstallments ||
                            !interestRates.length
                        }
                        invoice={invoice}
                        maxInstallments={maxInstallments}
                        interestRates={interestRates}
                    />
                )}

            {invoice.paymentMethod === PaymentMethods.Financing && (
                <Financing
                    disabled={disabled}
                    reducedFee={invoice.financingBank === 'REDUCED_FEE'}
                    financialBv={invoice.financingBank === 'BV'}
                />
            )}

            {invoice.paymentMethod === PaymentMethods.Others && <Others />}
        </>
    );
};

export default PaymentMethod;
