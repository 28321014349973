/* eslint-disable no-return-await */
import RequestV2 from './request-v2.http';

export default class SalesCampaignHttpService {
    private static uri = 'black-friday';

    public static async indexSeason(params: {}) {
        return await RequestV2.get(`${this.uri}/season`, params);
    }
}
