import { toast } from 'react-toastify';
// eslint-disable-next-line import/no-extraneous-dependencies
import { debounce } from 'lodash';
import { useCallback } from 'react';
import ProductNumericInput, {
    ILocalItemWithQuantityProductNumericInput,
    IPropsProductNumericInput,
} from '../product-numeric-input';
import InvoiceHttpService from '../../../../services/http/invoice-http';
import { useAuth } from '../../../../contexts/authContext';
import userHasRoles from '../../../../utils/userHasRoles';
import { Role } from '../../../../constants/role';

interface IInvoiceItens {
    id: number;
    productErpCode: string;
    productName: string;
    productCategory: string;
    unitValue: number;
    availability: string;
    quantity: number;
    totalValue: number;
}

interface props extends IPropsProductNumericInput {
    allItens: IInvoiceItens[];
    invoiceId: number;
    productType: 'BF' | 'MP';
    type: 'CART' | 'BUDGET' | 'INVOICE';
    updateCartOrInvoiceData: (cart: any) => void;
    setLoading?: (loading: boolean) => void;
}
export default function CartOrInvoiceProductNumericInput({
    localItem,
    onChange,
    quantityValue,
    allItens,
    invoiceId,
    productType,
    updateCartOrInvoiceData: updateCartData,
    setLoading,
    type,
    disabled,
}: props) {
    const { user } = useAuth();

    const isAdministrator = userHasRoles(user, [Role.Administrator]);
    const isCommercial = userHasRoles(user, [Role.Commercial]);
    const isCommercialSupervisor = userHasRoles(user, [
        Role.CommercialSupervisor,
    ]);

    const isCable = () => localItem.B1_COD.substring(0, 4) === '1020';

    async function updateCartOrInvoice(
        data: ILocalItemWithQuantityProductNumericInput,
    ) {
        await InvoiceHttpService.updateItemQuantity(
            invoiceId ?? 0,
            data.B1_COD,
            data.quantity,
            data.boardingDate,
        );
        if (type === 'CART') {
            const cart = await InvoiceHttpService.getUserCart();
            updateCartData(cart.data);
        }

        if (type === 'INVOICE') {
            const invoice = await InvoiceHttpService.show(`${invoiceId}`);
            updateCartData(invoice.data);
        }

        if (setLoading) {
            setLoading(false);
        }
    }

    function updateBudget(data: ILocalItemWithQuantityProductNumericInput) {
        const itemData = allItens.find(
            (item) => item.productErpCode === data.B1_COD,
        );
        if (itemData) {
            itemData.quantity = data.quantity;
            itemData.unitValue = data.VLR_TOT;
            itemData.totalValue = data.quantity * data.VLR_TOT;
        } else {
            allItens.push({
                id: 0,
                availability: data.boardingDate,
                productCategory: data.CAT_DESC,
                productErpCode: data.B1_COD,
                productName: data.B1_DESC,
                quantity: data.quantity,
                unitValue: data.VLR_TOT,
                totalValue: data.quantity * data.VLR_TOT,
            });
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSave = useCallback(
        debounce((nextValue) => updateCartOrInvoice(nextValue), 700),
        [],
    );

    function rulesToUpdateCart(
        data: ILocalItemWithQuantityProductNumericInput,
    ) {
        if (type === 'INVOICE') {
            if (productType === 'MP') {
                const haveInverter = ['1010'].includes(
                    data.B1_COD.substring(0, 4),
                );
                const havePanel = ['1001'].includes(
                    data.B1_COD.substring(0, 4),
                );

                if ((haveInverter || havePanel) && data.quantity <= 0) {
                    return {
                        error: true,
                        message:
                            'Para que sua compra seja autorizada, é necessário adicionar no mínimo 1 inversor e 1 Painel solar em seu pedido.',
                    };
                }
            }

            if (
                data.quantity <= 0 &&
                !(isAdministrator || isCommercial || isCommercialSupervisor)
            ) {
                return {
                    error: true,
                    message:
                        'Você não tem permissão para remover este item do pedido.',
                };
            }

            if (allItens.length === 1 && data.quantity <= 0) {
                return {
                    error: true,
                    message: 'Não é possível deixar um pedido sem itens.',
                };
            }
        }

        if (
            productType === 'BF' &&
            ((allItens.length > 0 && data.quantity > 0) || data.quantity > 1)
        ) {
            return {
                error: true,
                message:
                    'Nâo é possivel adicionar mais de um produto promocional!',
            };
        }
        return { error: false, message: '' };
    }

    function defineQuantity(data: ILocalItemWithQuantityProductNumericInput) {
        if (!isCable()) {
            return data.quantity;
        }

        if (data.quantity % 10 !== 0) {
            // eslint-disable-next-line no-param-reassign
            data.quantity = Math.ceil(data.quantity / 10) * 10;
            return data.quantity;
        }
        return 0;
    }

    function handleChange(data: ILocalItemWithQuantityProductNumericInput) {
        const rules = rulesToUpdateCart(data);
        defineQuantity(data);
        if (rules.error) {
            toast.error(rules.message);
            return;
        }
        if (setLoading) {
            setLoading(true);
        }
        if (type !== 'BUDGET') {
            debouncedSave(data);
        } else {
            updateBudget(data);
        }
        onChange(data);
        // eslint-disable-next-line consistent-return
        return data.quantity;
    }
    return (
        <ProductNumericInput
            localItem={localItem}
            disabled={disabled}
            onChange={(data) => {
                return handleChange(data);
            }}
            quantityValue={quantityValue}
        />
    );
}
