/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable radix */
/* eslint-disable consistent-return */
/* eslint-disable react/require-default-props */
/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { Icon } from './styles';
import ProductsList from './ProductsList';
import InvoiceHttpService from '../../services/http/invoice-http';
import { useOnlineStore } from '../../contexts/onlineStoreContext';
import { PaymentMethod } from '../../constants/paymentMethod';
import ConfirmationDialog from '../ConfirmationDialog';
import { NotificationIcon } from '../../constants/notificationIcon';
import handleResponseError from '../../utils/handleResponseError';

interface Props {
    disabled: boolean;
    invoice?: any;
}

export function BudgetEditLayout({ disabled, invoice }: Props) {
    const { setCart, setLoadingCart } = useOnlineStore();
    const [step, setStep] = useState(3);
    const [enableEdit, setEnableEdit] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [invoiceSimulate, setInvoiceSimulate] = useState(invoice);

    const [showConfirmation, setShowConfirmation] = useState(false);

    async function loadBudget() {
        setCart();

        const response: any = await InvoiceHttpService.show(invoice.id);

        setCart({
            ...invoice,
            ...response.data,
        });

        return response.data;
    }

    const budgetItemsSimulateMutation = useMutation({
        mutationKey: ['budget-items-simulate', invoice.id],
        mutationFn: async (budget: any) =>
            InvoiceHttpService.budgetItemSimulate(
                budget.id,
                budget.invoiceItems,
            ),
        onSuccess: ({ data }: any) => {
            setInvoiceSimulate({
                ...invoiceSimulate,
                interestValue: data.interestValue,
                interestPercentage: data.interestPercentage,
                netCommission: data.netCommission,
                grossCommission: data.grossCommission,
                freightValue: data.freightValue,
                freightPercentage: data.freightPercentage,
                discountValue: data.discountValue,
                rawInvoice: data.rawInvoice,
                totalInvoice: data.totalInvoice,
            });
        },
        onError: (error: any) => {
            handleResponseError(
                error,
                'Ocorreu um erro ao simular edição dos produtos do orçamento',
            );
        },
    });

    async function refreshItemsPrice() {
        try {
            const { data } = await InvoiceHttpService.getProducts(invoice.id);

            if (parseInt(data.data.length) !== invoice.invoiceItems.length) {
                toast.error('Não foi possível atualizar os valores.');
                return handleStopEditing();
            }

            const newInvoiceItems = invoice.invoiceItems.map(
                (invoiceItem: any) => {
                    const product = data.data.find(
                        (item: any) =>
                            item.B1_COD === invoiceItem.productErpCode,
                    );

                    const [unitValue, totalValue] = [
                        parseFloat(product.VLR_TOT),
                        parseFloat(product.VLR_TOT) * invoiceItem.quantity,
                    ];

                    return {
                        ...invoiceItem,
                        isCart: false,
                        unitValue,
                        totalValue,
                    };
                },
            );

            const newInvoice = await InvoiceHttpService.budgetItemSimulate(
                invoice.id,
                newInvoiceItems,
            );

            setInvoiceSimulate(newInvoice.data);
        } catch (error) {
            console.log(error);
        }
    }

    const handleStopEditing = () => {
        loadBudget();
        setStep(3);
        setEnableEdit(false);
    };

    const handleBudgetSave = async () => {
        try {
            setLoadingButton(true);

            if (invoice?.type === 'MP') {
                const haveInverter = invoiceSimulate?.invoiceItems.some(
                    (item: any) =>
                        ['1010'].includes(
                            item.productErpCode.substring(0, 4),
                        ) && parseInt(item.quantity) > 0,
                );
                const havePanel = invoiceSimulate?.invoiceItems.some(
                    (item: any) =>
                        ['1001'].includes(
                            item.productErpCode.substring(0, 4),
                        ) && parseInt(item.quantity) > 0,
                );

                if (!haveInverter || !havePanel) {
                    toast.error(
                        'Para que sua compra seja autorizada, é necessário adicionar no mínimo 1 inversor e 1 Painel solar em seu pedido.',
                    );
                    return true;
                }
            }

            await InvoiceHttpService.budgetItems(
                invoiceSimulate.id,
                invoiceSimulate.invoiceItems,
            );

            toast.success(`Orçamento atualizado com sucesso!`);

            handleStopEditing();
        } catch (error) {
            toast.error(`Houve algum erro ao editar este orçamento!`);
        } finally {
            setLoadingButton(false);
        }
    };

    const checkDisabled = () => {
        const haveAllItemsZeroed = invoiceSimulate?.invoiceItems.some(
            (item: any) => parseInt(item.quantity) > 0,
        );

        if (
            !invoiceSimulate?.integrator.id ||
            !invoiceSimulate?.customerDocument ||
            !invoiceSimulate?.deliveryAddressCep ||
            !invoiceSimulate?.deliveryAddressDescription ||
            !invoiceSimulate?.deliveryAddressErpState ||
            !invoiceSimulate?.deliveryAddressErpCity ||
            !invoiceSimulate?.deliveryAddressNeighborhood ||
            !invoiceSimulate?.deliveryAddressCep ||
            !invoiceSimulate?.paymentMethod ||
            !haveAllItemsZeroed ||
            (invoiceSimulate?.paymentMethod === PaymentMethod.Financing &&
                !invoiceSimulate?.financingBank) ||
            (invoiceSimulate?.paymentMethod === PaymentMethod.Financing &&
                invoiceSimulate?.financingBank === 'BV' &&
                !invoiceSimulate?.financing)
        )
            return true;

        return false;
    };

    const addToInvoiceItems = ({
        invoiceItems,
        totalInvoice,
    }: {
        invoiceItems: any[];
        totalInvoice: number;
    }) => {
        if (!invoiceSimulate || !invoiceSimulate.invoiceItems) {
            return;
        }

        invoiceSimulate.invoiceItems = invoiceItems;
        invoiceSimulate.totalInvoice = totalInvoice;
    };

    const enableEditing = async () => {
        await refreshItemsPrice();
        setEnableEdit(true);
    };

    useEffect(() => {
        if (enableEdit) {
            setStep(2);
        }
    }, [enableEdit]);

    useEffect(() => {
        setLoadingCart(budgetItemsSimulateMutation.isLoading);
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [budgetItemsSimulateMutation.isLoading]);

    return (
        <>
            <Col className={'mt-4'}>
                <Row
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Col>
                        <Row
                            onClick={() => setShowConfirmation(true)}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                width: 278,
                            }}
                            title={'Clique para editar este orçamento!'}
                        >
                            <Icon>
                                <EditIcon fill="#fff" />
                            </Icon>
                            <Col>
                                <h5 className="mb-0">Editar</h5>
                                <p className="mb-0">
                                    Clique para editar o orçamento
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    {enableEdit && (
                        <Col>
                            <Row
                                style={{
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Button
                                    onClick={handleStopEditing}
                                    style={{ width: '110px' }}
                                    variant={'outline-primary'}
                                    className={'mr-2'}
                                >
                                    Voltar
                                </Button>
                                <Button
                                    disabled={checkDisabled()}
                                    style={{ width: '110px' }}
                                    onClick={handleBudgetSave}
                                >
                                    {loadingButton ? (
                                        <Spinner animation={'border'} />
                                    ) : (
                                        'Salvar'
                                    )}
                                </Button>
                            </Row>
                        </Col>
                    )}
                </Row>
            </Col>
            <hr />

            <ProductsList
                invoice={invoiceSimulate}
                /* eslint-disable-next-line react/jsx-no-bind */
                setInvoice={budgetItemsSimulateMutation.mutateAsync}
                step={step}
                setStep={setStep}
                disabled={disabled}
                enableEdit={enableEdit}
                addToInvoiceItems={addToInvoiceItems}
            />

            {showConfirmation && (
                <ConfirmationDialog
                    show={showConfirmation}
                    onHide={() => setShowConfirmation(false)}
                    onConfirm={enableEditing}
                    title="Editar orçamento"
                    text={`Deseja editar o orçamento?\n(sujeito a atualização dos valores)`}
                    icon={NotificationIcon.Info}
                />
            )}
        </>
    );
}
