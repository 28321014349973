import React, { CSSProperties } from 'react';
import { Nav } from 'react-bootstrap';
import Group from '../../../shared/interfaces/group.interface';

interface GroupMenuItemProps {
    group: Group;
    active: boolean;
    disabled?: boolean;
}

export default function GroupMenuItem({
    group,
    active,
    disabled,
}: GroupMenuItemProps) {
    const activeStyle: CSSProperties = {
        color: '#F2994A',
        fontWeight: 600,
    };

    const inactiveStyle: CSSProperties = {
        color: '#707070',
    };

    const style = active ? activeStyle : inactiveStyle;

    return (
        <Nav.Item>
            <Nav.Link
                style={style}
                eventKey={group.BM_GRUPO}
                disabled={disabled}
            >
                {group.BM_DESC}
            </Nav.Link>
        </Nav.Item>
    );
}
