/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prefer-const */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { useClipboard } from '@mantine/hooks';
import { Col, Row, Spinner, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import QRCode from 'qrcode.react';
import { HiOutlineClipboardCopy } from 'react-icons/hi';
import {
    StyledPageSubTitle,
    StyledPageTitle,
} from '../../../../styles/pageTitle';
import { ReactComponent as BarcodeSvg } from '../../../../assets/icons/barcode.svg';
import PaymentHttpService from '../../../../services/http/payment-http';
import { formatCurrency } from '../../../../utils/strings';
import InvoiceSummary from '../../InvoiceSummary';
import { StyledButton } from '../Checkout/styles';
import isFriendlyHttpError from '../../../../utils/isFriendlyHttpError';

const Pix = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [url, setUrl] = useState('');
    const [QrCode, setQrCode] = useState('');
    const clipboard = useClipboard({ timeout: 500 });

    async function getPix(): Promise<void> {
        setLoading(true);

        try {
            const resp = await PaymentHttpService.generatePix(
                props.invoice?.id,
            );

            console.log(resp);

            setUrl(resp.data.mundipaggPixUrl);
            setQrCode(resp.data.mundipaggPixQrCode);
        } catch (err: any) {
            let error = err as any;

            if (isFriendlyHttpError(error)) {
                toast.error(error.message);

                setLoading(false);
                setUrl('');
                setQrCode('');

                return;
            }

            toast.error(
                'Erro ao gerar pix para pagamento, entre em contato com nosso time comercial',
            );

            setLoading(false);
            setUrl('');
            setQrCode('');

            return;
        }

        setLoading(false);
    }

    useEffect(() => {
        if (props.invoice?.id) {
            console.log('foi');
            getPix();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.invoice]);

    return (
        <Modal {...props} size="xl" centered>
            <Modal.Header closeButton>
                <Modal.Title
                    className={'ml-3'}
                    id="contained-modal-title-vcenter"
                >
                    <Row>
                        <Col sm={1}>
                            <BarcodeSvg />
                        </Col>
                        <Col>
                            <div className={'ml-2'}>
                                Pagamento via Pix{' '}
                                {formatCurrency(props.invoice.totalInvoice)}
                            </div>
                        </Col>
                    </Row>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={'ml-3'}>
                <Row>
                    <Col sm={6}>
                        <Row>
                            <div className={'mt-4 ml-3 mb-1'}>
                                <StyledPageTitle>Código QR:</StyledPageTitle>

                                <Row className={'mt-2 ml-0'}>
                                    {!loading && url && (
                                        <QRCode
                                            includeMargin
                                            imageSettings={{
                                                src: url ?? '',
                                                excavate: true,
                                                height: 330,
                                                width: 330,
                                            }}
                                            value={url}
                                            renderAs="svg"
                                            size={330}
                                        />
                                    )}
                                    {!loading && !url && (
                                        <StyledPageSubTitle>
                                            QR Code não encontrado, entre em
                                            contato conosco.
                                        </StyledPageSubTitle>
                                    )}
                                    {loading && (
                                        <Spinner animation={'border'} />
                                    )}
                                </Row>
                                {QrCode && (
                                    <Row className={'mt-2 ml-0'}>
                                        <StyledButton
                                            as="a"
                                            onClick={() => {
                                                clipboard.copy(QrCode);
                                                toast.success(
                                                    'Link do checkout copiado.',
                                                );
                                            }}
                                            className={'align-items-center'}
                                        >
                                            clique aqui para copiar o PIX copia
                                            e cola
                                            <HiOutlineClipboardCopy
                                                onClick={() => {
                                                    clipboard.copy(QrCode);
                                                    toast.success(
                                                        'Link do checkout copiado.',
                                                    );
                                                }}
                                            />
                                        </StyledButton>
                                    </Row>
                                )}
                            </div>
                        </Row>
                        <Row>
                            <div className={'mt-4 ml-3'}>
                                <StyledPageTitle>Pagamento</StyledPageTitle>
                                <StyledPageSubTitle>
                                    Pode ser feito pela internet/telefone,
                                    utilizando o código QR acima, utilizando seu
                                    aplicativo de banco.
                                </StyledPageSubTitle>
                            </div>
                        </Row>
                        <Row>
                            <div className={'mt-4 ml-3'}>
                                <StyledPageTitle>
                                    Prazo de entrega
                                </StyledPageTitle>
                                <StyledPageSubTitle>
                                    É contado a partir da confirmação de
                                    pagamento pelo banco, o que pode levar até 2
                                    dias úteis.
                                </StyledPageSubTitle>
                            </div>
                        </Row>
                    </Col>
                    <Col sm={1}></Col>
                    <Col>
                        <InvoiceSummary invoice={props.invoice} />
                    </Col>
                    <Col sm={1}></Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className={'d-block'} />
        </Modal>
    );
};

export default Pix;
