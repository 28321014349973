/* eslint-disable consistent-return */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { Badge, Col, Image, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { useOnlineStore } from '../../../contexts/onlineStoreContext';
import { StyledBottomText, StyledValueBottomText } from '../Base/styles';
import cartImg from '../../../assets/icons/cart.png';
import ConfirmInvoiceModal from '../Invoice/ConfirmModal';
import { listGeneratorsRoute, listReadyKits } from '../../../routes/config';
import { formatCurrency } from '../../../utils/strings';

const TopCart: React.FC = () => {
    const history = useHistory();

    const { cart, setStep, productType, items } = useOnlineStore();
    const [sum, setSum] = useState(0);
    const [totalValue, setTotalValue] = useState(0);
    const [showConfirmInvoiceModal, setShowConfirmInvoiceModal] =
        useState(false);

    const viewSummary = () => {
        if (!cart?.invoiceItems.length) {
            return toast.info('Seu carrinho está vazio');
        }

        if (productType === 'PA' || productType === 'BF') {
            history.push(listReadyKits.path, { viewSummary: true });
        }

        if (productType === 'MP') {
            history.push(listGeneratorsRoute.path, { viewSummary: true });
        }

        setStep(3);
    };

    useEffect(() => {
        const calc = async () => {
            setSum(0);
            let subtotal = 0;
            const itemsData =
                items && items.length ? items : cart?.invoiceItems ?? [];
            itemsData.forEach((item: any) => {
                subtotal +=
                    parseFloat(`${item.unitValue}`) *
                    parseInt(`${item.quantity}`, 10);
                setSum((sum) => (sum += item.quantity));
            });
            setTotalValue(subtotal);
        };

        calc();
    }, [cart, cart.invoiceItems, items]);

    return (
        <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
                viewSummary();
            }}
            className="w-100 mt-2 mr-3"
        >
            <Row>
                <Col xl={4}>
                    <div className={'mt-1 mr-n4 float-right'}>
                        <Image className={'mr-n2'} src={cartImg}></Image>

                        <sup className={'superscript'}>
                            <Badge
                                className={'ml-1 notify-badge'}
                                variant={'primary'}
                            >
                                {sum}
                            </Badge>
                        </sup>
                    </div>
                </Col>
                <Col xl={8}>
                    <StyledValueBottomText>
                        {formatCurrency(totalValue)}
                    </StyledValueBottomText>

                    <StyledBottomText className={'mt-n2'}>
                        ver resumo
                    </StyledBottomText>
                </Col>
            </Row>

            <ConfirmInvoiceModal
                show={showConfirmInvoiceModal}
                invoiceId={cart.id}
                type={cart.type}
                onHide={() => {
                    setShowConfirmInvoiceModal(false);
                }}
            />
        </div>
    );
};

export default TopCart;
