import { FormHandles } from '@unform/core';
import { useRef, useState } from 'react';
import { ModalProps, Modal, Form, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import CustomerAddress from '../OnlineStore/Customers/CustomerAddress';
import { StyledOutlineButton } from '../../styles/button';
import { StyledConfirmButton } from '../ConfirmationDialog/styles';
import { customerAddressRules } from '../../validations/customer';
import getValidationsErrors from '../../utils/getValidationsErrors';
import handleResponseError from '../../utils/handleResponseError';
import CustomerHttpService from '../../services/http/customer-http';

interface props extends ModalProps {
    customerName: string;
    customerId: number;
}

export default function CustomerAddressModal({
    customerName,
    customerId,
    onHide,
    ...props
}: props) {
    const formRef: any = useRef<FormHandles>(null);
    const newCustomerAddress = () => ({
        addressCep: '',
        addressNumber: '',
        addressDescription: '',
        addressNeighborhood: '',
        addressComplement: '',
        erpState: '',
        erpCity: '',
        erpCityName: '',
    });
    const [customerAddress, setCustomerAddress] = useState(
        newCustomerAddress(),
    );
    const [errors, setErrors] = useState(newCustomerAddress());
    const [loading, setLoading] = useState(false);

    const handleChanges = (event: any) => {
        const changes: any = {};
        const newErrors: any = {};
        let events = event;

        if (!Array.isArray(event)) {
            events = [event];
        }

        events.forEach((item: any) => {
            if (item.target.name === 'erpState') {
                changes.erpState =
                    customerAddress.erpState || item.target.value.code;
                changes.erpCity =
                    customerAddress.erpCityName && customerAddress.erpCity
                        ? {
                              value: customerAddress.erpCity,
                              label: customerAddress.erpCityName,
                          }
                        : '';
            }

            changes[item.target.name] = item.target.value;
            newErrors[item.target.name] = undefined;
        });
        console.log(changes);
        setCustomerAddress({ ...customerAddress, ...changes });
        setErrors({ ...errors, ...newErrors });
    };

    const prepareData = async (data: any) => {
        return {
            addressCep: data.addressCep.toString().replace(/\D/g, ''),
            erpCity:
                typeof data.erpCity === 'object'
                    ? data.erpCity.value
                    : data.erpCity,
            erpCityName:
                typeof data.erpCity === 'object'
                    ? data.erpCity.label
                    : data.erpCityName,
            erpState:
                typeof data.erpState === 'object'
                    ? data.erpState.value
                    : data.erpState,
            addressNumber: data.addressNumber
                .toString()
                .normalize('NFD')
                .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''),
            addressDescription: data.addressDescription
                .normalize('NFD')
                .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''),
            addressNeighborhood: data.addressNeighborhood
                .normalize('NFD')
                .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''),
            addressComplement: data.addressComplement
                .normalize('NFD')
                .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''),
        };
    };

    const save = async () => {
        try {
            const schema = Yup.object().shape(customerAddressRules);

            await schema.validate(customerAddress, { abortEarly: false });
        } catch (error) {
            setErrors(getValidationsErrors(error) as any);
            return;
        }

        const data = await prepareData(customerAddress);

        try {
            await CustomerHttpService.createAddress(data, customerId);
            toast.success('Endereço do Cliente cadastrado com sucesso');
            onHide();
        } catch (error) {
            handleResponseError(error, 'Erro ao salvar cadastro do cliente');
        }
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        setLoading(true);

        await save();

        setLoading(false);
    };

    return (
        <Modal
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Adicionar um novo endereço para o cliente {customerName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <CustomerAddress
                        errors={errors}
                        customer={customerAddress}
                        handleChanges={handleChanges}
                    />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <StyledOutlineButton
                    variant="outline-primary"
                    onClick={() => {
                        onHide();
                    }}
                    disabled={false}
                >
                    Cancelar
                </StyledOutlineButton>
                <StyledConfirmButton onClick={handleSubmit} disabled={loading}>
                    {loading ? <Spinner animation="border" /> : 'Salvar'}
                </StyledConfirmButton>
            </Modal.Footer>
        </Modal>
    );
}
