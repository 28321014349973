import RequestV2 from './request-v2.http';

export default class AuthHttpService {
    private static uri = 'auth';

    public static getUser() {
        return RequestV2.get(`${this.uri}/user`);
    }

    public static async logout() {
        return RequestV2.post(`${this.uri}/logout`);
    }

    public static async clearCookie(data: {
        key: 'auth_token' | 'refresh_token' | 'clear_storage';
    }) {
        return RequestV2.post(`${this.uri}/clear-cookie`, data);
    }
}
