import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ProductAvailability } from '../../constants/productAvailability';
import { Role } from '../../constants/role';
import { useAuth } from '../../contexts/authContext';
import formatDate from '../../utils/formatDate';
import userHasRoles from '../../utils/userHasRoles';
import BaseAvailabilityBadge from '../BaseAvailabilityBadge';

interface AvailabilityBadgeProps {
    product: any;
}

export default function AvailabilityBadge({ product }: AvailabilityBadgeProps) {
    const { user } = useAuth();

    const isAllowedToViewAvailableQuantity = userHasRoles(user, [
        Role.Administrator,
        Role.CommercialSupervisor,
        Role.Commercial,
        Role.Purchase,
    ]);

    const hasBoardingDate = Boolean(product.boardingDate);
    const isImmediate = product.boardingDate === ProductAvailability.Immediate;

    if (!isAllowedToViewAvailableQuantity) {
        return (
            <BaseAvailabilityBadge
                isImmediate={isImmediate}
                boardingDate={product.boardingDate}
            />
        );
    }

    const messages = [
        'Saldo atual disponível:',
        `${product.SALDO_DISP} unidade(s)`,
    ];

    if (!isImmediate && hasBoardingDate && product.boardingDate !== 'SEARCH') {
        messages.push(
            `Saldo disponível em ${formatDate(product.boardingDate)}:`,
            `${product.availableQty} unidade(s)`,
        );
    }

    return (
        <OverlayTrigger
            placement="right"
            overlay={
                <Tooltip id="tooltip-available-qty">
                    {messages.map((message) => (
                        <span key={message}>
                            {message}
                            <br />
                        </span>
                    ))}
                </Tooltip>
            }
        >
            <span>
                <BaseAvailabilityBadge
                    isImmediate={isImmediate}
                    boardingDate={product.boardingDate}
                />
            </span>
        </OverlayTrigger>
    );
}
