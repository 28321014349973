import Request from './request';
import RequestV2 from './request-v2.http';

export default class ReturnHttpService {
    private static uri = 'returns';

    public static index(params: {}) {
        return Request.get(this.uri, params);
    }

    public static saveReturn(id: string, params: { observation: string }) {
        return RequestV2.post(`${this.uri}/invoice/${id}`, params);
    }
}
