import { Row, Col, Table, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useRef } from 'react';
import { useInfiniteQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { BudgetStatus, InvoiceStatus } from '../../../constants/invoiceStatus';
import { budgetsDetailRoute, invoiceDetailRoute } from '../../../routes/config';
import { rowsPerPage } from '../../../constants/pagination';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import useInvoices from '../../../hooks/useInvoices';
import InvoiceHttpService from '../../../services/http/invoice-http';
import isFriendlyHttpError from '../../../utils/isFriendlyHttpError';
import { NotificationIcon } from '../../../constants/notificationIcon';
import ConfirmationDialog from '../../ConfirmationDialog';
import BudgetTableRow from '../BudgetTableRow';
import StyledTh from './styles';

export default function BudgetTable() {
    const history = useHistory();

    const {
        filterForm,
        filterTerm,
        selectedInvoice,
        setShowRemakeConfirmation,
        setShowRemoveConfirmation,
        setShowMakeConfirmation,
        showRemoveConfirmation,
        showMakeConfirmation,
        showRemakeConfirmation,
    } = useInvoices();

    const formData = filterForm.watch();

    const budgetsQuery = useInfiniteQuery({
        queryKey: [
            'budgets',
            filterTerm,
            formData.filterModules,
            formData.filterRegion,
            formData.filterSeller,
            formData.filterPaymentMethod,
            formData.filterInverters,
        ],
        queryFn: async ({ pageParam = 1 }) => {
            const filterPaymentMethodValues = formData.filterPaymentMethod
                ?.map((item) => item.value)
                .join('/');

            const params = {
                term: filterTerm,
                skip: pageParam,
                take: rowsPerPage,
                modules: formData.filterModules || '',
                status: Object.values(BudgetStatus).join(','),
                regions: formData.filterRegion || [],
                sellers: formData.filterSeller || [],
                paymentMethods: filterPaymentMethodValues || '',
            };

            const response = await InvoiceHttpService.indexBudget(params);

            return {
                data: response.data.data,
                currentPage: pageParam,
                total: response.data.data.length,
            };
        },
        getNextPageParam: ({ currentPage, total }) =>
            total !== 0 ? currentPage + 1 : undefined,
        staleTime: 300000,
        refetchOnWindowFocus: false,
        refetchOnMount: 'always',
    });

    const makeMutation = useMutation({
        mutationFn: async () => {
            await InvoiceHttpService.setStatus(selectedInvoice.id, {
                status: InvoiceStatus.Invoice,
            });
        },
        onError: (error: any) => {
            if (isFriendlyHttpError(error)) {
                toast.error(error.message);
                return;
            }

            toast.error('Ocorreu um erro ao fazer o pedido');
        },
        onSuccess: () => {
            toast.success('Pedido realizado com sucesso');

            history.push(invoiceDetailRoute.build({ id: selectedInvoice.id }));
        },
        onSettled: () => budgetsQuery.refetch(),
    });

    const remakeMutation = useMutation({
        mutationKey: ['remake-invoice'],
        mutationFn: async (keepAttachments: boolean) =>
            InvoiceHttpService.remake(selectedInvoice.id, keepAttachments),
        onError: (error: any) => {
            if (isFriendlyHttpError(error)) {
                toast.error(error.message);
                return;
            }

            toast.error('Ocorreu um erro ao refazer o orçamento');
        },
        onSuccess: (data: any) => {
            toast.success('Orçamento refeito com sucesso');

            history.push(budgetsDetailRoute.build(data.data));
        },
        onSettled: () => budgetsQuery.refetch(),
    });

    const removeMutation = useMutation({
        mutationFn: async () => InvoiceHttpService.destroy(selectedInvoice.id),
        onError: (error: any) => {
            if (isFriendlyHttpError(error)) {
                toast.error(error.message);
                return;
            }

            toast.error('Ocorreu um erro ao remover o orçamento');
        },
        onSuccess: () => {
            toast.success('Orçamento removido com sucesso');
        },
        onSettled: () => budgetsQuery.refetch(),
    });

    const showLoading =
        budgetsQuery.isLoading ||
        budgetsQuery.isFetchingNextPage ||
        !budgetsQuery.isFetched;

    const target = useRef();

    useIntersectionObserver({
        target,
        onIntersect: budgetsQuery.fetchNextPage,
        enabled: budgetsQuery.hasNextPage,
    });

    const budgets = budgetsQuery.data?.pages.reduce(
        (accumulator, page) => accumulator.concat(page.data),
        [],
    );

    const selectedBudgetHasAttachments =
        selectedInvoice?.invoiceAttachments?.filter(
            (attachment: any) => !attachment.deletedAt,
        ).length;

    return (
        <>
            <Row>
                <Col className="ml-2 mr-2">
                    <Table bordered hover size="sm" className="text-center">
                        <thead>
                            <tr>
                                <StyledTh>Nº DO ORÇAMENTO</StyledTh>
                                <StyledTh>SALVO EM</StyledTh>
                                <StyledTh>CLIENTE</StyledTh>
                                <StyledTh>RESPONSÁVEL</StyledTh>
                                <StyledTh>VALOR TOTAL</StyledTh>
                                <StyledTh>EXPIRA EM</StyledTh>
                                <StyledTh />
                            </tr>
                        </thead>
                        <tbody>
                            {budgets?.map((budget) => (
                                <BudgetTableRow
                                    key={budget.id}
                                    budget={budget}
                                />
                            ))}
                        </tbody>
                    </Table>
                </Col>
                <Col md={12} className="text-center" ref={target}>
                    {showLoading ? (
                        <Col md={12} className="text-center">
                            <Spinner animation="border" />
                        </Col>
                    ) : (
                        <Col md={12} className="text-center">
                            <strong style={{ color: '#adadad' }}>
                                Sem itens para carregar
                            </strong>
                        </Col>
                    )}
                </Col>
            </Row>

            {showMakeConfirmation && (
                <ConfirmationDialog
                    show={showMakeConfirmation}
                    onHide={() => setShowMakeConfirmation(false)}
                    onConfirm={async () => makeMutation.mutateAsync()}
                    title="Fazer pedido"
                    text="Deseja confirmar o orçamento selecionado como um pedido?"
                    icon={NotificationIcon.Info}
                />
            )}

            {showRemakeConfirmation && (
                <ConfirmationDialog
                    show={showRemakeConfirmation}
                    onHide={() => {
                        setShowRemakeConfirmation(false);
                    }}
                    onConfirmWithCheckbox={
                        selectedBudgetHasAttachments
                            ? (keepAttachments) =>
                                  remakeMutation.mutateAsync(keepAttachments)
                            : undefined
                    }
                    onConfirm={
                        !selectedBudgetHasAttachments
                            ? () => remakeMutation.mutateAsync(false)
                            : undefined
                    }
                    title="Refazer pedido"
                    text="Deseja refazer o pedido selecionado?"
                    icon={NotificationIcon.Info}
                    label="Desejo manter os anexos do pedido original"
                />
            )}

            {showRemoveConfirmation && (
                <ConfirmationDialog
                    show={showRemoveConfirmation}
                    onHide={() => setShowRemoveConfirmation(false)}
                    onConfirm={async () => removeMutation.mutateAsync()}
                    title="Remover orçamento"
                    text="Deseja remover o orçamento selecionado?"
                    icon={NotificationIcon.Warning}
                />
            )}
        </>
    );
}
