/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-lonely-if */
/* eslint-disable no-return-await */
import React, { useEffect, useState } from 'react';
import { Button, Col, Nav, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { useOnlineStore } from '../../../contexts/onlineStoreContext';
import ConfirmInvoiceModal from '../Invoice/ConfirmModal';
import {
    PaymentMethod,
    steps,
    readyKitsSteps,
} from '../../../constants/onlineStore';
import { StyledSquare, StyledTitleNav } from '../Base/styles';
import useWindowSize from '../../../hooks/useWindowSize';
import { ReactComponent as ReactArrowRight } from '../../../assets/icons/timeline/arrow_right.svg';
import InvoiceHttpService from '../../../services/http/invoice-http';
import { useStructureCalculation } from '../../../contexts/structureCalculationContext';

export default function Step() {
    const {
        step,
        setStep,
        cart,
        productType,
        option,
        newOption,
        setOption,
        loading,
        setLoading,
        setCart,
        loadCart,
        loadingCart,
        items,
    } = useOnlineStore();
    const [showConfirmInvoiceModal, setShowConfirmInvoiceModal] =
        useState<boolean>(false);
    const { structureCalculationValues } = useStructureCalculation();
    const size = useWindowSize();
    const [cartSteps, setCartSteps] = useState([]);

    useEffect(() => {
        setCartSteps(productType === 'MP' ? steps : readyKitsSteps);
    }, [productType]);

    const checkDisabled = (): boolean => {
        if (!cart) {
            return true;
        }
        const {
            integrator,
            customerDocument,
            deliveryAddressCep,
            deliveryAddressDescription,
            deliveryAddressErpState,
            deliveryAddressErpCity,
            deliveryAddressNeighborhood,
            paymentMethod,
            financingBank,
            financing,
        } = cart;
        if (
            !integrator?.id ||
            !customerDocument ||
            !deliveryAddressCep ||
            !deliveryAddressDescription ||
            !deliveryAddressErpState ||
            !deliveryAddressErpCity ||
            !deliveryAddressNeighborhood ||
            !paymentMethod
        ) {
            return true;
        }

        if (
            paymentMethod === PaymentMethod.Financing &&
            (!financingBank || (financingBank === 'BV' && !financing))
        ) {
            return true;
        }

        // if (type === 'MP') {
        //     const haveInverterOrPanel = invoiceItems.some((item: any) =>
        //         ['1010', '1001'].includes(item.productErpCode.substring(0, 4)),
        //     );
        //     console.log(haveInverterOrPanel);
        //     if (!haveInverterOrPanel) {
        //         return true;
        //     }
        // }

        return false;
    };

    const findKit = useMutation(
        async () => await InvoiceHttpService.addByKit(option),
        {
            onMutate: () => {
                setLoading(true);
            },
            onError: (err: any) => {
                if (err.message) {
                    toast.error(err?.message);
                } else {
                    toast.error('Erro ao buscar kits');
                }
            },
            onSuccess: async (data) => {
                if (!data) {
                    toast.info('Nenhum Kit encontrado para a seleção');

                    setStep(2);

                    return;
                }

                setOption(newOption());
                setCart((await loadCart(true)) ?? {});
                setStep(3);
                setLoading(false);
            },
            onSettled: () => {
                setLoading(false);
            },
        },
    );

    const haveMandatoryItems = () => {
        const haveInverter = cart?.invoiceItems.some((item: any) =>
            ['1010'].includes(item.productErpCode.substring(0, 4)),
        );
        const havePanel = cart?.invoiceItems.some((item: any) =>
            ['1001'].includes(item.productErpCode.substring(0, 4)),
        );

        return haveInverter && havePanel;
    };

    const nexStep = (item: any) => {
        if (cart?.invoiceItems && cart?.invoiceItems.length > 0) {
            setStep(item.number);
        } else {
            if (item.number > 2) {
                setStep(step);
            } else {
                setStep(item.number);
            }
        }
    };

    const handleNextStep = async () => {
        setStep(step === 4 ? 4 : step + 1);

        if (step === 3) {
            setCart(await loadCart(true));
        }

        if (step === 4 && !haveMandatoryItems() && productType === 'MP') {
            setStep(2);
            toast.error(
                'Para que sua compra seja autorizada, é necessário adicionar no mínimo 1 inversor e 1 Painel solar em seu pedido.',
            );
        } else {
            setShowConfirmInvoiceModal(true);
        }
    };

    return (
        <Row className="header align-items-center">
            <Col lg={2} md={12} sm={12} xl={2}></Col>

            <Col md={5} xl={8} lg={8}>
                <Nav
                    className={'pt-1 mr-n5'}
                    activeKey={step}
                    onSelect={(selectedKey: string) => {
                        if (+selectedKey === +step) {
                            return;
                        }

                        if (
                            (selectedKey !== '1' &&
                                cart?.invoiceItems?.length <= 0 &&
                                !(
                                    productType === 'MP' && selectedKey === '2'
                                )) ||
                            (!cart &&
                                (!items || !items.length) &&
                                selectedKey !== '1' &&
                                selectedKey !== '2')
                        ) {
                            toast.info('Selecione um produto para avançar');
                        }
                    }}
                >
                    {cartSteps &&
                        cartSteps.map((item: any, index: any) => (
                            <div key={index}>
                                <Nav.Item>
                                    <Nav.Link
                                        style={
                                            step === item.number
                                                ? {
                                                      color: '#F2994A',
                                                      fontWeight: 600,
                                                  }
                                                : {
                                                      color: 'rgba(112, 112, 112, 0.3)',
                                                  }
                                        }
                                        eventKey={item.number}
                                        onClick={async () => {
                                            if (item.number === 3) {
                                                setCart(await loadCart(true));
                                            }

                                            nexStep(item);
                                        }}
                                    >
                                        <Row>
                                            <Col xl={1}>
                                                <StyledSquare
                                                    background={
                                                        step === item.number
                                                            ? '#F2994A'
                                                            : '#fbf3e6'
                                                    }
                                                >
                                                    <h5
                                                        style={{
                                                            fontSize: 38,
                                                            fontWeight: 600,
                                                            color:
                                                                step ===
                                                                item.number
                                                                    ? '#F2994A'
                                                                    : 'rgba(112, 112, 112, 0.3)',
                                                        }}
                                                    >
                                                        {productType === 'MP'
                                                            ? item.number
                                                            : item.number - 1}
                                                    </h5>
                                                </StyledSquare>
                                            </Col>

                                            <Col className={'my-auto'}>
                                                <StyledTitleNav
                                                    className={
                                                        size.width < 1500 &&
                                                        productType === 'MP'
                                                            ? 'mr-n4'
                                                            : 'ml-3'
                                                    }
                                                    color={
                                                        step === item.number
                                                            ? '#F2994A'
                                                            : 'rgba(112, 112, 112, 0.3)'
                                                    }
                                                >
                                                    {item.title}
                                                </StyledTitleNav>
                                            </Col>
                                            {item.number !== 4 && (
                                                <ReactArrowRight
                                                    style={{
                                                        position: 'relative',
                                                        top: '-3px',
                                                    }}
                                                    className={`my-auto ${
                                                        size.width < 1500 &&
                                                        productType === 'MP'
                                                            ? ''
                                                            : 'mr-4'
                                                    }  `}
                                                    fill={
                                                        step === item.number
                                                            ? '#F2994A'
                                                            : 'rgba(112, 112, 112, 0.3)'
                                                    }
                                                />
                                            )}
                                        </Row>
                                    </Nav.Link>
                                </Nav.Item>
                            </div>
                        ))}
                </Nav>
            </Col>

            <Col className="pr-4 text-right d-flex justify-content-end" lg={2}>
                {step === 1 ? (
                    <Button
                        style={{
                            width: '7rem',
                        }}
                        onClick={async () => {
                            setStep(2);
                        }}
                        variant={'outline-primary'}
                    >
                        Pular Passo
                    </Button>
                ) : (
                    <Button
                        disabled={
                            (productType === 'PA' || productType === 'BF') &&
                            step === 2
                        }
                        variant={'outline-primary'}
                        onClick={() => setStep(step === 1 ? 1 : step - 1)}
                    >
                        Passo Anterior
                    </Button>
                )}
                {step === 1 ? (
                    <Button
                        className={`ml-2`}
                        style={{ width: '6.5rem' }}
                        onClick={() => findKit.mutateAsync()}
                        disabled={
                            (loading ||
                                !option.moduleId ||
                                !option.power ||
                                !option.roofTypeId ||
                                !option.inverterBrandId ||
                                !option.inverterId) &&
                            !structureCalculationValues?.lines?.length
                        }
                        variant={'primary'}
                    >
                        {loading ? <Spinner animation={'border'} /> : 'Buscar'}
                    </Button>
                ) : step === 4 ? (
                    <Button
                        style={{ width: '7.5rem' }}
                        className={`ml-2`}
                        disabled={checkDisabled()}
                        onClick={() => {
                            handleNextStep();
                        }}
                    >
                        {loadingCart ? (
                            <Spinner animation="border" />
                        ) : step === 4 ? (
                            'Salvar'
                        ) : (
                            'Próximo Passo'
                        )}
                    </Button>
                ) : (
                    <Button
                        style={{ width: '7.5rem' }}
                        className={`ml-2`}
                        disabled={loadingCart}
                        onClick={() => {
                            if (
                                (step !== '1' &&
                                    cart?.invoiceItems?.length <= 0 &&
                                    !(productType === 'MP' && step === '2')) ||
                                (!cart &&
                                    (!items || !items.length) &&
                                    step !== '1' &&
                                    step !== '2')
                            ) {
                                toast.info('Selecione um produto para avançar');

                                return;
                            }

                            if (
                                step >= 2 &&
                                cart?.invoiceItems &&
                                cart?.invoiceItems.length === 0
                            ) {
                                setStep(step);
                            } else {
                                setStep((step: number) => step + 1);
                            }
                        }}
                    >
                        {loadingCart ? (
                            <Spinner animation="border" />
                        ) : (
                            'Próximo passo'
                        )}
                    </Button>
                )}
            </Col>

            <ConfirmInvoiceModal
                show={showConfirmInvoiceModal}
                invoiceId={cart?.id}
                type={cart?.type}
                onHide={() => {
                    setShowConfirmInvoiceModal(false);
                }}
            />
        </Row>
    );
}
