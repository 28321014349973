import { Col, Image, Modal, Row, Container } from 'react-bootstrap';
import fileUploadImg from '../../../assets/icons/fileUpload.png';
import LibraryHttpService from '../../../services/http/library-http';

interface props {
    files: Array<{ nameFile: string; link: string }>;
    onHide: () => void;
}

export default function DownloadTechnicalDocumentProduct({
    files,
    onHide,
}: props) {
    async function downloadFT(link: string) {
        const linkArray = link.split('/');
        const folder = linkArray[1];
        const file = linkArray[2];
        await LibraryHttpService.downloadItem(folder, file);
    }
    return (
        <Modal
            className="modal-dialog-scrollable"
            size="xl"
            centered
            show={!!files.length}
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="ml-3 mr-3"
                    id="contained-modal-title-vcenter"
                >
                    Arquivos do produto
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="w-100 mr-3">
                <Container fluid="md">
                    {files.map((value, key) => {
                        return (
                            <Row
                                key={key}
                                className="p-3"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    downloadFT(value.link);
                                }}
                            >
                                <Col
                                    sm={2}
                                    className="d-flex justify-content-md-center"
                                >
                                    <Image src={fileUploadImg} />
                                </Col>
                                <Col
                                    sm={10}
                                    className="d-flex  align-items-center"
                                >
                                    <h5
                                        style={{
                                            color: '#F2994A',
                                            fontSize: '1rem',
                                        }}
                                    >
                                        {value.nameFile}
                                    </h5>
                                </Col>
                            </Row>
                        );
                    })}
                </Container>
            </Modal.Body>
        </Modal>
    );
}
